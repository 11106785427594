// 공통적으로 쓰이는 레이아웃을 정의합니다.

import { productAPI } from "api";
import SearchIcon from "assets/icons/Search";
import TrashIcon from "assets/icons/Trash";
import { palette } from "modules/defines/styles";
import { useContext, useState } from "react";
import styled from "styled-components";
import ModalImageViewer from "components/modal/ModalImageViewer";
import { ModalContext, ModalContextType } from "modules/context/ModalContext";

interface ImageButtonProps {
  img: string;
  type: string; // 'represent' or 'detail'
  idx: number;
  clickDisabled: boolean;
  productId: number;
  productNumber: string;
  setProductInfo: any;
  mid: number;
}

export const ImageContents = ({ ...props }: ImageButtonProps) => {
  const img = props.img;
  const [isHovering, setIsHovering] = useState(false);
  const { openModal, closeModal } = useContext(
    ModalContext
  ) as ModalContextType;

  // 이미지 삭제
  const handleImageDelete = async (
    productId: number,
    productNumber: string,
    type: string,
    idx: number
  ) => {
    if (window.confirm("이미지를 삭제하시겠습니까?")) {
      await productAPI
        .deleteImage({ id: productId, type: type, index: idx })
        .then(async (res) => {
          if (res.status === 200) {
            alert("이미지가 삭제되었습니다.");
            // reload 되어도 수정 모드에 머물러있도록 하기 위해 상품 정보를 다시 불러옴
            await productAPI
              .getProductDetail(productNumber, { mid: props.mid })
              .then((res) => {
                props.setProductInfo(res.data?.product);
              });
          } else {
            alert(
              "이미지 삭제에 실패하였습니다. 해당 현상이 지속될 경우 관리자에게 문의바랍니다."
            );
          }
        });
    }
  };

  // 이미지 뷰어
  const handleImageViewer = (img: string) => {
    let modalTitle = "";
    if (props.type === "represent") {
      modalTitle = "메인 사진";
    } else if (props.type === "detail") {
      modalTitle = "상세 정보";
    }
    return openModal(
      <ModalImageViewer
        subject={modalTitle}
        onClose={() => closeModal()}
        img={img}
        type={props.type}
      />
    );
  };
  return (
    <Container
      uploadedImg={img}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      clickDisabled={props.clickDisabled}
    >
      {isHovering && !props.clickDisabled && (
        <HoverContainer>
          <HoverButton onClick={() => handleImageViewer(img)}>
            <SearchIcon color={palette.neutral600} size="14" />
          </HoverButton>

          <HoverButton
            onClick={() =>
              handleImageDelete(
                props.productId,
                props.productNumber,
                props.type,
                props.idx
              )
            }
          >
            <TrashIcon color={palette.neutral600} size="14" />
          </HoverButton>
        </HoverContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ uploadedImg: string; clickDisabled: boolean }>`
  display: flex;
  width: 120px;
  height: 120px;

  flex-direction: column;
  gap: 6px;

  border-radius: 8px;
  border: 1px solid ${palette.neutral300};
  background-color: ${palette.neutral100};
  background-image: url(${(props) => props.uploadedImg});
  background-size: cover;

  cursor: ${(props) => (props.clickDisabled ? "default" : "pointer")};
`;

const HoverContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.opacityB40};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const HoverButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid ${palette.neutral300};
  background-color: ${palette.neutral100};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${palette.neutral300};
  }
`;
