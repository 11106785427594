import { subtitle14 } from "components/style/subtitle";
import { palette } from "modules/defines/styles";
import styled from "styled-components";

const TableInput = styled.input<{ flexGrow?: number }>`
  ${subtitle14};
  border: none;
  color: ${palette.neutral800};
  background-color: white;
  box-shadow: 0 0 0 1px ${palette.neutral300} inset;
  padding: 10px 16px;
  flex-grow: ${(props) => props.flexGrow || 0};
  &::placeholder {
    color: ${palette.neutral400};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${palette.primary500} inset;
  }
`;

export default TableInput;
