import styled from "styled-components";
import { body12 } from "components/style/body";
import { palette } from "modules/defines/styles";
import { PropsWithChildren, ReactNode } from "react";

const NoticeStyle = styled.label`
  ${body12}
  color: ${palette.primary500};
  background-color: ${palette.primary90};
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

interface NoticeProps {
  // children: PropsWithChildren<ReactNode>;
  badgeTitle: string;
}
const Notice: React.FC<NoticeProps> = ({ badgeTitle }) => {
  return <NoticeStyle>{badgeTitle}</NoticeStyle>;
};

export default Notice;
