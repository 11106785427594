// 0: 작성 중, 1: 승인 대기, 2: 승인 취소, 3: 판매 대기(승인 완료) 4: 판매 중, 5: 판매 중지, 6: 판매 종료

import { formatDateToKST } from "utils/util";
import { palette } from "./styles";

const TOAST_TYPE = {
  signInError: "signInError",
  sendVerificationCode: "sendVerificationCode",
  completeVerificationCode: "completeVerificationCode",
  error400: "error400",
} as const;

export const STATUS_TEXT_KOR = [
  {
    status: 0,
    title: "작성 중",
    buttonText: ["승인 신청"],
    confirmAlert: [
      "승인을 요청하시겠습니까?\n승인 완료까지는 영업일 기준 1~2일이 소요될 수 있습니다.",
    ],
    successAlert: ["승인이 요청되었습니다."],
    failAlert: ["승인 요청을 실패하였습니다. 관리자에게 문의해주세요."],
    nextStep: [1],
    buttonType: ["primary"],
    textColor: palette.neutral800,
    color: "gray",
  },
  {
    status: 1,
    title: "승인 대기",
    buttonText: ["승인요청 취소"],
    confirmAlert: ["승인 요청을 취소하시겠습니까?"],
    successAlert: ["승인 요청이 취소되었습니다."],
    failAlert: ["승인 요청 취소를 실패하였습니다. 관리자에게 문의해주세요."],
    nextStep: [0],
    buttonType: ["primary"],
    textColor: palette.neutral800,
    color: "gray",
  },
  {
    status: 2,
    title: "승인 취소",
    buttonText: ["승인 재요청"],
    confirmAlert: [
      "승인을 재요청하시겠습니[까?\n취소 사유 내용을 확인하지 않으셨다면 확인 후 수정하여 재요청바랍니다.",
    ],
    successAlert: ["승인이 재요청되었습니다."],
    failAlert: ["승인 재요청을 실패하였습니다. 관리자에게 문의해주세요."],
    nextStep: [1],
    buttonType: ["primary"],
    textColor: palette.secondary400,
    color: "red",
  },
  {
    status: 3,
    title: "판매 대기",
    buttonText: ["판매 시작"],
    confirmAlert: ["판매를 시작하시겠습니까?"],
    successAlert: [
      `판매가 시작되었습니다.\n${formatDateToKST(new Date()).split("+")[0].replace(/T/g, " ")}`,
    ],
    failAlert: ["판매 시작을 실패하였습니다. 관리자에게 문의해주세요."],
    nextStep: [4],
    buttonType: ["primary"],
    textColor: palette.neutral800,
    color: "accent",
  },
  {
    status: 4,
    title: "판매 중",
    buttonText: ["판매 중지"],
    confirmAlert: ["판매를 중지하시겠습니까?"],
    successAlert: ["판매가 중지되었습니다."],
    failAlert: ["판매 중지를 실패하였습니다. 관리자에게 문의해주세요."],
    nextStep: [5],
    buttonType: ["danger"],
    textColor: palette.primary500,
    color: "primary",
  },
  {
    status: 5,
    title: "판매 중지",
    buttonText: ["판매 재시작", "판매 종료"],
    confirmAlert: [
      "판매를 재시작하시겠습니까?",
      "판매를 종료하면 재시작할 수 없어 상품을 다시 등록해야 합니다.\n판매를 종료하시겠습니까?",
    ],
    successAlert: ["판매가 재시작되었습니다.", "판매가 종료되었습니다."],
    failAlert: ["판매 재시작", "종료를 실패하였습니다.\n관리자에게 문의해주세요."],
    nextStep: [4, 6],
    buttonType: ["primary", "danger"],
    textColor: palette.neutral800,
    color: "red",
  },
  {
    status: 6,
    title: "판매 종료",
    buttonText: ["종료됨"],
    confirmAlert: ["별도의 조치가 필요합니다."],
    successAlert: ["별도의 조치가 필요합니다."],
    failAlert: ["별도의 조치가 필요합니다."],
    nextStep: [6],
    buttonType: ["primary"],
    textColor: palette.neutral400,
    color: "gray",
  },
];

//레리아웃을 위한 배열 처리
export const INFO_ARRAY = [
  {
    status: 0,
    statusName: "작성 중",
  },
  {
    status: 3,
    statusName: "판매대기",
  },
  {
    status: 1,
    statusName: "승인 대기",
  },

  {
    status: 4,
    statusName: "판매 중",
  },
  {
    status: 2,
    statusName: "승인 취소",
  },

  {
    status: 5,
    statusName: "판매 중지",
  },

  {
    status: 6,
    statusName: "판매 종료",
  },
];

export const categoryEng = ["FOOD", "CAFE", "ALCOHOL", "CULTURE", "STAY", "SHOP", "ETC."];

export const bankList = [
  { code: "004", name: "국민은행" },
  { code: "088", name: "신한은행" },
  { code: "020", name: "우리은행" },
  { code: "081", name: "하나은행" },
  { code: "023", name: "SC제일은행" },
  { code: "027", name: "씨티은행" },
  { code: "039", name: "경남은행" },
  { code: "034", name: "광주은행" },
  { code: "031", name: "대구은행" },
  { code: "032", name: "부산은행" },
  { code: "037", name: "전북은행" },
  { code: "035", name: "제주은행" },
  { code: "003", name: "기업은행" },
  { code: "011", name: "농협은행" },
  { code: "002", name: "산업은행" },
  { code: "007", name: "수협은행" },
  { code: "089", name: "케이뱅크" },
  { code: "090", name: "카카오뱅크" },
  { code: "092", name: "토스뱅크" },
];

export type ToastType = (typeof TOAST_TYPE)[keyof typeof TOAST_TYPE];
