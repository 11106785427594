import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  color: string;
  size: string;
}

const SearchIcon: React.FC<IconProps> = ({
  color = palette.neutral600,
  size = 0,
}) => {
  const viewBox = `0 0 ${size} ${size}`;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="search">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.04747 1.52173C4.61365 1.28721 5.22049 1.1665 5.83332 1.1665C6.44616 1.1665 7.05299 1.28721 7.61918 1.52173C8.18537 1.75625 8.69981 2.1 9.13315 2.53334C9.56649 2.96668 9.91024 3.48113 10.1448 4.04731C10.3793 4.6135 10.5 5.22034 10.5 5.83317C10.5 6.44601 10.3793 7.05284 10.1448 7.61903C9.98503 8.00466 9.77462 8.36629 9.51975 8.69464L12.6625 11.8374C12.8903 12.0652 12.8903 12.4345 12.6625 12.6623C12.4347 12.8901 12.0653 12.8901 11.8375 12.6623L8.69479 9.5196C8.36644 9.77447 8.00481 9.98487 7.61918 10.1446C7.05299 10.3791 6.44616 10.4998 5.83332 10.4998C5.22049 10.4998 4.61365 10.3791 4.04747 10.1446C3.48128 9.91009 2.96683 9.56634 2.53349 9.133C2.10015 8.69966 1.75641 8.18521 1.52189 7.61903C1.28736 7.05284 1.16666 6.44601 1.16666 5.83317C1.16666 5.22033 1.28736 4.6135 1.52189 4.04731C1.75641 3.48113 2.10015 2.96668 2.53349 2.53334C2.96683 2.1 3.48128 1.75625 4.04747 1.52173ZM5.83332 2.33317C5.3737 2.33317 4.91857 2.4237 4.49393 2.59959C4.06929 2.77548 3.68345 3.03329 3.35845 3.3583C3.03344 3.6833 2.77564 4.06914 2.59974 4.49378C2.42385 4.91842 2.33332 5.37354 2.33332 5.83317C2.33332 6.2928 2.42385 6.74792 2.59974 7.17256C2.77564 7.5972 3.03344 7.98304 3.35845 8.30804C3.68345 8.63305 4.06929 8.89086 4.49393 9.06675C4.91857 9.24264 5.3737 9.33317 5.83332 9.33317C6.29295 9.33317 6.74808 9.24264 7.17271 9.06675C7.59735 8.89086 7.98319 8.63305 8.3082 8.30804C8.6332 7.98304 8.89101 7.5972 9.0669 7.17256C9.24279 6.74792 9.33332 6.2928 9.33332 5.83317C9.33332 5.37354 9.24279 4.91842 9.0669 4.49378C8.89101 4.06914 8.6332 3.6833 8.3082 3.3583C7.98319 3.03329 7.59735 2.77548 7.17271 2.59959C6.74808 2.4237 6.29295 2.33317 5.83332 2.33317Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
