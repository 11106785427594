import React, { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

interface PageTitleProps {
  children: PropsWithChildren<ReactNode>;
  textCenter: "center" | "left";
}
const PageTitle: React.FC<PageTitleProps> = ({ children, textCenter }) => {
  return <Title textCenter={textCenter}>{children}</Title>;
};

const Title = styled.h1<PageTitleProps>`
  text-align: ${(props) => (props.textCenter ? "center" : "left")};
  font-size: ${(props) => props.theme.typographyTheme.font.titleCard.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.titleCard.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.titleCard.lineHeight};
  letter-spacing: ${(props) => props.theme.typographyTheme.font.titleCard.letterSpacing};
`;

export default PageTitle;
