import styled from "styled-components";
import GlobalContainer from "../GlobalContainer";
import { palette } from "modules/defines/styles";
import {
  ContentsContainer,
  ContentsLayout,
  PageTitle,
  ProductSectionTitle,
  TopWrapper,
} from "components/GlobalStyles";
import InfoSummary from "components/common/InfoSummary";

import PaymentTable from "components/ui/PaymentTable/PaymentTable";

const PaymentListContainer: React.FC = () => {
  // const InfoSummaryTitle = ["정산 방식", "정산 주기", "정산예정일", "주문관리 수수료"];
  // const InfoSummaryValue = ["계좌이체", "매 월", "매 월 15일", "7.0 %"];
  const InfoSummaryTitle = ["정산 방식", "정산 주기", "정산예정일"];
  const InfoSummaryValue = ["계좌이체", "매 월", "매 월 15일"];
  return (
    <GlobalContainer>
      <ContentsContainer>
        <TopWrapper>
          <PageTitle>정산 내역</PageTitle>
        </TopWrapper>
        <InfoSummary
          title={InfoSummaryTitle}
          value={InfoSummaryValue}
          type="string"
        />
        <PaymentListLayout marginTop={"16px"}>
          <PaymentTableWrapper>
            <PaymentTable />
          </PaymentTableWrapper>
        </PaymentListLayout>
      </ContentsContainer>
    </GlobalContainer>
  );
};

const PaymentListLayout = styled(ContentsLayout)`
  overflow-y: auto;
  height: 100%;
  padding-bottom: 20px;
`;

const PaymentTableWrapper = styled.div`
  display: grid;
  grid-template-rows: fit-content(100%);
  width: 100%;
  height: 100%;
`;

export default PaymentListContainer;
