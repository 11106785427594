import { createContext, useContext } from "react";
import type { ToastType } from "modules/defines/define";
import type { Dispatch, SetStateAction } from "react";

interface ToastContextProps {
  toast: ToastType;
  setToast: Dispatch<SetStateAction<ToastType>>;
}
export const toastContext = createContext<ToastContextProps | undefined>(
  undefined
);

export const useToastContext = () => {
  const value = useContext(toastContext);
  if (value === undefined)
    throw new Error(
      "useToastContext must be used within a ToastContextProvider"
    );
  return value as ToastContextProps;
};
