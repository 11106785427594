import { body12 } from "components/style/body";
import { caption } from "components/style/caption";
import { subtitle14 } from "components/style/subtitle";
import { palette } from "modules/defines/styles";
import { TextareaHTMLAttributes } from "react";
import { Path, RegisterOptions, useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

interface IRHTextArea<FormType extends object> extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: Path<FormType>;
  rules?: RegisterOptions<FormType>;
  labelName?: string;
}

export function RHTextArea<FormType extends object>({
  name,
  rules,
  ...rest
}: IRHTextArea<FormType>) {
  const { control, watch } = useFormContext<FormType>();
  const { defaultValue = "", labelName = "" } = { ...rest };
  const {
    field,
    fieldState: { error: errorMassage },
  } = useController({
    name,
    control,
    defaultValue: defaultValue as any,
    rules,
  });
  const [watchValue] = watch([name]) as string[];
  const watchValueLength = () => {
    if (watchValue !== undefined) {
      if (watchValue.length < 21) {
        if (watchValue.length < 10) {
          return `0${watchValue.length}`;
        } else {
          return watchValue.length;
        }
      } else {
        return 21;
      }
    } else {
      return 0;
    }
  };
  return (
    <TextAreaWrapper>
      <Label htmlFor={field.name}>{labelName}</Label>
      <TextArea className={errorMassage && "error"} {...field} {...rest} maxLength={21} />
      <TextCount className={errorMassage && "error"}>{`${watchValueLength()}/21자`}</TextCount>
    </TextAreaWrapper>
  );
}

const TextAreaWrapper = styled.div``;

const TextCount = styled.small`
  display: block;
  ${caption};
  color: ${palette.neutral700};
  text-align: right;
  margin-top: 4px;

  &.error {
    color: ${palette.secondary400};
  }
`;

const Label = styled.label`
  ${body12};
  color: ${palette.neutral700};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
`;
const TextArea = styled.textarea`
  ${subtitle14};
  margin-top: 8px;
  resize: none;
  width: 100%;
  min-height: 60px;
  padding: 10px 16px;
  color: ${palette.neutral1000};
  border-radius: 8px;
  border: 1px solid ${palette.neutral200};
  background-color: white;

  &::placeholder {
    color: ${palette.neutral500};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${palette.primary500};
    background-color: ${palette.white};
  }

  &:disabled {
    cursor: dㄴefault;
    box-shadow: 0 0 0 1px ${palette.neutral400};
    background-color: ${palette.primary20};
    color: ${palette.neutral500};
  }

  &:read-only {
    box-shadow: 0 0 0 1px ${palette.neutral400};
    background-color: ${palette.primary20};
    color: ${palette.neutral500};
  }
  &.error {
    box-shadow: 0 0 0 1px ${palette.secondary400};
    background-color: ${palette.secondary20};
  }
`;
