/* eslint-disable @typescript-eslint/no-unused-vars */

import { Player } from "@lottiefiles/react-lottie-player";
import Icon from "assets/icons/Icon";
import { titleContents } from "components/style/title";
import { palette } from "modules/defines/styles";
import { ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import spinner_disabled from "assets/lottie/spinner_disabled.json";
interface ButtonStyleProps {
  styleType: "primary" | "secondary" | "outline" | "outlineDisabled";
  width?: string;
  height?: string;
  top?: string;
  size: "48" | "60";
  disabled?: boolean;
}

const ButtonStyle = styled.button<ButtonStyleProps>`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: ${(props) => (props.width ? props.top : "0")};
  min-width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.width ? props.height : "auto")};
  color: ${palette.white};
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  justify-content: center;
  gap: 6px;
  padding: ${(props) => {
    if (props.size === "48") {
      return "13px 22.5px";
    }
    if (props.size === "60") {
      return "19px 22.5px";
    }
  }};
  border-radius: ${(props) => {
    if (props.size === "48") {
      return "10px";
    }
    if (props.size === "60") {
      return "10px";
    }
  }};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  ${(props) => {
    if (props.styleType === "primary") {
      return css`
        background-color: ${palette.primary500};
        &:hover {
          background-color: ${palette.primary600};
        }
        &:disabled {
          cursor: default;
          color: ${palette.neutral400};
          background-color: ${palette.neutral200};
        }
      `;
    }
    if (props.styleType === "secondary") {
      return css`
        background-color: ${palette.black};
        &:hover {
          background-color: ${palette.neutral700};
        }
        &:disabled {
          cursor: default;
          color: ${palette.neutral400};
          background-color: ${palette.neutral200};
        }
      `;
    }
    if (props.styleType === "outline") {
      return css`
        gap: 4px;
        background-color: ${palette.white};
        color: ${palette.neutral600};
        box-shadow: 0 0 0 1px ${palette.neutral300} inset;
        &:hover {
          background-color: ${palette.neutral300};
          color: ${palette.neutral700};
        }
        &:disabled {
          cursor: default;
          color: ${palette.neutral400};
          background-color: ${palette.white};
        }
      `;
    }

    if (props.styleType === "outlineDisabled") {
      return css`
        ${titleContents};
        pointer-events: none;
        background-color: ${palette.neutral200};
        color: ${palette.neutral400};
        box-shadow: 0 0 0 1px ${palette.neutral400} inset;
        &:hover,
        &:focus {
          pointer-events: none;
        }
      `;
    }
  }};
`;

interface ButtonProps extends ButtonStyleProps {
  onClick?: (e: any) => void;
  children: React.PropsWithChildren<ReactNode>;
  type: "button" | "submit" | "reset";
  isLoading?: boolean;
  isShow?: boolean;
}
interface RedoIconProps {
  color?: string;
  isHovered?: boolean;
  isDisabled?: boolean;
  isShow?: boolean;
}

const RedoIcon: React.FC<RedoIconProps> = ({ color, isDisabled, isHovered }) => {
  let iconColor = color;
  if (isHovered) {
    iconColor = palette.neutral700;
  } else if (isDisabled) {
    iconColor = palette.neutral400;
  } else {
    iconColor = palette.neutral600;
  }
  return <Icon icon={"redo"} fill={iconColor} />;
};

const Button: React.FC<ButtonProps> = ({ ...props }) => {
  const {
    styleType,
    disabled,
    width,
    onClick,
    children,
    size,
    type,
    height,
    top,
    isLoading,
    isShow,
  } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ButtonStyle
      top={top}
      type={type}
      size={size}
      disabled={disabled}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      styleType={styleType}
      width={width}
      height={height}
    >
      {styleType === "outline" && isShow ? (
        <RedoIcon isShow={isShow} isHovered={isHovered} isDisabled={disabled} />
      ) : null}
      {isLoading ? (
        <Player
          style={{
            width: "20px",
            height: "20px",
          }}
          src={spinner_disabled}
          autoplay
          loop
        />
      ) : null}
      {children}
    </ButtonStyle>
  );
};

export default Button;
