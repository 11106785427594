import { palette } from "modules/defines/styles";
import styled from "styled-components";
import { titleCard, titleText } from "./style/title";
import { body12 } from "./style/body";

// Contents
export const ContentsContainer = styled.div`
  padding-top: 8px;
  width: 100%;
  height: calc(100vh - 98px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ProductSection = styled.div``;
export const ProductSectionTitle = styled.div`
  color: ${palette.neutral1000};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;

export const ProductInfoTable = styled.div`
  margin-top: 16px;
`;

export const ProductInfoRowLayout = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
`;

export const ProductInfoRow = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  height: ${(props) => (props.height ? props.height : "auto")};
`;

export const ProductSectionSubtitle = styled.div`
  color: ${palette.neutral800};
  ${titleText};
  align-self: flex-start;
  position: relative;
`;

export const CategoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
`;

export const CategoryItem = styled.div<{ enableClick: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: ${(props) => (props.enableClick ? "pointer" : "default")};
`;

export const CagtegoryRadioImg = styled.img`
  width: 20px;
  height: 20px;
`;
export const CategoryLabel = styled.div`
  color: ${palette.neutral800};
  ${body12};
  position: relative;
`;

export const ImageContainer = styled.div<{ marginTop?: string }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

// Page Title
export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
export const PageTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
`;
export const ImgButton = styled.img`
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

export const PageTitle = styled.div`
  color: ${palette.neutral1000};
  ${titleCard};
`;

// Button
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const ContentsLayout = styled.div<{ marginTop: string }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "20px")};
  padding: 20px 16px;
  border-radius: 16px;
  background-color: ${palette.white};
  width: 100%;
  min-height: 300px;
  max-height: calc(100vh - 160px);
  /* height: calc(100vh - 160px); */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  /* scrollbar-width: thin; */
  position: relative;
  /* &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
    min-height: 24px;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${palette.opacityB40};
    border-radius: 100px;
    border: 2px solid white;
  } */
`;
