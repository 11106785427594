import { pagePaths } from "modules/defines/path";
import MainContainer from "containers/MainContainer";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import SignInContainer from "containers/sign/SignInContainer";
import SignUpContainer from "containers/sign/SignUpContainer";
import ProductListContainer from "containers/product/ProductListContainer";
// import ProductRegisterContainer from "containers/product/ProductRegisterContainer";
import ProductDetailContainer from "containers/product/ProductDetailContainer";
import PaymentListContainer from "containers/payment/PaymentListContainer";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { authAPI, dashboardAPI, paymentAPI, productAPI } from "api";
import Loading from "components/ui/state/Loading";
import { Component } from "react";
import SettingContainer from "containers/setting/SettingContainer";
import { useCookies } from "react-cookie";
import UseApprovalContainer from "containers/UseApprovalContainer";
import StateContainer from "../containers/StateContatiner";

const RouterComponent = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [cookies, setCookie] = useCookies();
  const { data, isLoading, dataUpdatedAt } = useQuery({
    queryKey: ["checkUser"],
    queryFn: authAPI.checkUser,
    // staleTime: 1000 * 60 * 60,
  });

  const { data: dashbordManagerInfo, isLoading: isDashbordManagerInfo } =
    useQuery({
      queryKey: ["dashboardManager"],
      queryFn: dashboardAPI.getDashboardManager,
    });

  const { data: dashbordProductInfo, isLoading: isDashbordProductInfo } =
    useQuery({
      queryKey: ["dashboardProduct"],
      queryFn: dashboardAPI.getDashboardProduct,
    });

  const { data: dashbordMonthInfo, isLoading: isDashbordMonthInfo } = useQuery({
    queryKey: ["dashboardMonthInfo"],
    queryFn: dashboardAPI.getDashboardMonthInfo,
    enabled: !!data?.data.success,
  });

  const userProductList = useQuery({
    queryKey: ["userProductList"],
    queryFn: () => productAPI.getProductList(data?.data.manager.id),
    enabled: !!data?.data.success,
  });

  // const userPaymentInfo = useQuery({
  //   queryKey: ["userPaymentInfo"],
  //   queryFn: () => paymentAPI.getPaymentInfo({ id: data?.data.manager.id }),
  // });

  // if (isLoading) return <></>;
  // if (isDashbordManagerInfo) return <></>;
  // if (isDashbordProductInfo) return <></>;
  // if (isDashbordMonthInfo) return <></>;
  // if (userProductList.isLoading) return <></>;
  // if (userPaymentInfo.isLoading) return <></>;
  // if (!dataUpdatedAt) return <Loading />;

  if (
    history.location.pathname !== "/use-approval" &&
    history.location.pathname !== "/state"
  ) {
    if (isLoading) return <></>;
    if (isDashbordManagerInfo) return <></>;
    if (isDashbordProductInfo) return <></>;
    if (isDashbordMonthInfo) return <></>;
    if (userProductList.isLoading) return <></>;
    // if (userPaymentInfo.isLoading) return <Loading />;
    if (!dataUpdatedAt) return <Loading />;
  }
  const isLogin = data?.data.success === true;

  return (
    <Switch>
      {/* Sign */}
      <Route path={pagePaths.signIn} exact component={SignInContainer} />
      <Route path={pagePaths.signUp} exact component={SignUpContainer} />
      <PrivateRoute
        isAuthenticated={isLogin && cookies.isAdmin === true}
        path={pagePaths.main}
        exact
        component={MainContainer}
      />
      <PrivateRoute
        isAuthenticated={isLogin}
        path={pagePaths.productDetail}
        exact
        component={ProductDetailContainer}
      />
      <PrivateRoute
        isAuthenticated={isLogin}
        path={pagePaths.product}
        exact
        component={ProductListContainer}
      />
      <PrivateRoute
        // isAuthenticated={isLogin && cookies.isAdmin === true}
        isAuthenticated={isLogin}
        path={pagePaths.setting}
        exact
        component={SettingContainer}
      />
      <PrivateRoute
        isAuthenticated={isLogin && cookies.isAdmin === true}
        path={pagePaths.payment}
        exact
        component={PaymentListContainer}
      />
      <Route
        path={pagePaths.useApproval}
        exact
        component={UseApprovalContainer}
      />
      <Route path={pagePaths.state} exact component={StateContainer} />

      {/* Main (Dashboard) */}

      {/* {data?.data.success === true ? (
        <>
          <Route path={pagePaths.productDetail} exact component={ProductDetailContainer} />
          <Route path={pagePaths.product} exact component={ProductListContainer} />
          <Route path={pagePaths.payment} exact component={PaymentListContainer} />
        </>
      ) : (
        <>
          <Route path={pagePaths.signIn} exact component={SignInContainer} />
          <Route path={pagePaths.signUp} exact component={SignUpContainer} />
        </>
      )} */}

      {/* Main (Product) */}
      {/* <Route path={pagePaths.product} exact component={ProductListContainer} /> */}
      {/* 
      <Route
        path={pagePaths.productRegister}
        exact
        component={ProductRegisterContainer}
      /> */}

      {/* <Route path={pagePaths.productDetail} exact component={ProductDetailContainer} /> */}
      {/* Main (Payment) */}
      {/* <Route path={pagePaths.payment} exact component={PaymentListContainer} /> */}

      <Redirect to={pagePaths.signIn} />
    </Switch>
  );
};
interface PrivateRouteProps {
  component: any;
  isAuthenticated: boolean;
  [key: string]: any;
}

function PrivateRoute({
  component: Component,
  isAuthenticated,
  ...rest
}: PrivateRouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `${pagePaths.signIn}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
export default RouterComponent;
