import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import styled from "styled-components";
import { palette } from "modules/defines/styles";
import { titleContents, titleText } from "components/style/title";
import "react-calendar/dist/Calendar.css";
import { icon_left, icon_right } from "assets/icon";
import { body12 } from "components/style/body";
import { subtitle14 } from "components/style/subtitle";
import { caption, caption02 } from "components/style/caption";
import TextButton from "components/ui/TextButton";
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface CalenderProps {
  date: Value;
  setDate: React.Dispatch<React.SetStateAction<Value>>;
  setShowCalender: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string; // single | range
}
const Calender = ({ date, setDate, setShowCalender, type = "range" }: CalenderProps) => {
  // const today = new Date();
  // const [date, setDate] = useState<Value>([today, today]);
  const [activeStartDate, setActiveStartDate] = useState<Date | undefined>(new Date());
  const handleDateChange = (newDate: Value) => {
    // if (Array.isArray(newDate)) {
    //   console.log(`First selected date: ${newDate[0]}`);
    //   console.log(`Last selected date: ${newDate[1]}`);
    // } else {
    //   console.log(`Selected date: ${newDate}`);
    // }
    console.log(newDate);
    setDate(newDate);
    if (Array.isArray(newDate)) {
      setActiveStartDate(newDate[1] as Date); // newDate가 배열인 경우에만 인덱스를 사용합니다.
    }
  };

  // const handleDayClick = (date: Date) => {
  //   console.log(`Clicked date: ${date}`);
  // };

  const resetDate = () => {
    const today = new Date();
    type === "range" ? setDate([today, today]) : setDate(today);
    setActiveStartDate(today);
  };

  const handleShowCalender = () => {
    setShowCalender(false);
  };

  return (
    <StyledCalendarWrapper>
      <CalendarStyle
        selectRange={type === "range"}
        activeStartDate={activeStartDate}
        // onActiveStartDateChange={({
        //   activeStartDate: nextActiveStartDate,
        // }: {
        //   activeStartDate: any;
        // }) => {
        //   const currentMonth = activeStartDate?.getMonth();
        //   const nextMonth = nextActiveStartDate.getMonth();

        //   // 활성 시작 날짜가 다음 달로 넘어가는 것을 막습니다.
        //   // if (currentMonth !== nextMonth) {
        //   //   return;
        //   // }

        //   setActiveStartDate(nextActiveStartDate);
        // }}

        onActiveStartDateChange={({ activeStartDate: nextActiveStartDate }) => {
          console.log(activeStartDate);
          setActiveStartDate(nextActiveStartDate as Date);
        }}
        onViewChange={({ activeStartDate }: { activeStartDate: any }) => {
          console.log(activeStartDate);
        }}
        formatDay={(locale, date: any) => moment(date).format("DD")}
        minDetail="year" // 상단 네비게이션에서 '월' 단위만 보이게 설정
        maxDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
        calendarType="gregory"
        next2Label={null} // +1년 & +10년 이동 버튼 숨기기
        prev2Label={null} // -1년 & -10년 이동 버튼 숨기기
        value={date}
        onChange={handleDateChange}
        locale="ko-KR"
      />
      <ButtonGroup>
        <TextButton onClick={resetDate} size={"14"} styleType={"tertiary"} disabled={false}>
          초기화
        </TextButton>
        <TextButton onClick={handleShowCalender} size={"14"} styleType={"main"} disabled={false}>
          확인
        </TextButton>
      </ButtonGroup>
    </StyledCalendarWrapper>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 0 24px;
  justify-content: space-between;
`;

const CalendarStyle = styled(Calendar)``;

export const StyledCalendarWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 200;
  width: 340px;
  height: 340px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 2px 6px 0px ${palette.opacityB25};
  .react-calendar {
    border: none;
  }
  .react-calendar__navigation {
    width: 100%;
    height: 22px;
    position: relative;
    display: flex;
    align-items: center;

    padding: 24px 24px 16px 24px;
    text-align: left;
    color: ${palette.neutral1000};
  }

  .react-calendar__navigation button:disabled {
    background: transparent;
  }

  .react-calendar__navigation button {
    text-align: start;
    &:enabled:hover {
      background-color: transparent;
    }
    &:nth-of-type(2) > span {
      ${titleContents};
    }

    &:first-of-type {
      &:enabled:hover {
        min-width: 22px;
        height: 22px;
        background-color: transparent;
      }
      &:enabled {
        min-width: 22px;
        height: 22px;
        background-color: transparent;
      }

      background-image: url(${icon_left});
      padding: 3px;
      min-width: 22px;
      height: 22px;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 0;
      order: 2;
      margin-right: 8px;
    }

    &:last-of-type {
      &:enabled:hover {
        min-width: 22px;
        height: 22px;
        background-color: transparent;
      }
      &:enabled {
        min-width: 22px;
        height: 22px;
        background-color: transparent;
      }

      order: 3;
      font-size: 0;
      padding: 3px;
      background-image: url(${icon_right});
      min-width: 22px;
      height: 22px;
      background-repeat: no-repeat;
      background-position: center;
      background-repeat: no-repeat;
    }
    border: none;
    color: ${palette.neutral1000};
    ${titleContents};
  }

  .react-calendar__navigation__label {
    width: 200px;
    margin-right: 140px;
  }
  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & > abbr {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .react-calendar__month-view__weekdays__weekday--weekend {
    &:abbr[title="일요일"] {
      color: ${palette.neutral600};
    }
  }

  .react-calendar__month-view__days__day {
    ${titleText};
    color: ${palette.neutral600};
    width: 20px;
    height: 20px;
    text-align: center;
    & > abbr {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .react-calendar__tile--hasActive {
    background-color: ${palette.primary500};
    border-radius: 16px;
    & > abbr {
      color: white;
    }
  }
  .react-calendar__tile--now {
    background-color: ${palette.neutral100};
    border-radius: 100%;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    & > abbr {
      ${subtitle14};
      color: ${palette.neutral500};
      padding: 0 10px;
      display: contents;
      background-color: red;
    }
  }

  .react-calendar__tile {
    height: 36px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${palette.primary90};
    }
  }

  .react-calendar__tile--hover {
    background-color: ${palette.primary90};
  }

  .react-calendar__month-view__weekdays {
    padding: 0 44px;
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
    color: ${palette.neutral500};
    padding: 0 10px;
    ${body12};
  }

  .react-calendar__month-view__days {
    padding: 0 44px;
    height: 196px;
  }

  .react-calendar__tile--rangeStart {
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    border: 3px solid ${palette.primary90};
    background-color: ${palette.primary90};

    & > abbr {
      text-align: center;
      vertical-align: middle;
      color: white;
      background-color: ${palette.primary500};
      padding: 4px 6px;
      border-radius: 100%;
    }
  }

  .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    border: 3px solid ${palette.primary90};
    background-color: ${palette.primary90};
    &.react-calendar__tile:enabled:focus {
      background-color: ${palette.primary90};
    }
    & > abbr {
      text-align: center;
      vertical-align: middle;
      color: white;
      background-color: ${palette.primary500};
      padding: 4px 6px;
      border-radius: 100%;
    }
  }

  .react-calendar__tile--rangeBothEnds {
    border-radius: 100%;
    background-color: ${palette.primary500};
    & > abbr {
      color: white;
      padding: 5px;
    }
  }

  .react-calendar__tile--active {
    background-color: ${palette.primary90};
    color: ${palette.neutral600};

    border: 0;
  }

  .react-calendar__tile--now {
    border-radius: 0;
    background-color: ${palette.primary90};
  }
  .react-calendar__tile--rangeBothEnds {
    background-color: transparent;
    border: 0;
    & > abbr {
      border: 3px solid ${palette.primary90};
      padding: 4px 6px;
    }
  }

  .react-calendar__year-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 216px;
    padding: 0 44px;
  }

  .react-calendar__year-view__months__month {
    width: 62px;
    height: 51px;
    padding: 0 10px;
    & > abbr {
      ${titleText};
      color: ${palette.neutral600};
    }

    &:hover {
      background-color: ${palette.primary500};
      border-radius: 16px;
      & > abbr {
        color: white;
      }
    }
  }
`;

export default Calender;
