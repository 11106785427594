import { Fragment } from "react/jsx-runtime";
import App from "./app";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
  <Fragment>
    <App />
    <div id="toast"></div>
  </Fragment>
);
