import { css } from "styled-components";
const caption = css`
  font-size: ${(props) => props.theme.typographyTheme.font.caption.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.caption.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.caption.lineHeight};
`;

const caption02 = css`
  font-size: ${(props) => props.theme.typographyTheme.font.caption02.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.caption02.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.caption02.lineHeight};
  letter-spacing: ${(props) => props.theme.typographyTheme.font.caption02.letterSpacing};
`;

export { caption, caption02 };
