import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  enabled: boolean;
  size: string;
}

const EditIcon: React.FC<IconProps> = ({ enabled, size = 0 }) => {
  const viewBox = `0 0 ${size} ${size}`;
  const color = enabled ? palette.white100 : palette.neutral700;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="edit">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7087 2.90722C17.29 2.32633 18.0782 2 18.9 2C19.7222 2 20.5107 2.32662 21.0921 2.908C21.6735 3.48938 22.0001 4.27791 22.0001 5.10011C22.0001 5.92207 21.6737 6.71039 21.0926 7.29172C21.0924 7.29189 21.0928 7.29156 21.0926 7.29172L19.8284 8.5604C19.7934 8.61212 19.7529 8.66128 19.7071 8.70711C19.6622 8.75205 19.614 8.79183 19.5634 8.82643L12.7084 15.7059C12.5207 15.8943 12.2658 16.0001 12 16.0001H9C8.44772 16.0001 8 15.5524 8 15.0001V12.0001C8 11.7343 8.10585 11.4794 8.29416 11.2917L15.1731 4.4373C15.2078 4.38642 15.2477 4.33804 15.2929 4.29289C15.3389 4.24687 15.3883 4.20627 15.4403 4.1711L16.7079 2.908C16.7082 2.90774 16.7084 2.90748 16.7087 2.90722ZM16.0113 6.42551L10 12.4154V14.0001H11.5847L17.5746 7.98881L16.0113 6.42551ZM18.9863 6.57208L17.428 5.01382L18.1221 4.32221C18.3284 4.1159 18.6082 4 18.9 4C19.1918 4 19.4716 4.1159 19.6779 4.32221C19.8842 4.52852 20.0001 4.80834 20.0001 5.10011C20.0001 5.39187 19.8842 5.67169 19.6779 5.878L18.9863 6.57208ZM3.87868 6.87868C4.44129 6.31607 5.20435 6 6 6H7C7.55228 6 8 6.44772 8 7C8 7.55228 7.55228 8 7 8H6C5.73478 8 5.48043 8.10536 5.29289 8.29289C5.10536 8.48043 5 8.73478 5 9V18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H15C15.2652 19 15.5196 18.8946 15.7071 18.7071C15.8946 18.5196 16 18.2652 16 18V17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17V18C18 18.7957 17.6839 19.5587 17.1213 20.1213C16.5587 20.6839 15.7957 21 15 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7957 3 18V9C3 8.20435 3.31607 7.44129 3.87868 6.87868Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default EditIcon;
