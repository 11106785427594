import { palette } from "modules/defines/styles";
import { Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import ToolTip from "./ToolTip";

interface InfoSummaryProps {
  title: string[];
  value: string[] | number[];
  type: string;
}

const InfoSummary = ({ ...props }: InfoSummaryProps) => {
  const InfoSummaryType = props.type; // 'string' or 'number'
  return (
    <ProductSummaryWrapper>
      {props.title.map((title, idx) => (
        <Fragment key={idx}>
          <ProductSummaryItemWrapper>
            <ProductSummaryContentsTitle>{title}</ProductSummaryContentsTitle>
            {InfoSummaryType === "number" && (
              <ProductSummaryContentsTextWrapper>
                <ProductSummaryContentsText>
                  {props.value[idx]}
                </ProductSummaryContentsText>
                건
              </ProductSummaryContentsTextWrapper>
            )}
            {InfoSummaryType === "string" && (
              <ProductSummaryContentsTextString
                isWarning={idx === props.title.length - 1}
              >
                {props.value[idx]}
                {idx === props.title.length - 1 && (
                  <ToolTip
                    content={`1일부터 말일까지 사용 완료된 주문이 다음달 15일에 정산됩니다.`}
                    location={"bottom-right"}
                    width={"190px"}
                    top={"-10px"}
                    right={"-14px"}
                  />
                )}
              </ProductSummaryContentsTextString>
            )}
          </ProductSummaryItemWrapper>
          <BorderWrapper>
            {idx !== props.title.length - 1 && (
              <div
                style={{
                  width: "1px",
                  height: "70%",
                  backgroundColor: palette.neutral200,
                }}
              />
            )}
          </BorderWrapper>
        </Fragment>
      ))}
    </ProductSummaryWrapper>
  );
};

// Product Summary
const ProductSummaryWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  background-color: ${palette.white};
  justify-content: space-between;
  gap: 1px;
  width: 100%;
  padding: 20px 12px 24px 12px;
  border: 1px solid ${palette.primary20};
`;
const ProductSummaryItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const BorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 1px;
`;
const ProductSummaryContentsTitle = styled.div`
  color: ${palette.primary700};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* TitleContents */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;
const ProductSummaryContentsTextWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  flex-direction: row;
  align-items: baseline;

  color: ${palette.neutral800};
  font-feature-settings: "clig" off, "liga" off;

  /* Subtitle16 */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;
const ProductSummaryContentsText = styled.div`
  color: ${palette.neutral800};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* TitleCard */
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: -0.48px;

  margin-right: 2px;
`;

const ProductSummaryContentsTextString = styled.div<{ isWarning: boolean }>`
  margin-top: 8px;
  display: flex;

  color: ${(props) =>
    props.isWarning ? palette.secondary400 : palette.neutral800};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* TitlePage */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: -0.4px;

  position: relative;
`;

export default InfoSummary;
