import { palette } from "modules/defines/styles";
import { createRef, memo, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { UseControllerProps, useController, Control, FieldValues } from "react-hook-form";
import styled, { keyframes } from "styled-components";
import { categoryEng } from "modules/defines/define";
import { body12 } from "components/style/body";
import { subtitle14 } from "components/style/subtitle";
import { caption } from "components/style/caption";
export interface InputControlProps extends UseControllerProps {}

interface CheckboxGroupProps {
  control: Control<FieldValues>;
  reset?: boolean;
}
export const RHMultiCategory: React.FC<CheckboxGroupProps> = ({ control, reset }) => {
  const {
    field,
    fieldState: { error: errorMassage },
  } = useController({
    control: control,
    name: "category",
    defaultValue: [],
    rules: { required: "카테고리를 1개이상 선택해주세요" },
  });

  const [firstSelected, setFirstSelected] = useState<string | undefined>(undefined);

  const buttonRefs = useMemo(
    () =>
      Array(categoryEng.length)
        .fill(0)
        .map(() => createRef<HTMLButtonElement>()),
    []
  );

  const handleCategoryClick = useCallback(
    (index: number) => {
      const value = buttonRefs[index].current?.value;
      let newValue;

      if (field.value.includes(value)) {
        newValue = field.value.filter((item: any) => item !== value);
      } else {
        newValue = [...field.value, value];
        if (field.value.length === 0) {
          setFirstSelected(value);
        }
      }

      field.onChange(newValue);
    },
    [field, buttonRefs]
  );

  useLayoutEffect(() => {
    if (field.value.length >= 1) {
      setFirstSelected(field.value[0]);
    }
    // console.log({ 현재배열: field.value, "최초 선택(메인)": firstSelected });
  }, [field, firstSelected]);
  return (
    <div>
      <CategoryWrapper>
        <CategoryLabel>카테고리 선택</CategoryLabel>

        <CategoryButtonWrapper>
          {categoryEng.map((category, index) => {
            return (
              <CategoryButton
                type="button"
                id="category"
                key={`${category}_${index}`}
                ref={buttonRefs[index]}
                value={category}
                onClick={() => {
                  if (firstSelected === category) {
                    setFirstSelected(undefined);
                    field.onChange([]);
                  } else {
                    handleCategoryClick(index);
                  }
                }}
                className={
                  firstSelected === category
                    ? "first-selected"
                    : field.value.includes(category)
                    ? "selected"
                    : ""
                }
              >
                {category}
              </CategoryButton>
            );
          })}
        </CategoryButtonWrapper>
        {errorMassage && <ErrorMessageText>{errorMassage.message}</ErrorMessageText>}
      </CategoryWrapper>
    </div>
  );
};

const IconFade = keyframes`
  0% {
    opacity: 1;
    scale: 1.2;
  }
  100% {
    opacity: 1;
     scale: 1;
  }
`;

const backgroundFade = keyframes`

0% {
  opacity: 0.8;
  background-size: 68% 100%;
}
100% {
  opacity: 1;
  background-size: 100% 100%;

}
`;

const TitleRed = styled.span`
  color: ${palette.secondary400};
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  font-weight: 400;
`;

const CategoryLabelExtra = styled.span`
  color: ${palette.neutral700};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;
const CategoryLabel = styled.label`
  ${body12};

  color: ${palette.neutral700};
`;

const CategoryButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, fit-content(100%));

  row-gap: 8px;
  column-gap: 4px;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CategoryButton = styled.button`
  padding: 6px 12px;
  ${subtitle14};
  background-color: ${palette.primary20};
  color: ${palette.primary500};
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  &.selected {
    animation: ${backgroundFade} 0.1s ease-in-out;
    background-image: linear-gradient(to right, ${palette.primary500}, ${palette.primary500});
    background-repeat: no-repeat;
    color: white;
  }
  &.first-selected {
    animation: ${backgroundFade} 0.1s ease-in-out;
    background-image: linear-gradient(to right, ${palette.primary600}, ${palette.primary600});
    background-repeat: no-repeat;
    color: white;
  }
`;

const ErrorMessageText = styled.div`
  color: ${palette.secondary400};
  ${caption};
`;

export default memo(RHMultiCategory);
