import { body14 } from "components/style/body";
import { titleContents } from "components/style/title";
import Button from "components/ui/Button";
import { motion } from "framer-motion";
import { ModalContext, ModalContextType } from "modules/context/ModalContext";
import { palette } from "modules/defines/styles";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { preventScroll, allowScroll } from "utils/handleScroll";
import { spaceAPI } from "api";

interface ModalFAVEditRequestProps {
  spaceId: number;
  managerName: string;
  managerContact: string;
}

const ModalFAVEditRequest = ({ ...props }: ModalFAVEditRequestProps) => {
  const { spaceId, managerName, managerContact } = props;
  const { closeModal } = useContext(ModalContext) as ModalContextType;
  useEffect(() => {
    const prevScrollY = preventScroll();
    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  const [requestDescription, setRequestDescription] = useState("");
  const handleRequestDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setRequestDescription(e.target.value);
  };

  const requestSpaceEdit = async (data: any) => {
    await spaceAPI.requestSpaceEdit(data).then((res) => {
      if (res.data.success) {
        closeModal();
        alert("수정 요청이 완료되었습니다. 관리자 확인 후 반영됩니다.");
      } else {
        alert("정보 수정 요청 실패. 관리자에게 문의바랍니다.");
      }
    });
  };

  const descriptionText =
    "정할 내용을 구체적으로 작성해 주세요. 검토 후 적용 여부를 알림으로 보내드립니다.";
  return (
    <ModalLayout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <Title>공간 정보 수정</Title>
      {descriptionText
        .split(".")
        .map((line, index) => <Description key={index}>{line}.</Description>)
        .splice(0, 2)}
      <Textarea
        onChange={handleRequestDescription}
        value={requestDescription}
        placeholder="예시 : 상호명 <0000>으로 변경 바랍니다."
      />
      <ButtonGroup>
        <Button
          width="128px"
          isShow={false}
          type="button"
          size="48"
          styleType="outline"
          onClick={() => {
            closeModal();
            alert("취소되었습니다.");
          }}
        >
          취소
        </Button>
        <Button
          width="236px"
          isShow={false}
          type="button"
          size="48"
          styleType="primary"
          onClick={async () => {
            await requestSpaceEdit({
              contact: managerContact,
              space_id: spaceId,
              name: managerName,
              text: requestDescription,
            });
          }}
        >
          수정 요청
        </Button>
      </ButtonGroup>
    </ModalLayout>
  );
};

const ModalLayout = styled(motion.div)`
  padding: 12px 4px;
  margin: 20px;
`;
const Title = styled.h2`
  ${titleContents};
  color: ${palette.neutral1000};
  margin-bottom: 8px;
`;

const Description = styled.p`
  ${body14};
  color: ${palette.neutral600};
`;

const Textarea = styled.textarea`
  margin-top: 12px;

  resize: none;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid ${palette.neutral200};
  width: 100%;
  aspect-ratio: 372 / 192;
  &:focus {
    outline: none;
    border: 1px solid ${palette.primary500};
    background-color: white;
  }
`;

const CancelButton = styled.button`
  color: ${palette.neutral600};
  ${titleContents};
  background-color: white;
`;
const ButtonGroup = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
`;
export default ModalFAVEditRequest;
