export interface Option {
  price: number;
  title: string;
  count: number;
}

export const transPrice = (price: number, isBilling: boolean): string => {
  var priceResult = "";
  priceResult = price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");

  if (isBilling) {
    priceResult = "월 " + priceResult;
  } else {
    priceResult = priceResult;
  }

  return priceResult;
};

// export const calcDiscount = (
//   price: number,
//   discountRate: number | undefined
// ): string => {
//   var priceInt = parseInt(price.toString().replace(/,/g, ""));
//   if (discountRate !== undefined && discountRate !== 0) {
//     priceInt = priceInt * ((100 - discountRate) / 100);
//   }
//   return addCommaToNum(Math.floor(priceInt));
// };

export const addCommaToNum = (inputNum: number): string => {
  var numStr = inputNum + "";
  for (var i = 0; i < Math.floor(numStr.replace(/,/g, "").length / 3); i++) {
    var index = numStr.replace(/,/g, "").length - 3 * (i + 1);
    if (index !== 0)
      numStr = numStr.slice(0, index) + "," + numStr.slice(index);
  }
  return numStr;
};
export const deleteCommaToStr = (inputStr: string): number => {
  return parseInt(inputStr.replace(/,/g, ""));
};

export const addStrNum = (str1: string, str2: string): string => {
  return addCommaToNum(
    Number(str1.replace(/,/g, "")) + Number(str2.replace(/,/g, ""))
  );
};

export const calOriginalPrice = (
  totalPrice: number,
  discount: number,
  options: Option[]
): number => {
  var originalPrice = (totalPrice * 100) / (100 - discount);
  var countSum = 0;

  options.forEach((option) => {
    originalPrice -= option.price * option.count;
    countSum += option.count;
  });

  return originalPrice / countSum;
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
