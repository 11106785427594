import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  enabled: boolean;
  size: string;
}

const CalculatorIcon: React.FC<IconProps> = ({ enabled, size = 0 }) => {
  const viewBox = `0 0 ${size} ${size}`;
  const color = enabled ? palette.white100 : palette.neutral700;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="calculator">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4C5.73478 4 5.48043 4.10536 5.29289 4.29289C5.10536 4.48043 5 4.73478 5 5V19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H18C18.2652 20 18.5196 19.8946 18.7071 19.7071C18.8946 19.5196 19 19.2652 19 19V5C19 4.73478 18.8946 4.48043 18.7071 4.29289C18.5196 4.10536 18.2652 4 18 4H6ZM3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H18C18.7956 2 19.5587 2.31607 20.1213 2.87868C20.6839 3.44129 21 4.20435 21 5V19C21 19.7957 20.6839 20.5587 20.1213 21.1213C19.5587 21.6839 18.7957 22 18 22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7957 3 19V5C3 4.20435 3.31607 3.44129 3.87868 2.87868ZM7.58579 6.58579C7.96086 6.21071 8.46957 6 9 6H15C15.5304 6 16.0391 6.21071 16.4142 6.58579C16.7893 6.96086 17 7.46957 17 8V9C17 9.53043 16.7893 10.0391 16.4142 10.4142C16.0391 10.7893 15.5304 11 15 11H9C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9V8C7 7.46957 7.21071 6.96086 7.58579 6.58579ZM15 8H9V9H15V8ZM8 13C8.55228 13 9 13.4477 9 14V14.01C9 14.5623 8.55228 15.01 8 15.01C7.44772 15.01 7 14.5623 7 14.01V14C7 13.4477 7.44772 13 8 13ZM12 13C12.5523 13 13 13.4477 13 14V14.01C13 14.5623 12.5523 15.01 12 15.01C11.4477 15.01 11 14.5623 11 14.01V14C11 13.4477 11.4477 13 12 13ZM16 13C16.5523 13 17 13.4477 17 14V14.01C17 14.5623 16.5523 15.01 16 15.01C15.4477 15.01 15 14.5623 15 14.01V14C15 13.4477 15.4477 13 16 13ZM8 16C8.55228 16 9 16.4477 9 17V17.01C9 17.5623 8.55228 18.01 8 18.01C7.44772 18.01 7 17.5623 7 17.01V17C7 16.4477 7.44772 16 8 16ZM12 16C12.5523 16 13 16.4477 13 17V17.01C13 17.5623 12.5523 18.01 12 18.01C11.4477 18.01 11 17.5623 11 17.01V17C11 16.4477 11.4477 16 12 16ZM16 16C16.5523 16 17 16.4477 17 17V17.01C17 17.5623 16.5523 18.01 16 18.01C15.4477 18.01 15 17.5623 15 17.01V17C15 16.4477 15.4477 16 16 16Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CalculatorIcon;
