import { body12 } from "components/style/body";
import { palette } from "modules/defines/styles";
import { useEffect, useState } from "react";
import clsx from "clsx";
import styled from "styled-components";
import { icon_bank } from "assets/icon/index";
interface BankListItemProps extends React.HTMLAttributes<HTMLInputElement> {
  code: string;
  name: string;
  radioGroupName: string;
  isChecked: boolean;
}

const BankListItem: React.FC<BankListItemProps> = ({ ...props }) => {
  const { code, name, radioGroupName, onChange, isChecked } = props;

  return (
    <li>
      <Input
        checked={isChecked}
        type="radio"
        onChange={onChange}
        name={radioGroupName}
        id={code}
        value={code}
      />
      <Label code={code} className={clsx(code, isChecked ? "isChecked" : "")} htmlFor={code}>
        {name}
      </Label>
    </li>
  );
};

const Input = styled.input`
  -webkit-appearance: none; // 웹킷 브라우저에서 기본 스타일 제거
  -moz-appearance: none; // 모질라 브라우저에서 기본 스타일 제거
  appearance: none; // 기본 브라우저에서 기본 스타일 제거
  vertical-align: middle;
`;

interface LabelProps {
  code: string;
}
const Label = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  gap: 2px;
  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: url(${icon_bank}) no-repeat;
    background-size: 208px 80px; /* 스프라이트 이미지의 원래 크기의 1/3 */
    background-position: ${(props) =>
      getBackgroundPosition(props.code)}; /* 아이콘의 위치를 1/3로 조정 */
  }
  width: 90px;
  cursor: pointer;
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.neutral100};
  ${body12};
  &.isChecked {
    color: white;
    background-color: ${palette.neutral400};
  }
  color: ${palette.neutral600};
`;

function getBackgroundPosition(code: string) {
  switch (code) {
    case "011":
      return "0px 0px";
    case "003":
      return "-32px 0px";
    case "004":
      return "-64px 0px";
    case "023":
      return "-96px 0px";
    case "020":
      return "-160px -32px";
    case "089":
      return "-128px -32px";
    case "031":
      return "-128px 0px";
    case "027":
      return "-32px -32px";
    case "037":
    case "034":
      return "-64px -32px";
    case "032":
    case "039":
      return "-96px -32px";
    case "035":
    case "088":
      return "-128px -64px";
    case "081":
      return "-96px -64px";
    case "092":
      return "-64px -64px";
    case "090":
      return "-192px 0px";
    case "002":
      return "-192px -32px";
    case "007":
      return "-32px -64px";
    default:
      return "0px 0px";
  }
}
export default BankListItem;
