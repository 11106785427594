import { createGlobalStyle } from "styled-components";

import PretendardBlack from "assets/fonts/pretendard/Pretendard-Black.woff";
import PretendardExtraBold from "assets/fonts/pretendard/Pretendard-ExtraBold.woff";
import PretendardBold from "assets/fonts/pretendard/Pretendard-Bold.woff";
import PretendardSemiBold from "assets/fonts/pretendard/Pretendard-SemiBold.woff";
import PretendardRegular from "assets/fonts/pretendard/Pretendard-Regular.woff";
import PretendardMedium from "assets/fonts/pretendard/Pretendard-Medium.woff";
import PretendardLight from "assets/fonts/pretendard/Pretendard-Light.woff";
import PretendardExtraLight from "assets/fonts/pretendard/Pretendard-ExtraLight.woff";
import PretendardThin from "assets/fonts/pretendard/Pretendard-Thin.woff";

export const GlobalFontStyle = createGlobalStyle`
  body {
  min-height: 100dvh;
  
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
}
  @font-face {
    font-family: "Pretendard";
    font-weight: 900;
    src: url(${PretendardBlack}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 800;
    src: url(${PretendardExtraBold}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 700;
    src: url(${PretendardBold}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 600;
    src: url(${PretendardSemiBold}) format("woff");
  }
  
  @font-face {
    font-family: "Pretendard";
    font-weight: 500;
    src: url(${PretendardMedium}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 400;
    src: url(${PretendardRegular}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 300;
    src: url(${PretendardLight}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 200;
    src: url(${PretendardExtraLight}) format("woff");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 100;
    src: url(${PretendardThin}) format("woff");
  }
`;
