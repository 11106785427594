import Header from "components/common/_test/Header";
import { palette } from "modules/defines/styles";
import styled from "styled-components";
import {
  FieldValues,
  SubmitHandler,
  useForm,
  useFormContext,
  set,
} from "react-hook-form";
// import RHInput from "components/common/_test/RHInput";
import { useHistory, useLocation } from "react-router-dom";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { ruleGroup } from "modules/defines/rules";
import RHCheckBox from "components/common/_test/RHCheckBox";
import { authAPI } from "api";
import { ModalContext, ModalContextType } from "modules/context/ModalContext";
import ModalFAVConnect from "components/modal/ModalFAVConnect";
import { RHInput } from "components/ui/RHInput";
import RHForm from "components/ui/RHForm";
import Button from "components/ui/Button";
import { UseFormGetValues } from "react-hook-form";
import { useVerificationCode } from "modules/customHooks/useVerificationCode";
import { useToastContext } from "modules/customHooks/useToastContext";
import type { ToastType } from "modules/defines/define";
import Toast from "components/ui/Toast/Toast";
import Loading from "components/ui/state/Loading";
import { useQueryClient } from "@tanstack/react-query";
import type { SignUpUserProps } from "modules/defines/interfaces";
import { DevTool } from "@hookform/devtools";
const SignUpContainer = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  /* -------------------------------------- hooks ------------------------------------- */
  const [loading, setLoading] = useState(false);

  /* -------------------------------------- logic ------------------------------------- */

  // 공간 연결
  const getDashboardManagerQuery = queryClient.getQueryData([
    "dashboardManager",
  ]) as {} as any | undefined;

  const isLogin = getDashboardManagerQuery?.data.success;

  useLayoutEffect(() => {
    if (isLogin) {
      history.push("/");
    }
  }, [isLogin]);

  const handleSignUp = async (data: SignUpUserProps) => {
    setLoading(true);
    await authAPI
      .signUp({
        email: data.email,
        corpName: data.corpName,
        corpType: "corp",
        password: btoa(data.password),
        space_id: data.space_id,
        managerName: data.managerName,
        birth: data.birth,
        phone: data.phone,
        corpAddr: data.corpAddr,
        adminCode: data.adminCode,
      })
      .then((res) => {
        if (res.status === 200) {
          alert("회원가입이 완료되었습니다.");
          history.push("/signin");
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <Container>
      <Header />
      <RHForm onValid={handleSignUp} formLayout="signUp">
        <SignUpForm signUpLoading={loading} />
      </RHForm>
    </Container>
  );
};

interface SignUpFormProps {
  signUpLoading: boolean;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ signUpLoading }) => {
  const { openModal, closeModal } = useContext(
    ModalContext
  ) as ModalContextType;
  const {
    setValue,
    getValues,
    register,
    setError,
    getFieldState,
    clearErrors,

    formState: { isValid },
  } = useFormContext();
  const toastState = useToastContext();
  const [showVerificationCodeInput, setShowVerificationCodeInput] =
    useState<boolean>(false);
  const [isCompleteVerification, setIsCompleteVerification] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonContent, setButtonContent] = useState<string>("인증");

  const { authCode, setIsAuthenticating } = useVerificationCode();

  useEffect(() => {
    register("space_id");
  }, [register, buttonContent]);

  const show = useCallback(
    (toastType: ToastType) => {
      toastState.setToast(toastType);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toastState.setToast]
  );

  const handleEmailVerification = async () => {
    setLoading(true);
    setButtonContent("인증요청");
    setIsAuthenticating(true);
    await authAPI
      .sendCode({
        type: "signup",
        email: getValues("email"),
        code: authCode,
      })
      .then((response) => {
        if (response.status === 200) {
          show("sendVerificationCode");
          setShowVerificationCodeInput(true);
          setButtonContent("재인증");
        } else {
          setError("email", {
            type: "required",
            message: "이미 가입된 이메일입니다.",
          });
          setIsAuthenticating(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const connectFAVSpaceListener = () => {
    return openModal(
      <ModalFAVConnect
        clearErrors={clearErrors}
        onclick={() => closeModal()}
        setValue={setValue as UseFormGetValues<FieldValues>}
        subject="FAV 공간 검색"
        description={`기존 FAV APP에 등록된 공간이 있는 경우 연결을,\n없다면 새로 추가해 주세요.`}
        onClose={() => closeModal()}
      />
    );
  };

  return (
    <>
      <FormTitle>회원가입</FormTitle>
      <RHInput
        isRequest={loading}
        type="email"
        name="email"
        placeholder="이메일 입력"
        labelName="이메일"
        disabled={loading || isCompleteVerification}
        rules={ruleGroup.email}
        isButton={true}
        buttonContent={buttonContent}
        onclick={handleEmailVerification}
        styleType={
          getFieldState("email")?.invalid ||
          !getFieldState("email")?.isDirty ||
          loading ||
          isCompleteVerification
            ? "outlineDisabled"
            : "primary"
        }
      />
      {showVerificationCodeInput && (
        <RHInput
          type="text"
          name="code"
          placeholder="인증번호 8자리를 입력해주세요."
          labelName="인증번호"
          rules={{
            required: "인증번호를 입력해주세요.",
          }}
          disabled={isCompleteVerification}
          styleType={isCompleteVerification ? "outlineDisabled" : "primary"}
          isButton={true}
          onclick={() => {
            if (getValues("code") === authCode) {
              setIsCompleteVerification(true);
              show("completeVerificationCode");
              setIsAuthenticating(false);
              setButtonContent("인증완료");
            } else {
              setError("code", {
                type: "ruleRequire",
                message: "인증번호가 일치하지 않습니다.",
              });
            }
          }}
          buttonContent="인증"
        />
      )}
      <RHInput
        disabled={!isCompleteVerification}
        name="password"
        placeholder="8-30자 영문 / 숫자 / 특수문자 조합"
        labelName="비밀번호"
        rules={ruleGroup.password}
        isIcon={true}
      />
      <RHInput
        disabled={!isCompleteVerification}
        name="passwordConfirm"
        placeholder="비밀번호를 다시 입력해 주세요"
        labelName="비밀번호 확인"
        isIcon={true}
        rules={{
          required: true,
          pattern: {
            value: ruleGroup.password.pattern.value,
            message: ruleGroup.password.pattern.message,
          },
          validate: (changePasswordCheck?: string) => {
            return (
              changePasswordCheck === getValues("password") ||
              "비밀번호가 일치하지 않습니다."
            );
          },
        }}
      />

      <RHInput
        disabled={!isCompleteVerification}
        styleType={!isCompleteVerification ? "outlineDisabled" : "primary"}
        onclick={() => connectFAVSpaceListener()}
        onClick={() => connectFAVSpaceListener()}
        isButton
        readOnly
        type="text"
        name="corpName"
        placeholder="검색에서 매장을 찾아주세요"
        labelName="매장 연결"
        buttonContent={getValues("corpName") ? "재검색" : "검색"}
        rules={{
          required: "매장을 연결해주세요.",
        }}
      />

      <FormSubTitle>대표 매니저 정보</FormSubTitle>
      <RHInput
        disabled={!isCompleteVerification}
        type="text"
        name="managerName"
        placeholder="담당자의 이름을 입력해 주세요"
        labelName="대표 매니저 이름"
        // rules={ruleGroup.email}
      />
      <RHInput
        disabled={!isCompleteVerification}
        type="tel"
        name="phone"
        placeholder="01000000000"
        labelName="휴대폰 번호"
        rules={ruleGroup.mobile}
      />
      <RHInput
        disabled={!isCompleteVerification}
        type="text"
        name="birth"
        labelName="생년월일"
        placeholder="YYMMDD"
        rules={{
          pattern: {
            value: /^([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))$/,
            message: "생년월일 여섯자를 입력해주세요.",
          },
        }}
      />
      <RHInput
        type="text"
        name="adminCode"
        placeholder="관리자 로그인을 위한 코드를 입력해주세요"
        labelName="관리자 코드"
        rules={ruleGroup.adminCode}
      />

      <AdminCodeDesc>
        관리자 코드는 관리자 로그인을 위해 필요하니 꼭 기억해주세요.
      </AdminCodeDesc>
      <Button
        size="60"
        styleType={isValid && !signUpLoading ? "primary" : "outlineDisabled"}
        type="submit"
      >
        회원가입 완료
      </Button>
      {showVerificationCodeInput && <Toast contents="sendVerificationCode" />}
      {isCompleteVerification && <Toast contents="completeVerificationCode" />}

      {/* 사업장 정보 */}
      {/* <FormSubTitle>사업장 정보</FormSubTitle>

        <InputContainer>
          <InputLabel>사업자 유형</InputLabel>
          <RadioButtonContainer>
            <RadioButton>
              <input type="radio" name="businessType" value="individual" />
              <RadioButtonLabel>개인사업자</RadioButtonLabel>
            </RadioButton>
            <RadioButton>
              <input type="radio" name="businessType" value="corporate" />
              <RadioButtonLabel>법인사업자</RadioButtonLabel>
            </RadioButton>
          </RadioButtonContainer>
        </InputContainer> */}

      {/* <RHCheckBox
          isdisabled={isCompleteVerification ? false : true}
          require
          label="서비스 이용 약관 동의"
          marginTop="55px"
          control={control}
          name="agreeTermsOfService"
          rules={ruleGroup.agreeTermsOfService}
        /> */}
      {/* <ButtonContainer>
          <SubmitButton role="button">Sign Up</SubmitButton>
        </ButtonContainer> */}
    </>
  );
};

const Container = styled.div`
  background-color: ${palette.neutral200};
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 120px 0 220px 0;
  /* overflow-y: scroll; */
`;

const FormTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: ${palette.neutral1000};
  margin-bottom: 20px;
`;

const FormSubTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: ${palette.neutral1000};
  margin-bottom: 12px;
  margin-top: 22px;
`;

const AdminCodeDesc = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${palette.neutral400};
  margin-top: 4px;
`;

export default SignUpContainer;
