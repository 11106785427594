import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  color: string;
}

export const PlusIcon24: React.FC<IconProps> = ({
  color = palette.primary500,
}) => {
  const viewBox = `0 0 24 24`;
  return (
    <svg
      width="24"
      height="24"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="plus">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11V5C11 4.44772 11.4477 4 12 4Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const PlusIcon16: React.FC<IconProps> = ({
  color = palette.primary500,
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="plus">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99984 2.6665C8.36803 2.6665 8.6665 2.96498 8.6665 3.33317V7.33317H12.6665C13.0347 7.33317 13.3332 7.63165 13.3332 7.99984C13.3332 8.36803 13.0347 8.6665 12.6665 8.6665H8.6665V12.6665C8.6665 13.0347 8.36803 13.3332 7.99984 13.3332C7.63165 13.3332 7.33317 13.0347 7.33317 12.6665V8.6665H3.33317C2.96498 8.6665 2.6665 8.36803 2.6665 7.99984C2.6665 7.63165 2.96498 7.33317 3.33317 7.33317H7.33317V3.33317C7.33317 2.96498 7.63165 2.6665 7.99984 2.6665Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
