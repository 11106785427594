import { palette } from "modules/defines/styles";
import { InputHTMLAttributes, PropsWithChildren, useEffect, useState } from "react";
import { FieldError, Path, useController, useFormContext } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import styled, { css } from "styled-components";
import Button from "components/ui/Button";
import Icon from "assets/icons/Icon";
import { Player } from "@lottiefiles/react-lottie-player";
import spinner_disabled from "assets/lottie/spinner_disabled.json";
import { subtitle14 } from "components/style/subtitle";
import { caption } from "components/style/caption";
import { body12 } from "components/style/body";
interface IRHInput<FormType extends object> extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<FormType>;
  rules?: RegisterOptions<FormType>;
  labelName?: string;
  isButton?: boolean;
  disabled?: boolean;
  buttonDisabled?: boolean;
  buttonContent?: string;
  styleType?: "primary" | "secondary" | "outline" | "outlineDisabled";
  onclick?: () => void;
  isIcon?: boolean;
  isRequest?: boolean;
  noSpace?: boolean;
}

export function RHInput<FormType extends object>({
  name,
  rules,
  ...rest
}: PropsWithChildren<IRHInput<FormType>>) {
  const { control } = useFormContext<FormType>();
  const {
    defaultValue = "",
    labelName = "",
    isButton,
    disabled,
    buttonContent = "검색",
    styleType = "primary",
    onclick,
    isIcon,
    isRequest,
    buttonDisabled,
    noSpace,
  } = { ...rest };
  const {
    field,
    fieldState: { error: errorMassage },
  } = useController({
    name,
    control,
    defaultValue: defaultValue as any,
    rules,
  });

  const [hidden, setHidden] = useState(true);
  const [type, setType] = useState(rest.type);

  useEffect(() => {}, [hidden]);
  return (
    <InputWithButtonLayout isButton={isButton}>
      <InputLayout isErrorMessage={errorMassage}>
        <Label htmlFor={field.name}>{labelName}</Label>
        <Input
          type={hidden ? "password" : type}
          className={errorMassage ? "error" : ""}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          ref={field.ref}
          {...rest}
        />
        {isIcon && (
          <Icon
            css={css`
              cursor: ${disabled ? "default" : "pointer"};
              position: absolute;
              bottom: 16px;
              right: 16px;
            `}
            icon={hidden ? "showPassword" : "hiddenPassword"}
            fill={palette.neutral600}
            onclick={() => {
              if (!disabled) {
                setHidden(!hidden);
              }
            }}
          />
        )}

        <ErrorMessageText noSpace={noSpace}>{errorMassage?.message}</ErrorMessageText>
      </InputLayout>
      {isButton && (
        <Button
          onClick={onclick}
          top={labelName === "" ? "7px" : "24px"}
          width="80px"
          height="48px"
          size="48"
          type="button"
          styleType={styleType}
          disabled={buttonDisabled}
        >
          {isRequest ? (
            <Player
              style={{
                width: "20px",
                height: "20px",
              }}
              src={spinner_disabled}
              autoplay
              loop
            />
          ) : null}

          {buttonContent}
        </Button>
      )}
    </InputWithButtonLayout>
  );
}

const InputWithButtonLayout = styled.div<{ isButton?: boolean }>`
  ${(props) =>
    props.isButton &&
    css`
      display: flex;
      gap: 6px;
    `}
`;

const InputLayout = styled.div<{ isErrorMessage: FieldError | undefined }>`
  width: 100%;
  margin-bottom: ${(props) => (props.isErrorMessage?.message ? "20px" : "0")};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ErrorMessageText = styled.p<{ noSpace?: boolean }>`
  ${(props) => {
    return props.noSpace
      ? css`
          position: static;
        `
      : css`
          position: absolute;
          bottom: -20px;
        `;
  }}

  color: ${palette.secondary400};
  ${caption}
`;

const Label = styled.label`
  color: ${palette.neutral700};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
  margin-bottom: 8px;
  ${body12};
`;

const Input = styled.input`
  ${subtitle14};
  width: 100%;
  border: 0;
  padding: 10px 16px;
  height: 48px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px ${palette.neutral200} inset;
  background-color: ${palette.white};
  color: ${palette.neutral1000};
  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::-ms-input-placeholder {
    color: ${palette.neutral500};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${palette.primary500};
    background-color: ${palette.white};
  }

  &:disabled {
    cursor: default;
    box-shadow: 0 0 0 1px ${palette.neutral400};
    background-color: ${palette.primary20};
    color: ${palette.neutral500};
  }

  &:read-only {
    box-shadow: 0 0 0 1px ${palette.neutral400};
    background-color: ${palette.primary20};
    color: ${palette.neutral500};
  }
  &.error {
    box-shadow: 0 0 0 1px ${palette.secondary400};
    background-color: ${palette.secondary20};
  }
`;
