import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { palette } from "modules/defines/styles";
import { transPrice } from "modules/functions/utils";
import parse from "html-react-parser";
import { titleContents } from "components/style/title";
import BottomSheetDefault from "components/bottomsheet/BottomSheetDefault";

const ModalPreviewProduct = ({ ...props }) => {
  const { subject = "", product } = props;
  const [totalPrice, setTotalPrice] = useState("");
  const [detailsList, setDetailsList] = useState([]);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const onClickPurchaseButton = (e) => {
    e.preventDefault();
    setShowBottomSheet(true);
  };

  const onClickBottomSheetClose = () => {
    setShowBottomSheet(false);
  };
  useEffect(() => {
    if (product.id !== 0) {
      setTotalPrice(product.priceFinal);
      setDetailsList(
        product.details == null || product.details === ""
          ? []
          : product.details.split("///")
      );
    }
  }, [product]);

  const [isSticky, setIsSticky] = useState(false);
  const [titleBarPosition, setTitleBarPosition] = useState(null);
  const [activeSection, setActiveSection] = useState("");
  const tabRef = useRef(null);
  const detailRef = useRef(null);
  const noticeRef = useRef(null);
  const containerRef = useRef(null);
  const subjectRef = useRef(null);
  const handleScroll = () => {
    if (tabRef.current && subjectRef.current) {
      setIsSticky(
        tabRef.current.getBoundingClientRect().top <= titleBarPosition
      );
    }

    const detailOffset = detailRef.current?.offsetTop - 60;
    const noticeOffset = noticeRef.current?.offsetTop - 60;
    const scrollPosition = containerRef.current.scrollTop;

    if (scrollPosition >= detailOffset && scrollPosition < noticeOffset) {
      setActiveSection("detail");
    } else if (scrollPosition >= noticeOffset) {
      setActiveSection("notice");
    } else {
      setActiveSection("");
    }
  };

  const scrollToSection = (ref) => {
    containerRef.current.scrollTo({
      top: ref.current.offsetTop - 60,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTitleBarPosition(
      subjectRef.current.getBoundingClientRect().height +
        subjectRef.current.getBoundingClientRect().top
    );
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <Subject ref={subjectRef}>{subject}</Subject>
      <ContainerWrapper ref={containerRef}>
        <ContentsTitleContainer>
          {product.img ? (
            <ImageContainer src={product.img} />
          ) : (
            <ImageContainer />
          )}
          <ContentsWrapper>
            <ProductManagerLabel>
              <ProductTypeLabel>{product.category}</ProductTypeLabel>
              {product.spaceTitle}
            </ProductManagerLabel>
            <TitleContainer>{product.title}</TitleContainer>
            <DescContainer>{product.description}</DescContainer>
            {product.discount === 0 ? (
              <PriceContainer>
                {transPrice(product.price, product.isBilling)}원
              </PriceContainer>
            ) : (
              <PriceDiscountContainer>
                <PriceDiscountOriginalPrice>
                  {transPrice(product.price, product.isBilling)}원
                </PriceDiscountOriginalPrice>
                <PriceDiscountedWrapper>
                  <PriceDiscountRate>
                    {Number(product.discount).toFixed(1).toString()}%
                  </PriceDiscountRate>
                  <PriceDiscountedPrice>{totalPrice}원</PriceDiscountedPrice>
                </PriceDiscountedWrapper>
              </PriceDiscountContainer>
            )}
          </ContentsWrapper>
        </ContentsTitleContainer>

        {product.type === "ticket" && (
          <Notice>
            이 상품은 <Highlight>교환권(현장수령)</Highlight> 입니다.
          </Notice>
        )}
        <StickyContainer ref={tabRef}>
          <StickyBar isSticky={isSticky} position={titleBarPosition}>
            <TabButton
              onClick={() => scrollToSection(detailRef)}
              active={activeSection === "detail"}
            >
              상세내용
            </TabButton>
            <TabButton
              onClick={() => scrollToSection(noticeRef)}
              active={activeSection === "notice"}
            >
              유의사항
            </TabButton>
          </StickyBar>
        </StickyContainer>
        {isSticky && <div style={{ height: "45px" }}></div>}

        <ButtonSolid60
          onClick={(e) => onClickPurchaseButton(e)}
          fixed={true}
          btype="secondary"
        >
          구매하기
        </ButtonSolid60>
        <ContentsDescContainer id="detail" ref={detailRef}>
          {product.details !== undefined &&
            detailsList.length !== 0 &&
            detailsList.map((detail, idx) => {
              return (
                <DescImageWrapper src={detail} key={`detail-img-${idx}`} />
              );
            })}
        </ContentsDescContainer>

        <ContentsDescContainer id="notice" className="notice" ref={noticeRef}>
          <DescTitle>유의사항</DescTitle>
          {product.contents && (
            <ContentsText>{parse(product.contents)}</ContentsText>
          )}
        </ContentsDescContainer>

        {showBottomSheet && (
          <BottomSheetDefault
            closeBottomSheet={onClickBottomSheetClose}
            product={product}
            totalPrice={totalPrice}
          />
        )}
      </ContainerWrapper>
    </ModalWrapper>
  );
};
export default ModalPreviewProduct;

const ModalWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-width: 372px;
  min-height: 394px;
  width: 400px;
  height: 80vh;
`;
const Subject = styled.h2`
  color: ${palette.neutral1000};
  ${titleContents};
  position: absolute;
  padding: 20px 0 16px 24px;
  z-index: 1000;
`;
const ContainerWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 60px;
  // margin-bottom: 100px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.opacityB40};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }

  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: block;
    width: 25px;
  }
`;

const ContentsTitleContainer = styled.div`
  display: flex;

  flex-direction: column;
`;
const ImageContainer = styled.img`
  height: 400px;
  object-fit: cover;

  width: 100%;
  margin-bottom: 25px;
`;
const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: calc(100% - 50px);
  margin: 0 25px;
`;
const TitleContainer = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 6px;
  color: ${palette.fontBlack};
`;
const DescContainer = styled.div`
  font-family: "Pretendard";
  font-weight: 400;
  line-height: 28px;
  font-size: 18px;
  color: #393939;
  margin-bottom: 16px;
`;
const PriceContainer = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  line-height: 20px;
  font-size: 18px;
  color: ${palette.fontBlack};
  margin-bottom: 40px;
`;
const PriceDiscountContainer = styled.div`
  font-family: "Pretendard";
  font-weight: 400;

  display: flex;
  flex-direction: column;
`;
const PriceDiscountOriginalPrice = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #c1c0c9;
`;
const PriceDiscountedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
`;
const PriceDiscountRate = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #0097ff;
`;
const PriceDiscountedPrice = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-left: 5px;
  color: ${palette.fontBlack};
`;
const ProductTypeLabel = styled.div`
  background-color: #00000066;
  color: white;
  display: inline-block;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
`;

const ProductManagerLabel = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #393939;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Notice = styled.div`
  background-color: #f4f4f4;
  padding: 19px 0;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
  border-radius: 5px;
  color: #393939;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

const Highlight = styled.span`
  color: #0097ff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

const ContentsDescContainer = styled.div`
  min-height: calc(100vh - 240px);
  display: flex;
  flex-direction: column;
  padding: 40px 25px 0 25px;
  &.notice {
    padding-bottom: 150px;
  }
`;
const DescTitle = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 10px;
`;

const DescImageWrapper = styled.img``;
const StickyContainer = styled.div`
  position: relative;
  width: 100%;
`;
const StickyBar = styled.div`
  position: ${({ isSticky }) => (isSticky ? "fixed" : "relative")};
  top: ${({ isSticky, position }) => (isSticky ? `${position}px` : "auto")};
  width: 100%;
  max-width: 40rem;
  background-color: white;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-around;
  // padding: 0 10px;

  left: ${({ isSticky }) => (isSticky ? "calc(50% - 20rem)" : "0")};
  @media screen and (max-width: 40rem) {
    left: 0;
  }
`;
const TabButton = styled.div`
  background: none;
  border: none;
  flex: 1;
  padding: 6px 0;
  margin: 0 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  border-bottom: ${({ active }) => (active ? "2px solid #007bff" : "none")};
`;

const ContentsText = styled.div`
  margin-top: 10px;
  p {
    font-size: 14px;
    line-height: 18px;
  }
  strong {
    font-weight: 700;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
`;

const ButtonSolid60 = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 60px;
  border-radius: 10px;
  width: 350px;
  min-width: 322px;
  cursor: pointer;

  color: ${palette.white};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  position: fixed;
  bottom: calc(10vh + 20px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  background-color: #1e1e1e;
  &:hover {
    background-color: #626262;
  }
`;
