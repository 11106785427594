import { css } from "styled-components";

const title = css`
  font-size: ${(props) => props.theme.typographyTheme.font.title.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.title.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.title.lineHeight};
`;

const titleText = css`
  font-size: ${(props) => props.theme.typographyTheme.font.titleText.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.titleText.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.titleText.lineHeight};
  letter-spacing: ${(props) => props.theme.typographyTheme.font.titleText.letterSpacing};
`;

const titleContents = css`
  font-size: ${(props) => props.theme.typographyTheme.font.titleContents.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.titleContents.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.titleContents.lineHeight};
`;

const titleCard = css`
  font-size: ${(props) => props.theme.typographyTheme.font.titleCard.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.titleCard.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.titleCard.lineHeight};
  letter-spacing: ${(props) => props.theme.typographyTheme.font.titleCard.letterSpacing};
`;

const titlePage = css`
  font-size: ${(props) => props.theme.typographyTheme.font.titlePage.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.titlePage.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.titlePage.lineHeight};
  letter-spacing: ${(props) => props.theme.typographyTheme.font.titlePage.letterSpacing};
`;
export { title, titleText, titleContents, titleCard, titlePage };
