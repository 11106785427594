import styled from "styled-components";
import { icon_fav } from "assets/icon/index";

interface PropsChild {
  logoHeight?: string;
  clickEnabled?: boolean;
}
const HeaderLogo: React.FC<PropsChild> = ({ logoHeight = "22px", clickEnabled = true }) => {
  return (
    <LogoContainer
      onClick={() => {
        if (clickEnabled) return (window.location.href = "/");
      }}
      clickEnabled={clickEnabled}
      className="isVersion"
    >
      <img src={icon_fav} alt="storeAdminLogo" loading="lazy" />
      <LogoTitle> FAV 스토어 관리</LogoTitle>
    </LogoContainer>
  );
};

const LogoContainer = styled.div<{ clickEnabled: boolean }>`
  display: flex;
  padding: 8xp 12px;
  gap: 8px;
  align-items: center;
  cursor: ${(props) => (props.clickEnabled ? "pointer" : "default")};
`;

const LogoTitle = styled.h1`
  color: #000;
  font-size: 18px;

  font-weight: 700;
  line-height: 26px; /* 144.444% */
`;

export default HeaderLogo;
