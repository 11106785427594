import { FormEventHandler, PropsWithChildren, useCallback } from "react";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  set,
  UseFormSetValue,
  FieldValues,
} from "react-hook-form";
import styled from "styled-components";
import { DevTool } from "@hookform/devtools";
interface IRHForm<FormType extends object> {
  onValid: SubmitHandler<FormType>;
  onInvalid?: SubmitErrorHandler<FormType>;
  onReset?: FormEventHandler<HTMLFormElement>;
  formLayout: "signIn" | "signUp" | "search" | "test";
}

const RHForm = <FormType extends object>({
  children,
  onValid,
  onReset,
  onInvalid,
  formLayout,
}: PropsWithChildren<IRHForm<FormType>>) => {
  const methods = useForm<FormType>({
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const handleReset = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      methods.reset();
      if (onReset) {
        onReset(e);
      }
    },
    [methods, onReset]
  );

  return (
    <FormProvider {...methods}>
      <FormLayout
        className={formLayout}
        onReset={handleReset}
        onSubmit={methods.handleSubmit(onValid, onInvalid)}
      >
        {children}
        {/* <DevTool control={methods.control} /> */}
      </FormLayout>
    </FormProvider>
  );
};

const FormLayout = styled.form`
  &.signIn {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    gap: 16px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 40px 90px;
    width: 500px;
    & > button {
      margin-top: 16px;
    }
  }
  &.signUp {
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 408px;
  }

  .test {
    background: white;
  }
  .search {
    background-color: white;
  }
`;

export default RHForm;
