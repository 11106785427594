import { ModalContext, ModalContextType } from "modules/context/ModalContext";
import { useContext } from "react";
import { ModalStandardProps } from "modules/defines/interfaces";
import styled from "styled-components";
import { titleContents } from "components/style/title";
import { palette } from "modules/defines/styles";
import { body12 } from "components/style/body";
import { motion } from "framer-motion";

interface ModalImageViewerProps extends ModalStandardProps {
  img: string;
  type: string; // 'represent' or 'detail'
}

const ModalImageViewer = ({ ...props }: ModalImageViewerProps) => {
  const { subject = "", img } = props;
  const { openModal, closeModal } = useContext(
    ModalContext
  ) as ModalContextType;
  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
      type={props.type}
    >
      <Subject>{subject}</Subject>
      <ImageWrapper>
        <Image src={img} type={props.type} />
      </ImageWrapper>
    </ModalWrapper>
  );
};

export default ModalImageViewer;

const ModalWrapper = styled(motion.div)<{ type: string }>`
  display: flex;
  flex-direction: column;
  width: auto;
  height: ${(props) => (props.type === "represent" ? "auto" : "90vh")};
  margin: 20px;
`;

const Subject = styled.h2`
  ${titleContents}
  color: ${palette.neutral1000};
`;
const ImageWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${palette.neutral100};
`;

const Image = styled.img<{ src: string; type: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  width: ${(props) => (props.type === "represent" ? "auto" : "40vw")};
  height: ${(props) => (props.type === "represent" ? "60vh" : "auto")};
`;
