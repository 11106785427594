import { ReactNode, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { ModalContext } from "modules/context/ModalContext";
import { icon_close } from "assets/icon";
import { palette } from "modules/defines/styles";

const ModalPortal = (props) => {
  const el = document.getElementById("app-modal");
  let { modal, modalContent } = useContext(ModalContext);
  const { openModal, closeModal } = useContext(ModalContext);

  // set 'ESC' keystroke to escape ========================
  const handleKeyDown = (e) => {
    if (e.code === "Escape") {
      closeModal();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  if (modal && el) {
    return createPortal(
      <>
        <ModalBackground>
          <ModalContent preview={props.preview}>
            <CloseButton src={icon_close} onClick={() => closeModal()} />
            {modalContent}
          </ModalContent>
        </ModalBackground>
      </>,
      el
    );
  } else return null;
};

export const ModalOpener = styled.div`
  cursor: pointer;
`;

const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: ${palette.white};
  /* display: flex; */
  position: relative;
  padding: 0px;
  border-radius: 16px;
  min-width: 400px;
`;

const CloseButton = styled.img`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 18px;
  top: 20px;
  cursor: pointer;
  z-index: 1000;
`;

export default ModalPortal;
