import { palette } from "modules/defines/styles";
import styled, { keyframes } from "styled-components";

const showBottomSheet = keyframes`
  from {
    transform: translateY(60px);
    opacity: 0;
  } to {
    transform: translateY(0);
    opacity: 1;
  }
`;
export const BottomSheetContainer = styled.div`
  position: fixed;
  bottom: 10vh;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 80vh;

  max-width: 40rem;
  background-color: ${palette.opacityB40};
  z-index: 3;
`;
export const BottomSheetWrapper = styled.div`
  animation: ${showBottomSheet} 0.3s ease-in-out;
  width: 100%;
  background-color: ${palette.white};
  position: fixed;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 0 20px 0 #e6e6e6;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;
export const BottomSheetCloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: ${palette.neutral1000};
`;
export const BottomSheetContentsWrapper = styled.div`
  height: 100%;
  padding: 30px 25px;
  font-family: "Pretendard";
  font-weight: 400;
`;
export const BottomSheetWrapperTitle = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
`;
export const BottomSheetWrapperDesc = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: ${palette.gray};
  line-height: 18px;
`;
export const BottomSheetInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
export const BottomSheetInput = styled.input`
  all: unset;
  height: 40px;
  width: calc(100%-20px);
  font-size: 16px;
  color: ${palette.darkgray};
  border: 1px solid ${palette.gray};
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 0px 10px;
`;
export const BottomSheetOptionTitle = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 4px;
  color: ${palette.primary500};
`;
export const BottomSheetOptionSelect = styled.select`
  all: unset;
  height: 40px;
  width: calc(100%-20px);
  font-size: 16px;
  color: ${palette.darkgray};
  border: 1px solid ${palette.gray};
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
`;

export const BottomSheetWarningText = styled.div`
  color: ${palette.fontWarning};
  font-size: 14px;
  margin-top: 12px;
`;
export const BottomSheetButtonRow = styled.div`
  font-family: "Pretendard";
  font-weight: 700;
  margin-bottom: 34px;
  padding: 0 25px;
  height: 60px;
  color: ${palette.white};
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Pretendard";
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
`;
export const BottomSheetBagButton = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? "#717171" : "#c1c0c9")};
  padding: 20px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-right: 8px;
  border: ${(props) =>
    props.active ? "1px solid #979797" : "1px solid #C1C0C9"};
`;
export const BottomSheetPurchaseButton = styled.button`
  all: unset;
  cursor: ${(props) => (props.active ? "pointer" : "default")};
  color: ${(props) => (props.active ? "#ffffff" : "#c1c0c9")};
  display: flex;
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  justify-content: center;
  max-width: 240px;
  align-items: center;
  background-color: ${(props) => (props.active ? "black" : "#f4f4f4")};
`;

export const SelectedProductContainer = styled.div`
  margin-top: 8px;
  padding-top: 16px;
  width: 100%;
  display: inline-block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;
  border-top: 1px solid ${palette.lightgray};
`;

export const SelectedProductWrapper = styled.div`
  display: inline-block;
  margin-right: 16px;
  background-color: ${palette.white};
  box-shadow: 0 0 20px 0 #e6e6e6;
  border: solid 1px ${palette.gray};
  width: 160px;
  height: 110px;
  border-radius: 16px;
  position: relative;
`;
export const SelectedProductExitBtn = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 24px;
  cursor: pointer;
  color: ${palette.neutral1000};
`;
export const SelectedProductItemWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 60px;
  width: 120px;
`;
export const SelectedProductItemListWrapper = styled.div`
  margin-bottom: 4px;
`;

export const SelectedProductItem = styled.div`
  font-size: 11px;
  font-family: "Pretendard";
  font-weight: 700;
  line-height: 20px;
`;
export const SelectedProductSubItem = styled.div`
  line-height: 8px;
  font-size: 10px;
  font-family: "Pretendard";
  font-weight: 400;
`;
export const SelectedProductPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  border-top: 1px solid ${palette.lightgray};
  position: absolute;
  bottom: 0px;
`;
export const SelectedProductAmountWrapper = styled.div`
  display: flex;
  margin-left: 4px;
`;
export const SelectedProductAmountBtn = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${palette.primary500};
  color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.primaryHover};
  }
`;
export const SelectedProductAmountText = styled.div`
  font-size: 12px;
  color: ${palette.neutral1000};
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SelectedProductPrice = styled.div`
  font-size: 14px;
  color: ${palette.gray};
  margin-right: 8px;
`;

export const FinalDiscountedPrice = styled.div`
  display: flex;
  color: ${palette.primary500};
  text-align: center;

  /* Body_Submenu */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

export const FinalPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${palette.lightgray};
  margin-top: 12px;
  padding-top: 20px;
  font-family: "Pretendard";
  font-weight: 700;
  align-items: baseline;
`;
export const FinalPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
`;

export const FinalPriceDesc = styled.div`
  color: ${palette.neutral550};
  text-align: center;

  /* Body_Submenu */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

export const FinalPriceText = styled.div`
  color: ${palette.neutral1000};
  text-align: center;
  /* H_PageTitle-25 */
  font-family: Pretendard;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 120% */
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const DropdownButton = styled.button`
  background-color: white;
  color: #717171;
  padding: 15px 20px;
  border: 1px solid #c1c0c9;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Pretendard";
  box-sizing: border-box;
  z-index: 1;
  position: relative;
  margin-bottom: 15px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

export const DropdownContent = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  top: 25px;
  padding-top: 25px;
  padding-bottom: 5px;
  position: absolute;
  background-color: white;
  min-width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  max-height: 150px;
  overflow-y: auto;
`;

export const DropdownItem = styled.div`
  padding: 10px 17px;
  cursor: pointer;
  border-top: 1px solid #eee;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Pretendard";

  &:first-child {
    border-top: none;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const ImportantText = styled.span`
  color: #ea4d5d;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Pretendard";
`;

export const SelectedOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 30vh;
`;

export const OptionRow = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const OptionTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const OptionTitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #393939;
`;

export const OptionNumberRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;

export const QuantityControl = styled.div`
  display: flex;
  align-items: center;

  button {
    background-color: #d9d9d9;
    border: none;
    padding: 0 10px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    font-size: 25px;
    color: white;
    &:hover {
      background-color: #ccc;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 36px;
    height: 36px;
    background-color: white;
    border: none;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
    text-align: center;
  }
`;

export const RemoveButton = styled.span`
  background: none;
  border: none;
  cursor: pointer;
  color: #717171;
  font-size: 20px;
`;
export const CloseButton = styled.img`
  cursor: pointer;
`;

export const PriceText = styled.div`
  color: #393939;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  font-family: "Pretendard";
  vertical-align: bottom;
`;

export const DiscountText = styled.span`
  color: #0097ff;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  font-family: "Pretendard";
  margin-right: 5px;
`;
