import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  color: string;
  size: string;
}

const TrashIcon: React.FC<IconProps> = ({
  color = palette.neutral600,
  size = 0,
}) => {
  const viewBox = `0 0 ${size} ${size}`;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="trash">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.00838 1.50821C5.22717 1.28942 5.52391 1.1665 5.83333 1.1665H8.16667C8.47609 1.1665 8.77283 1.28942 8.99162 1.50821C9.21042 1.727 9.33333 2.02375 9.33333 2.33317V3.49984H11.0765C11.0806 3.49979 11.0847 3.49979 11.0889 3.49984H11.6667C11.9888 3.49984 12.25 3.761 12.25 4.08317C12.25 4.40534 11.9888 4.6665 11.6667 4.6665H11.6201L11.0831 11.1099C11.0762 11.5643 10.8927 11.9987 10.5708 12.3206C10.2426 12.6488 9.79746 12.8332 9.33333 12.8332H4.66667C4.20254 12.8332 3.75742 12.6488 3.42923 12.3206C3.10734 11.9987 2.9238 11.5643 2.91687 11.1099L2.37992 4.6665H2.33333C2.01117 4.6665 1.75 4.40534 1.75 4.08317C1.75 3.761 2.01117 3.49984 2.33333 3.49984H2.91115C2.91527 3.49979 2.91938 3.49979 2.92349 3.49984H4.66667V2.33317C4.66667 2.02375 4.78958 1.727 5.00838 1.50821ZM3.55063 4.6665L4.08132 11.0347C4.08266 11.0508 4.08333 11.067 4.08333 11.0832C4.08333 11.2379 4.14479 11.3863 4.25419 11.4956C4.36358 11.605 4.51196 11.6665 4.66667 11.6665H9.33333C9.48804 11.6665 9.63642 11.605 9.74581 11.4956C9.85521 11.3863 9.91667 11.2379 9.91667 11.0832C9.91667 11.067 9.91734 11.0508 9.91868 11.0347L10.4494 4.6665H3.55063ZM8.16667 3.49984H5.83333V2.33317H8.16667V3.49984ZM5.83333 5.83317C6.1555 5.83317 6.41667 6.09434 6.41667 6.4165V9.9165C6.41667 10.2387 6.1555 10.4998 5.83333 10.4998C5.51117 10.4998 5.25 10.2387 5.25 9.9165V6.4165C5.25 6.09434 5.51117 5.83317 5.83333 5.83317ZM8.16667 5.83317C8.48883 5.83317 8.75 6.09434 8.75 6.4165V9.9165C8.75 10.2387 8.48883 10.4998 8.16667 10.4998C7.8445 10.4998 7.58333 10.2387 7.58333 9.9165V6.4165C7.58333 6.09434 7.8445 5.83317 8.16667 5.83317Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default TrashIcon;
