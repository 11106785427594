// 공통적으로 쓰이는 레이아웃을 정의합니다.

import { PlusIcon24 } from "assets/icons/Plus";
import { palette } from "modules/defines/styles";
import styled from "styled-components";

interface ImageButtonProps {
  text: string;
  // onClick: () => void;
}

export const ImageUploadButton = ({ ...props }: ImageButtonProps) => {
  return (
    // <Container onClick={props.onClick}>
    <Container>
      <PlusIcon24 color={palette.primary500} />
      <ButtonText>{props.text}</ButtonText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  padding: 35px 14px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  gap: 6px;

  border-radius: 8px;
  border: 1px solid ${palette.neutral300};
  background-color: ${palette.neutral100};

  cursor: pointer;
  &:hover {
    background-color: ${palette.opacityB20};
  }
`;

const ButtonText = styled.div`
  color: ${palette.neutral500};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* TitleText */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;
