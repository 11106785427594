import { UseControllerProps, useController } from "react-hook-form";
import { CellDiv, CellInput } from "../TextField";
import { useEffect } from "react";
import styled from "styled-components";
import { palette } from "modules/defines/styles";

export interface InputControlProps extends UseControllerProps {
  width?: string;
  marginTop?: string;
  placeholder: string;
  isButtonDisabled?: boolean;
  isEssential?: boolean;
  dtype?: string;
  type?: string;
  isCompleteVerification?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  name: string;
  defaultValue?: string | number;
  textAlign?: string;
  unit?: string;
  currentPrice?: number;
}

export const CellInputField = ({ ...props }: InputControlProps) => {
  const {
    width = "100%",
    disabled,
    placeholder,
    readOnly = false,
    dtype,
    type = "text",
    defaultValue,
    textAlign = "left",
    unit,
    currentPrice = -1,
  } = { ...props };
  const {
    field,
    fieldState: { error: errorMassage },
  } = useController(props);

  useEffect(() => {
    if (defaultValue !== null && defaultValue !== undefined) {
      field.onChange(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (field.name == "product-title") {
      if (field.value.length > 60) {
        alert("상품명은 60자를 넘을 수 없습니다.");
        field.onChange(field.value.slice(0, 60));
      }
    }
    if (field.name == "product-description") {
      if (field.value.length > 80) {
        alert("상품 설명은 80자를 넘을 수 없습니다.");
        field.onChange(field.value.slice(0, 80));
      }
    }
    if (field.name == "product-discount") {
      if (field.value > 100) {
        alert("할인율은 100%를 넘을 수 없습니다.");
        field.onChange(100);
      } else if (field.value < 0) {
        alert("할인율은 0% 미만이 될 수 없습니다.");
        field.onChange(0);
      }
    } else if (field.name == "product-price") {
      if (field.value < 0) {
        alert("가격은 0원 미만이 될 수 없습니다.");
        field.onChange(0);
      }
    }
    if (field.name == "product-price-final" && currentPrice >= 0) {
      if (Number(field.value) > Number(currentPrice)) {
        alert("최종가격은 판매가격을 넘을 수 없습니다.");
        field.onChange(currentPrice);
      }
    }
  }, [field.value]);

  return (
    <>
      <CellInput
        width={width}
        placeholder={placeholder}
        dtype={dtype}
        type={type}
        disabled={disabled}
        id={field.name}
        value={field.value || ""}
        onChange={field.onChange}
        onBlur={field.onBlur}
        readOnly={readOnly}
        textAlign={textAlign}
      />
      {unit && <UnitText>{unit}</UnitText>}
    </>
  );
};

const UnitText = styled.div`
  position: absolute;
  top: 10px;
  right: 24px;
  color: ${palette.neutral800};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* TitleText */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;
