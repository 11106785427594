import Icon from "assets/icons/Icon";
import { palette } from "modules/defines/styles";
import React, { useState } from "react";
import styled, { css } from "styled-components";

interface ToolTipProps {
  content: string;
  location: string; // 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  width: string;
  top: string;
  right: string;
}

const ToolTip: React.FC<ToolTipProps> = ({
  content,
  location = "bottom-right",
  width,
  top,
  right,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Container
      top={top}
      right={right}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <Icon icon="tooltip" fill={palette.neutral600} />
      {isHover && (
        <ToolTipContent location={location} width={width}>
          <ToolTipContentText>{content}</ToolTipContentText>
        </ToolTipContent>
      )}
    </Container>
  );
};

const Container = styled.div<{
  top: string;
  right: string;
}>`
  position: absolute;
  display: inline-block;
  cursor: pointer;
  top: ${(props) => (props.top ? props.top : "0px")};
  right: ${(props) => (props.right ? props.right : "0px")};
`;

const ToolTipContent = styled.div<{
  location: string;
  width: string;
}>`
  width: ${(props) => props.width};

  position: absolute;
  padding: 10px;
  border-radius: 4px;
  background: ${palette.opacityB80};
  white-space: pre-line;
  z-index: 2;
  text-align: left;
  ${(props) =>
    props.location === "bottom-right" &&
    `
      top: 100%;
      right: 100%;
      transform: translateX(100%);
    `}
  ${(props) =>
    props.location === "top-left" &&
    `
      bottom: 100%;
      left: 100%;
      transform: translateX(-100%);
    `}
  ${(props) =>
    props.location === "top-right" &&
    `
      bottom: 100%;
      right: 100%;
      transform: translateX(100%);
    `}
  ${(props) =>
    props.location === "bottom-left" &&
    `
      top: 100%;
      left: 100%;
      transform: translateX(-100%);
    `}
`;

const ToolTipContentText = styled.div`
  color: ${palette.white100};

  /* TitleContents */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 137.5% */

  white-space: pre-line;
`;

export default ToolTip;
