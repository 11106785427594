import Icon from "assets/icons/Icon";
import { palette } from "modules/defines/styles";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const StateContainer = () => {
  const location = useLocation();
  const locationState = location.state;

  return (
    <Container>
      {!locationState && <Icon icon="wrong" fill="#D9D9D9" />}
      {locationState && locationState.type === "success" && <Icon icon="check" fill="#D9D9D9" />}
      <Text>
        {!locationState && `잘못된 접근입니다.\n 다시 확인해 주세요.`}
        {locationState && locationState.type === "success" && "승인이 완료되었습니다."}
      </Text>
    </Container>
  );
};

const Text = styled.h1`
  white-space: pre-line;
  color: #1e1e1e;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px; /* 120% */
`;

const Container = styled.div`
  padding: 0 24px;
  /* background-color: red; */
  margin: 0 auto;
  max-width: 40rem;
  min-height: 100dvh;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${palette.white};
  box-shadow: 0 0 20px 0 #e6e6e6;
`;

export default StateContainer;
