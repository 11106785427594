import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  color?: string;
  size?: string;
}

const RedoIcon: React.FC<IconProps> = ({ color, size = 0 }) => {
  const viewBox = `0 0 ${size} ${size}`;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="redo">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.3313 2.46309C5.33951 1.87967 6.51125 1.64447 7.66603 1.79373C8.82081 1.94298 9.89457 2.4684 10.7219 3.28905C11.5492 4.10971 12.0842 5.18007 12.2445 6.33526C12.2889 6.65489 12.0661 6.95 11.747 6.99442C11.4279 7.03884 11.1333 6.81574 11.089 6.49611C10.9639 5.59481 10.5465 4.7597 9.90098 4.11941C9.25548 3.47911 8.41771 3.06917 7.51673 2.95272C6.61575 2.83627 5.70153 3.01978 4.91491 3.47497C4.39733 3.77448 3.95388 4.18068 3.61158 4.66278H4.66674C4.9889 4.66278 5.25007 4.92438 5.25007 5.24708C5.25007 5.56978 4.9889 5.83138 4.66674 5.83138H2.63812C2.62928 5.83159 2.62042 5.83159 2.61155 5.83138H2.3334C2.01124 5.83138 1.75007 5.56978 1.75007 5.24708V2.90987C1.75007 2.58717 2.01124 2.32557 2.3334 2.32557C2.65557 2.32557 2.91674 2.58717 2.91674 2.90987V3.65361C3.31256 3.1801 3.79034 2.77613 4.3313 2.46309ZM2.25311 7.00558C2.57221 6.96116 2.86684 7.18426 2.91119 7.50389C3.03623 8.40519 3.45366 9.2403 4.09916 9.8806C4.74466 10.5209 5.58243 10.9308 6.48341 11.0473C7.3844 11.1637 8.29861 10.9802 9.08523 10.525C9.60281 10.2255 10.0463 9.81932 10.3886 9.33722H9.33341C9.01124 9.33722 8.75007 9.07562 8.75007 8.75292C8.75007 8.43022 9.01124 8.16862 9.33341 8.16862H11.362C11.3709 8.16842 11.3797 8.16841 11.3886 8.16862H11.6667C11.9889 8.16862 12.2501 8.43022 12.2501 8.75292V11.0901C12.2501 11.4128 11.9889 11.6744 11.6667 11.6744C11.3446 11.6744 11.0834 11.4128 11.0834 11.0901V10.3464C10.6876 10.8199 10.2098 11.2239 9.66884 11.5369C8.66064 12.1203 7.4889 12.3555 6.33411 12.2063C5.17933 12.057 4.10557 11.5316 3.27824 10.7109C2.45091 9.89029 1.9159 8.81993 1.75562 7.66474C1.71128 7.34512 1.93401 7.05 2.25311 7.00558Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default RedoIcon;
