import { titleText } from "components/style/title";
import { palette } from "modules/defines/styles";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled, { css, keyframes } from "styled-components";

interface ToastProps {
  contents: string;
  duration?: string;
}

const Toast = ({ ...props }: ToastProps) => {
  let { contents, duration = 5000 } = { ...props };

  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
    const timeoutId = setTimeout(() => {
      setDomReady(false);
    }, duration as unknown as number);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  switch (contents) {
    case "signInError":
      contents = "아이디, 비밀번호를 다시 확인해주세요.";
      break;
    case "sendVerificationCode":
      contents = "인증번호가 발송되었습니다. 메일함을 확인해주세요.";
      break;
    case "completeVerificationCode":
      contents = "이메일 인증이 완료되었습니다.";
      break;
    case "error400":
      contents = "죄송합니다. 잠시 후 다시 시도해주세요.";
      break;
  }
  return domReady
    ? createPortal(
        <ToastWrapper duration={props.duration}>
          <Content>{contents}</Content>
        </ToastWrapper>,
        document.getElementById("toast") as HTMLElement
      )
    : null;
};

const fadeInAnimation = keyframes`
      0% {
        opacity: 0;
        transform: translateX(20%);
    }
    20% {
        opacity: 1;
        transform: translateX(0%);
    }
    80% {
        opacity: 1;
        transform: translateX(0%);
    }
    100% {
        opacity: 0;
        transform: translateX(0%);
        display: none;
    }


`;
const ToastWrapper = styled.div<{ duration?: string }>`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
  position: fixed;
  width: 460px;
  bottom: 40px;
  right: 40px;
  transform: translateX(20%);
  border-radius: 16px;
  background-color: ${palette.opacityB60};
  padding: 15px 24px;
  opacity: 0;
  animation: ${(props) =>
    css`
      ${fadeInAnimation} ${props.duration || "5s"} ease-in-out;
    `};
`;

const Content = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${titleText};
  color: ${palette.white};
  white-space: nowrap;
`;

export default Toast;
