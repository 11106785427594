import { bankList } from "modules/defines/define";
import { palette } from "modules/defines/styles";
import styled from "styled-components";
import BankListItem from "./BankListItem";
import { useState } from "react";
import { titleContents } from "components/style/title";
import Button from "../Button";
import { icon_close } from "assets/icon";

interface BankListProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setOpen: (open: boolean) => void;
  onComplete: () => void;
}
const BankList: React.FC<BankListProps> = ({ ...props }) => {
  const { onChange, setOpen, onComplete } = props;
  const [selectedBank, setSelectedBank] = useState("");

  const handleChange = (code: string) => {
    setSelectedBank(code);
  };
  return (
    <BankListWrapper>
      <CloseButton onClick={() => setOpen(false)} width={40} height={40} src={icon_close} />
      <BankListTitle>은행 선택</BankListTitle>
      <BackListUl>
        {bankList.map((bank, index) => {
          return (
            <BankListItem
              isChecked={selectedBank === bank.code}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(bank.code);
                onChange(e);
              }}
              key={index}
              code={bank.code}
              name={bank.name}
              radioGroupName="bank"
            />
          );
        })}
      </BackListUl>
      <Button type="button" styleType="primary" size="48" onClick={onComplete}>
        선택 완료
      </Button>
    </BankListWrapper>
  );
};

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
`;
const BankListTitle = styled.h2`
  ${titleContents};
  color: ${palette.neutral1000};
  margin-bottom: 12px;
`;
const BankListWrapper = styled.div`
  position: absolute;
  top: 48px;
  left: 50%;
  padding: 32px 24px;
  transform: translateX(-50%);
  width: 420px;
  height: 386px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px ${palette.opacityB25};
`;
const BackListUl = styled.ul`
  display: grid;
  align-items: center;
  justify-items: center;
  height: 216px;
  grid-template-columns: repeat(4, fit-content(100%));
  grid-template-rows: repeat(6, 40px);
  gap: 4px;
  margin-bottom: 24px;
`;

export default BankList;
