export const includeSpecialChar = (inputText: string): boolean => {
  if (
    inputText.includes("/") ||
    inputText.includes("!") ||
    inputText.includes("#") ||
    inputText.includes("$") ||
    inputText.includes("%") ||
    inputText.includes("^") ||
    inputText.includes("@") ||
    inputText.includes("&") ||
    inputText.includes("*") ||
    inputText.includes("(") ||
    inputText.includes(")") ||
    inputText.includes("+") ||
    inputText.includes("=") ||
    inputText.includes("`") ||
    inputText.includes("~") ||
    inputText.includes(",") ||
    inputText.includes("<") ||
    inputText.includes(">") ||
    inputText.includes("?")
  ) {
    return true;
  } else {
    return false;
  }
};

export function makeRandomID(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function utf8Encode(string: string) {
  string = string.replace(/\r\n/g, "\n");
  let utftext = "";

  for (let n = 0; n < string.length; n++) {
    const c = string.charCodeAt(n);

    if (c < 128) {
      utftext += String.fromCharCode(c);
    } else if (c > 127 && c < 2048) {
      utftext += String.fromCharCode((c >> 6) | 192);
      utftext += String.fromCharCode((c & 63) | 128);
    } else {
      utftext += String.fromCharCode((c >> 12) | 224);
      utftext += String.fromCharCode(((c >> 6) & 63) | 128);
      utftext += String.fromCharCode((c & 63) | 128);
    }
  }

  return utftext;
}

export function Base64Encode(input: string) {
  const keyStr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/!@#$%^&*()?=";
  let output = "";
  let chr1;
  let chr2;
  let chr3;
  let enc1;
  let enc2;
  let enc3;
  let enc4;
  let i = 0;
  input = utf8Encode(input);
  while (i < input.length) {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output =
      output +
      keyStr.charAt(enc1) +
      keyStr.charAt(enc2) +
      keyStr.charAt(enc3) +
      keyStr.charAt(enc4);
  }
  return output;
}

export function formatDateToKST(date: Date) {
  // KST 타임존 오프셋 (UTC+9)
  const KST_OFFSET = 9 * 60 * 60 * 1000;

  // UTC 시간을 KST 시간으로 변환
  const kstDate = new Date(date.getTime() + KST_OFFSET);

  // KST 시간 형식으로 문자열 생성
  const year = kstDate.getUTCFullYear();
  const month = String(kstDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(kstDate.getUTCDate()).padStart(2, "0");
  const hours = String(kstDate.getUTCHours()).padStart(2, "0");
  const minutes = String(kstDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(kstDate.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(kstDate.getUTCMilliseconds()).padStart(3, "0");

  // 최종 출력 문자열 생성
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+09:00`;
  return formattedDate;
}

export function maskName(name: string) {
  const length = name.length;
  const mid = Math.floor(length / 2);

  if (length % 2 === 0) {
    // 짝수 길이일 경우
    return name.substring(0, mid - 1) + "**" + name.substring(mid + 1);
  } else {
    // 홀수 길이일 경우
    return name.substring(0, mid) + "*" + name.substring(mid + 1);
  }
}
