import { titleText } from "components/style/title";
import { palette } from "modules/defines/styles";
import styled from "styled-components";

interface StatusProps {
  status: "primary" | "gray" | "red" | "accent";
}

const Status = styled.div<StatusProps>`
  ${titleText};
  text-align: center;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
  padding: 2px 8px;
  border-radius: 12px;
  color: ${({ status }) => {
    switch (status) {
      case "primary":
        return palette.primary500;
      case "gray":
        return palette.neutral500;
      case "red":
        return palette.secondary400;
      case "accent":
        return palette.white;
      default:
        return palette.primary500;
    }
  }};
  background-color: ${({ status }) => {
    switch (status) {
      case "primary":
        return palette.primary20;
      case "gray":
        return palette.neutral200;
      case "red":
        return palette.secondary20;
      case "accent":
        return palette.secondary400;
      default:
        return palette.primary20;
    }
  }};
`;

export default Status;
