import { PlusIcon16 } from "assets/icons/Plus";
import { palette } from "modules/defines/styles";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

// Button-34/b
export const Button34b = styled.button<{
  btype: string;
  disabled?: boolean;
  width?: string;
}>`
  width: ${(props) => (props.width ? props.width : "auto")};
  //font
  color: ${palette.white100};

  /* TitleContents */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */

  // layout
  padding: 6px 14px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${palette.primary500};
  &:hover {
    background-color: ${palette.primary600};
  }
  ${(props) =>
    props.btype === "primary" &&
    css`
      background-color: ${palette.primary500};
      &:hover {
        background-color: ${palette.primary600};
      }
    `}
  ${(props) =>
    props.btype === "tertiary" &&
    css`
      background-color: ${palette.neutral500};
      &:hover {
        background-color: ${palette.neutral600};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      padding: 6px 14px;
      background-color: ${palette.neutral300};
      &:hover {
        background-color: ${palette.neutral300};
      }
      cursor: default;
      color: ${palette.neutral400};
    `}
`;

/// Button-32/r
interface ButtonProps {
  children: string;
  background: boolean;
  btype: string;
  disabled: boolean;
}
export const Button32r = ({ ...props }: ButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {}, [isHovering]);

  return (
    <Button32rWrapper
      bgColor={"#0097FF"}
      onMouseEnter={() => {
        if (!props.disabled) setIsHovering(true);
      }}
      onMouseLeave={() => {
        if (!props.disabled) setIsHovering(false);
      }}
    >
      <PlusIcon16 color={"#0097FF"} />
      <Button32rValue color={"#0097FF"}>{props.children}</Button32rValue>
    </Button32rWrapper>
  );
};

const Button32rWrapper = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  display: inline-flex;
  padding: 6px 12px 6px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
`;

const Button32rValue = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-feature-settings: "clig" off, "liga" off;
  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

// Button / text / solid
export const ButtonTextSolid = styled.button<{
  btype: string;
  disabled?: boolean;
}>`
  font-feature-settings: "clig" off, "liga" off;

  padding: 4px 10px;
  border-radius: 8px;

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  ${(props) =>
    props.btype === "on" &&
    css`
      color: ${palette.white100};
      background-color: ${palette.primary500};
      &:hover {
        background-color: ${palette.primary600};
      }
      ${props.disabled &&
      css`
        color: ${palette.neutral400};
        cursor: default;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      `}
    `}
  ${(props) =>
    props.btype === "off" &&
    css`
      color: ${palette.neutral700};
      &:hover {
        background-color: ${palette.neutral400};
      }
      ${props.disabled &&
      css`
        color: ${palette.neutral500};
        cursor: default;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      `}
    `}
`;
// Button / text / 14
export const ButtonText14 = styled.button<{
  btype: string;
  disabled?: boolean;
}>`
  font-feature-settings: "clig" off, "liga" off;

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 8px;

  ${(props) =>
    props.btype === "wh" &&
    css`
      color: ${palette.white100};
    `}
  ${(props) =>
    props.btype === "bk" &&
    css`
      color: ${palette.neutral1000};
    `}
      ${(props) =>
    props.btype === "main" &&
    css`
      color: ${palette.primary500};
    `}
        ${(props) =>
    props.btype === "secondary" &&
    css`
      color: ${palette.secondary400};
    `}
          ${(props) =>
    props.btype === "tertiary" &&
    css`
      color: ${palette.neutral500};
    `}
  &:hover {
    background-color: ${palette.opacityB40};
  }
  ${(props) =>
    props.disabled &&
    css`
      color: ${palette.neutral300};
      background-color: transparent;
    `}
`;

// Button / solid / 48
export const ButtonSolid48 = styled.button<{
  btype: string;
  disabled?: boolean;
  width?: string;
}>`
  display: flex;
  height: 48px;
  width: ${(props) => (props.width ? props.width : "100%")};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-feature-settings: "clig" off, "liga" off;

  /* TitleContents */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */

  cursor: pointer;
  border-radius: 10px;

  ${(props) =>
    props.btype === "primary" &&
    css`
      background-color: ${palette.primary500};
      color: ${palette.white100};
      &:hover {
        background-color: ${palette.primary600};
      }
    `}
  ${(props) =>
    props.btype === "secondary" &&
    css`
      background-color: ${palette.opacity1000};
      color: ${palette.white100};
      &:hover {
        background-color: ${palette.neutral700};
      }
    `}
  ${(props) =>
    props.btype === "outline" &&
    css`
      background-color: ${palette.white100};
      border: 1px solid ${palette.neutral300};
      color: ${palette.neutral600};
      &:hover {
        background-color: ${palette.neutral300};
      }
    `}
`;
