import { body12 } from "components/style/body";
import { palette } from "modules/defines/styles";
import styled from "styled-components";

interface CategoryProps {
  outline: boolean;
}

const Category = styled.div`
  ${body12};
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.primary500};
  background-color: ${palette.white};
  padding: 4px 6px;
  border-radius: 4px;
  background-color: ${(props: CategoryProps) =>
    props.outline ? palette.white : palette.primary20};
  border: ${(props: CategoryProps) => (props.outline ? `1px solid ${palette.primary500}` : "none")};
  grid-area: category;
`;

export default Category;
