import { palette } from "modules/defines/styles";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle` 

  ${reset} // styled-reset이라는 패키지를 설치해야한다. 몇가지만 reset해 줄 경우 사용하지 않아도 무방하다.

    a{
        text-decoration: none;
        color: inherit;
    }
    *{
        box-sizing: border-box;
        font-family : Pretendard;
    
       
    }
    
    html, body, div, span, h1, h2, h3, h4, h5, h6, p, 
    a, dl, dt, dd, ol, ul, li, form, label, table{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 10px;
        vertical-align: baseline;
        font-family : Pretendard;
     
    }
    input, textarea { 
        -moz-user-select: auto;
        -webkit-user-select: auto;
        -ms-user-select: auto;
        user-select: auto;
    }

    input:-webkit-autofill {
	    -webkit-text-fill-color:${palette.neutral1000};
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${palette.neutral1000};
        border: 1px solid ${palette.neutral200};
    }

    input:-webkit-autofill:focus {
         -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${palette.neutral1000};
        border: 1px solid ${palette.primary500};
        box-sizing: border-box;
    }
        
    input:-webkit-autofill:disabled {
        -webkit-box-shadow: 0 0 0px 1000px ${palette.primary20} inset;
        box-shadow: 0 0 0px 1000px ${palette.primary20} inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${palette.neutral500};
        border: 1px solid ${palette.neutral400};
        box-sizing: border-box;
        cursor: default;
    }

    input:autofill{
	    -webkit-text-fill-color: ${palette.neutral1000};
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${palette.neutral1000};
    }

     input:autofill:focus {
         -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${palette.neutral1000};
        border: 1px solid ${palette.primary500};
        box-sizing: border-box;
    }
        
    input:autofill:disabled {
        -webkit-box-shadow: 0 0 0px 1000px ${palette.primary20} inset;
        box-shadow: 0 0 0px 1000px ${palette.primary20} inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${palette.neutral500};
        border: 1px solid ${palette.neutral400};
        box-sizing: border-box;
        cursor: default;
    }

    button {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;
    }

    .line-clamp {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    }

    body::-webkit-scrollbar {
        display: none;

    }




 
`;

export default GlobalStyles;
