import {
  Control,
  FieldValues,
  Path,
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import styled, { keyframes } from "styled-components";

import { palette } from "modules/defines/styles";
import { ChangeEvent, InputHTMLAttributes, PropsWithChildren, useCallback, useState } from "react";
import { icon_close } from "assets/icon";
import { body12 } from "components/style/body";
import { titleContents } from "components/style/title";
import { caption } from "components/style/caption";

interface IRHInput<FormType extends object> extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<FormType>;
  rules?: RegisterOptions<FormType>;
}
function RHImageFileInput<FormType extends object>({
  name,
  rules,
  ...rest
}: PropsWithChildren<IRHInput<FormType>>) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { control } = useFormContext<FormType>();
  const { defaultValue = [] } = { ...rest };
  const {
    field,
    fieldState: { error: errorMassage },
  } = useController({
    name,
    control,
    defaultValue: defaultValue as any,
    rules,
  });
  const handleDelete = useCallback(() => {
    setIsDeleting(true);
    const id = setTimeout(() => {
      field.onChange([]);
      setIsDeleting(false);
    }, 1000);
    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, isDeleting]);
  const filename = (field.value as FileList)[0]?.name;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        position: "relative",
      }}
    >
      <Title>대표 사진</Title>

      <Label className={filename ? "disabled" : ""} typeof="button" htmlFor="imageFile">
        찾아보기
        <Input
          disabled={filename ? true : false}
          name={field.name}
          ref={field.ref}
          onChange={(e) => {
            field.onChange(e.target.files);
          }}
          {...rest}
          id="imageFile"
          accept="image/png,image/jpeg,image/jpg"
          type="file"
        />
      </Label>
      {!filename && <Description>500MB 이하의 jpg, png 파일이 업로드 가능합니다.</Description>}
      <FileName className={isDeleting ? "deleting" : ""}>
        {filename && (
          <>
            {`파일명: ${filename.replace("C:\\fakepath\\", "")}`}
            <FileCloseButton onClick={handleDelete} src={icon_close} alt="대표 사진 파일 지우기" />
          </>
        )}
      </FileName>
    </div>
  );
}

export default RHImageFileInput;

const Animation = keyframes`
  0% {
    text-decoration: none;
    opacity: 1;
  }
  100% {
    text-decoration: line-through;
    opacity: 0.1;
  }

`;

const Title = styled.label`
  ${body12};
  display: flex;
  align-items: center;

  gap: 4px;
  color: ${palette.neutral700};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
`;

const Description = styled.p`
  position: absolute;
  bottom: -22px;
  left: 3px;
  margin-top: 4px;
  margin-bottom: 16px;
  color: ${palette.neutral700};
  ${caption}
`;
const Label = styled.label`
  ${titleContents};

  cursor: pointer;
  width: 100%;
  text-align: center;
  color: ${palette.white};
  background-color: ${palette.primary500};
  box-shadow: 0 0 0 1px ${palette.primary500} inset;
  padding: 13px 10px;
  border-radius: 10px;
  &:hover {
    background-color: ${palette.primary600};
  }
  &.disabled {
    cursor: default;
    width: 100%;
    text-align: center;
    background: ${palette.neutral200};
    color: ${palette.neutral400};
    box-shadow: 0 0 0 1px ${palette.neutral400} inset;
    padding: 13px 10px;
    border-radius: 10px;
  }
`;

const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FileName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${palette.neutral700};
  ${caption};
  padding: 2px 0;
  &.deleting {
    animation: ${Animation} 1s;
  }
`;

const FileCloseButton = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 1000px;
`;
