import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  color: string;
}

const SearchBarIcon: React.FC<IconProps> = ({ color = palette.neutral400 }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="search2">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.66699 9.16699C1.66699 5.02486 5.02486 1.66699 9.16699 1.66699C13.3091 1.66699 16.667 5.02486 16.667 9.16699C16.667 10.9378 16.0533 12.5653 15.0269 13.8484L18.0896 16.9111C18.415 17.2365 18.415 17.7641 18.0896 18.0896C17.7641 18.415 17.2365 18.415 16.9111 18.0896L13.8484 15.0269C12.5653 16.0533 10.9378 16.667 9.16699 16.667C5.02486 16.667 1.66699 13.3091 1.66699 9.16699ZM9.16699 3.33366C5.94533 3.33366 3.33366 5.94533 3.33366 9.16699C3.33366 12.3887 5.94533 15.0003 9.16699 15.0003C12.3887 15.0003 15.0003 12.3887 15.0003 9.16699C15.0003 5.94533 12.3887 3.33366 9.16699 3.33366Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default SearchBarIcon;
