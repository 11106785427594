import { icon_fav } from "assets/icon";
import CalculatorIcon from "assets/icons/Calculator";
import DashboardIcon from "assets/icons/Dashboard";
import EditIcon from "assets/icons/Edit";
import ListSearchIcon from "assets/icons/ListSearch";
import { ButtonText14 } from "components/common/Button";
import { palette } from "modules/defines/styles";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Player } from "@lottiefiles/react-lottie-player";
import spinner from "assets/lottie/spinner.json";
import { useHistory } from "react-router-dom";
const Loading = () => {
  const history = useHistory();
  return (
    <Container>
      <Navigation>
        <HeaderContainer>
          <img src={icon_fav} style={{ width: 32, height: 32 }} />
          <HeaderTitle>FAV 스토어관리</HeaderTitle>
        </HeaderContainer>

        <NavItemContainer>
          <NavItem enabled={false} title={"대시보드"} marginTop={"0px"} icon={DashboardIcon} />
          <NavGroupTitle marginTop="16px">상품 관리</NavGroupTitle>
          <NavItem
            enabled={false}
            title={"상품 조회/관리"}
            marginTop={"8px"}
            icon={ListSearchIcon}
          />
          <NavItem enabled={false} title={"상품 등록"} marginTop={"0px"} icon={EditIcon} />
          <NavGroupTitle marginTop="16px">정산 관리</NavGroupTitle>
          <NavItem enabled={false} title={"정산 내역"} marginTop={"8px"} icon={CalculatorIcon} />
        </NavItemContainer>
      </Navigation>
      <ContentsContainer>
        <TopBarContainer>
          <TopBarTitleWrapper>
            <TopBarTitle></TopBarTitle>
            <TopBarRole></TopBarRole>
            <TopBarName>매니저 정보를 불러오고있습니다...</TopBarName>
            <div onClick={() => history.push("/test")}>테스트</div>ㄴ
          </TopBarTitleWrapper>
          <TopBarButtonWrapper>
            <TopBarTextButtonAlertWrapper>
              <ButtonText14 disabled btype="bk">
                알림
              </ButtonText14>
              {/* {alertData && alertData.length > 0 && <TopBarTextButtonAlertIcon />} */}
            </TopBarTextButtonAlertWrapper>
            <ButtonText14 disabled btype="bk">
              설정
            </ButtonText14>
            <ButtonText14 disabled btype="tertiary">
              로그인 중..
              {/* {isLogin ? "로그아웃" : "로그인"} */}
            </ButtonText14>
          </TopBarButtonWrapper>
        </TopBarContainer>

        <ContentsWrapper>
          <Player
            style={{
              width: "40px",
              height: "40px",
            }}
            src={spinner}
            autoplay
            loop
          />
        </ContentsWrapper>
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

interface IconProps {
  enabled: boolean;
  size: string;
}

interface NavItemProps {
  enabled: boolean;
  onClick?: () => void;
  title: string;
  marginTop?: string;
  icon: React.ComponentType<IconProps>;
}

const NavItem: React.FC<NavItemProps> = ({ marginTop, title, enabled, onClick, icon: Icon }) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <NavItemWrapper
      marginTop={marginTop}
      onClick={onClick}
      enabled={enabled}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      isHovering={isHovering}
    >
      <Icon enabled={enabled || isHovering} size={"24"} />

      <NavTitle>{title}</NavTitle>
    </NavItemWrapper>
  );
};

const Navigation = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 240px;
  background-color: ${palette.white};
  z-index: 101;
  border-right: 1px solid ${palette.neutral300};
  padding-top: 14px;
`;

const HeaderContainer = styled.div`
  margin-left: 14px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  cursor: pointer;
`;

const HeaderTitle = styled.div`
  color: ${palette.primary900};
  font-feature-settings: "clig" off, "liga" off;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
`;

const NavItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const NavGroupTitle = styled.div<{ marginTop?: string }>`
  padding: 0 16px;
  color: var(--Netural-Neutral500, #717784);
  font-feature-settings: "clig" off, "liga" off;
  /* caption */

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */

  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
`;

const NavItemWrapper = styled.div<{
  marginTop?: string;
  enabled: boolean;
  isHovering: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background-color: ${palette.secondary90};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  cursor: pointer;
  border-radius: 4px;
  ${(props) =>
    props.enabled
      ? css`
          background-color: ${palette.neutral500};
          color: ${palette.white};
          pointer-events: none;
        `
      : css`
          background-color: ${palette.white};
          color: ${palette.neutral700};
          &:hover {
            background-color: ${palette.opacityB40};
            color: ${palette.white};
          }
        `}
`;

const NavTitle = styled.div`
  font-feature-settings: "clig" off, "liga" off;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;

const TopBarContainer = styled.div`
  height: 62px;
  padding: 24px 32px 10px 32px;
  background-color: ${palette.white};
  display: flex;
  justify-content: space-between;
`;
const TopBarTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TopBarTitle = styled.div`
  color: ${palette.neutral800};
  font-feature-settings: "clig" off, "liga" off;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
`;

const TopBarRole = styled.div`
  margin-left: 16px;
  color: ${palette.neutral800};
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;

const TopBarName = styled.div`
  margin-left: 4px;
  color: ${palette.neutral800};
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const TopBarButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TopBarTextButtonAlertWrapper = styled.div`
  position: relative;
`;

const ContentsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100vw - 240px);
  background-color: ${palette.neutral200};
  display: flex;
  flex-direction: column;
`;
const ContentsWrapper = styled.div`
  padding: 16px 16px 20px 24px;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Loading;
