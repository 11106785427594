export const palette = {
  ////////////////////////////////////////////////

  white: "#FFFFFF",
  black: "#000000",
  gray: "#7A7A7A",
  darkgray: "#323232",
  lightgray: "#AEAEAE",

  fontWhite: "#FFFFFF",
  neutral100: "#F8F9FC",
  neutral200: "#F1F3F9",
  neutral300: "#E1E6EF",
  neutral400: "#9CA5B8",
  neutral500: "#717784",
  neutral550: "#717171",
  neutral600: "#585E6A",
  neutral700: "#3F444D",
  neutral800: "#23272F",
  neutral900: "#1B1F27",
  neutral1000: "#1E1E1E",

  opacityB20: "rgba(0, 0, 0, 0.20)",
  opacityB25: "rgba(0, 0, 0, 0.25)",
  opacityB40: "rgba(0, 0, 0, 0.40)",
  opacityB60: "rgba(0, 0, 0,  0.60)",
  opacityB80: "rgba(0, 0, 0, 0.80)",

  opacityW40: "rgba(255, 255, 255, 0.40)",
  opacityW60: "rgba(255, 255, 255, 0.60)",
  opacityW80: "rgba(255, 255, 255, 0.80)",
  opacity1000: "#000000",

  primary20: "#F5FBFF",
  primary90: "#E5F4FF",
  primary100: "#CCEAFF",
  primary200: "#99D5FF",
  primary300: "#CCEAFF",
  primary400: "#33ACFF",
  primary500: "#0097FF",
  primary600: "#0079CC", //hover
  primary700: "#005B99",
  primary800: "#003C66",
  primary900: "#001E33",
  primaryHover: "#0067AD",
  secondary002: "rgba(193, 192, 201, 1)",
  secondary20: "#FEF6F7",
  secondary90: "#FAD6DA",
  secondary400: "#EA4D5D",
  secondary500: "#D3293A",

  white100: "#ffffff",

  grayEmptyElement: "#D7D7D7",
  fontWarning: "#DC3545",
};

export const typographyTheme = {
  font: {
    caption: {
      size: "12px",
      weight: 400,
      lineHeight: "14px",
    },
    caption02: {
      size: "10px",
      weight: 400,
      lineHeight: "10px",
      letterSpacing: "-0.2px",
    },
    body12: {
      size: "12px",
      weight: 500,
      lineHeight: "16px",
      letterSpacing: "-0.24px",
    },
    body14: {
      size: "14px",
      weight: 400,
      lineHeight: "22px",
    },
    body16: {
      size: "14px",
      weight: 400,
      lineHeight: "22px",
    },
    bodyText16: {
      size: "16px",
      weight: 400,
      lineHeight: "23px",
      letterSpacing: "-0.32px",
    },
    subtitle14: {
      size: "14px",
      weight: 400,
      lineHeight: "20px",
    },
    subtitle16: {
      size: "16px",
      weight: 400,
      lineHeight: "22px",
    },
    subtitle18: {
      size: "18px",
      weight: 400,
      lineHeight: "26px",
    },

    titleContents: {
      size: "16px",
      weight: 700,
      lineHeight: "22px",
    },
    titlePage: {
      size: "20px",
      weight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.4px",
    },
    titleCard: {
      size: "24px",
      weight: 700,
      lineHeight: "36px",
      letterSpacing: "-0.48px",
    },
    title: {
      size: "18px",
      weight: 700,
      lineHeight: "26px",
    },
    titleText: {
      size: "14px",
      weight: 700,
      lineHeight: "20px",
    },
    TextButton: {
      size: "13px",
      weight: 400,
      lineHeight: "20px",
    },
  },
};
