import { useModal } from "modules/customHooks/useModal";
import { ReactNode, createContext } from "react";
import ModalPortal from "components/modal/ModalPortal";

export interface ModalContextType {
  modal: boolean;
  openModal: (content: ReactNode) => void;
  closeModal: () => void;
  modalContent: ReactNode;
}

let ModalContext = createContext<ModalContextType | null>(null);

let ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  let { modal, openModal, closeModal, modalContent } = useModal();
  return (
    <ModalContext.Provider
      value={{ modal, openModal, closeModal, modalContent }}
    >
      <ModalPortal />
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
