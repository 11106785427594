import styled from "styled-components";
import { palette } from "modules/defines/styles";
import { ReactNode } from "react";

interface TextButtonStyleProps {
  size: "14" | "bold_16";
  styleType: "white" | "bk" | "main" | "secondary" | "tertiary" | "default";
  disabled: boolean;
  children: React.PropsWithChildren<ReactNode>;
  onClick?: () => void;
}

const colorStyleType = (styleType: TextButtonStyleProps["styleType"]) => {
  switch (styleType) {
    case "white":
      return "#fff";
    case "bk":
      return "#000";
    case "main":
      return palette.primary500;
    case "secondary":
      return palette.secondary400;
    case "tertiary":
      return palette.neutral500;
    case "default":
      return palette.neutral700;
    default:
      return "#000";
  }
};

const TextButtonStyle = styled.button<TextButtonStyleProps>`
  color: ${(props) => colorStyleType(props.styleType)};
  padding: ${(props) => (props.size === "14" ? "4px 10px" : "6px 10px")};
  font-size: ${(props) =>
    props.size === "14" ? props.theme.typographyTheme.font.subtitle14.size : "16px"};
  font-weight: ${(props) =>
    props.size === "14" ? props.theme.typographyTheme.font.subtitle14.weight : 700};
  line-height: ${(props) =>
    props.size === "14" ? props.theme.typographyTheme.font.subtitle14.lineHeight : "22px"};
  &:hover {
    border-radius: 8px;
    background: ${(props) =>
      props.styleType === "default" ? palette.neutral400 : palette.opacityB40};
  }
  &:disabled {
    cursor: default;
    background: transparent;
    color: ${palette.neutral300};
  }
`;

const TextButton: React.FC<TextButtonStyleProps> = ({
  size,
  styleType,
  disabled,
  children,
  onClick,
}) => {
  return (
    <TextButtonStyle
      type="button"
      onClick={onClick}
      size={size}
      styleType={styleType}
      disabled={disabled}
    >
      {children}
    </TextButtonStyle>
  );
};
export default TextButton;
