import { css } from "styled-components";

const subtitle14 = css`
  font-size: ${(props) => props.theme.typographyTheme.font.subtitle14.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.subtitle14.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.subtitle14.lineHeight};
`;

const subTitle = css`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
`;
export { subtitle14, subTitle };
