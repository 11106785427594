import CircleInfo from "assets/icons/CircleInfo";
import { palette } from "modules/defines/styles";
import styled from "styled-components";

interface ProductDescriptionBarProps {
  text: string;
  marginTop?: string;
}

export const ProductDescriptionBar = ({
  ...props
}: ProductDescriptionBarProps) => {
  return (
    <Container marginTop={props.marginTop || "0px"}>
      <CircleInfo />
      {props.text}
    </Container>
  );
};

const Container = styled.div<{ marginTop: string }>`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${palette.neutral100};
  color: ${palette.neutral800};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  border-radius: 6px;
  border: 1px solid ${palette.primary200};
  background-color: ${palette.primary90};

  // font
  color: ${palette.primary600};
  font-feature-settings: "clig" off, "liga" off;

  /* caption */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
`;
