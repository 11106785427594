import Icon from "assets/icons/Icon";

import styled from "styled-components";

const HiddenCheckBox = styled.input`
  display: none;
`;

const CheckBoxLabel = styled.label`
  display: flex;

  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  background-color: white;
  cursor: pointer;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border: 2px solid #000000;

    border-radius: 2px;
  }
  position: relative;
  border: 1px solid #000000;
  &.checked {
    background-color: black;
  }
`;

const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ICheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const CheckBox: React.FC<ICheckBoxProps> = ({ ...props }) => {
  return (
    <CheckBoxWrapper>
      <HiddenCheckBox {...props} />
      <CheckBoxLabel className={props.checked ? "checked" : ""} htmlFor={props.id}>
        {props.checked ? (
          <Icon
            css={{
              position: "absolute",
              top: "1px",
              left: "-3.5px",
            }}
            icon="checkArrow"
            fill="white"
          />
        ) : null}
      </CheckBoxLabel>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
