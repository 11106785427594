import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserAgent } from "utils/userAgent";
import { ModalProvider } from "modules/context/ModalContext";
import GlobalStyles from "GlobalStyles";
import RouterComponent from "router/router";
import { ThemeProvider } from "styled-components";
import { typographyTheme } from "modules/defines/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GlobalFontStyle } from "modules/defines/fonts";
import { ToastType } from "modules/defines/define";
import { CookiesProvider } from "react-cookie";
import { toastContext } from "modules/customHooks/useToastContext";

// import { GlobalFontStyle } from "modules/defines/fonts";
function App() {
  const queryClient = new QueryClient();
  const [toast, setToast] = useState<ToastType>("completeVerificationCode");
  const { os } = UserAgent;

  useEffect(() => {
    if (os.isIos || os.isAndroid) {
      document.body.classList.add("isMobile");
    } else {
      document.body.classList.add("isDesktop");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <toastContext.Provider value={{ toast, setToast }}>
        <GlobalFontStyle />
        <ThemeProvider theme={{ typographyTheme }}>
          <BrowserRouter>
            <CookiesProvider>
              <ModalProvider>
                <GlobalStyles />

                <RouterComponent />
              </ModalProvider>
            </CookiesProvider>
          </BrowserRouter>
        </ThemeProvider>
        <ReactQueryDevtools />
      </toastContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
