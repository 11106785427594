import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  BottomSheetContainer,
  BottomSheetContentsWrapper,
  BottomSheetPurchaseButton,
  BottomSheetBagButton,
  BottomSheetWrapper,
  BottomSheetWrapperTitle,
  BottomSheetButtonRow,
  FinalPriceContainer,
  FinalPriceDesc,
  FinalPriceText,
  DropdownContainer,
  DropdownButton,
  DropdownContent,
  DropdownItem,
  ImportantText,
  SelectedOptionContainer,
  OptionRow,
  OptionTitle,
  QuantityControl,
  PriceText,
  OptionTitleRow,
  OptionNumberRow,
  DiscountText,
  CloseButton,
  FinalPriceWrapper,
} from "./BottomSheetCommon";
import { addCommaToNum, deleteCommaToStr } from "modules/functions/utils";
import { icon_close } from "assets/icon";
const BottomSheetDefault = ({
  closeBottomSheet,
  product,
  totalPrice,
  uidFromApp,
}) => {
  const [options, setOptions] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [selectedNum, setSelectedNum] = useState(0); // for detect event
  const [finalPrice, setFinalPrice] = useState(0); // final price (결제 금액)
  const [discountedPrice, setDiscountedPrice] = useState(0);

  const [selectedCount, setSeletedCount] = useState(1); // Option이 없을 때 선택한 상품 수량

  // set 'ESC' keystroke to escape ========================
  const handleKeyDown = (e) => {
    if (e.code === "Escape") {
      closeBottomSheet();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Initialize (Option 불러와서 적용하기) (Option이 있을 때만)
  useEffect(() => {
    if (product.options !== null && product.options.length !== 0) {
      initializeOptions();
    }
  }, []);

  const initializeOptions = () => {
    const tempData = cloneDeep(product.options);
    setOptions(product.options);
    tempData.map((item) => {
      item["count"] = 0;
    });
    setOptionsSelected(tempData);
  };
  // Option 선택 변경 시 final price 변경 (Option이 있을 때만)
  useEffect(() => {
    var countPrice = 0; // 할인 전 금액
    optionsSelected.map((optionItem) => {
      countPrice +=
        (optionItem["price"] + parseInt(product.price)) * optionItem["count"];
    });
    setFinalPrice(product.priceFinal);
    setDiscountedPrice(countPrice - product.priceFinal);
  }, [optionsSelected]);

  // Check if complete
  useEffect(() => {
    // count
    var totalCount = 0;
    var selectedCount = 0;
    if (totalCount === selectedCount && totalCount !== 0) {
      var isDuplicate = false;
      optionsSelected.map((optionItem) => {
        var buffItem = cloneDeep(optionItem);
        delete buffItem["count"];
        delete buffItem["price"];
        if (JSON.stringify(buffItem) === JSON.stringify(options)) {
          isDuplicate = true;
          optionItem["count"] += 1;
          setOptionsSelected((prev) => [...prev]);
        }
      });
      if (!isDuplicate) {
        var optionsBuff = cloneDeep(options);
        var itemPrice = deleteCommaToStr(totalPrice);
        optionsBuff["price"] = itemPrice;
        optionsBuff["count"] = 1;

        setOptionsSelected((prev) => [...prev, optionsBuff]);
      }

      initializeOptions();
      // Select > Option 초기화
      const optionElements = document.getElementsByClassName("optionClass");
      const elements = Array.from(optionElements);
      elements.forEach((element) => {
        element.selected = true;
      });
    }
  }, [selectedNum]);

  const onClickPurchaseFinalButton = async (e) => {
    e.preventDefault();
  };

  const onClickCloseButton = (e) => {
    e.preventDefault();
    closeBottomSheet();
  };

  const selectOption = (optionItem) => {
    setOptionsSelected((prevOptions) =>
      prevOptions.map((option) =>
        option.title === optionItem.title
          ? { ...option, count: option.count + 1 }
          : option
      )
    );
    setShow(false);
  };

  const increaseQuantity = (optionItem) => {
    setOptionsSelected((prevOptions) =>
      prevOptions.map((option) =>
        option.title === optionItem.title
          ? { ...option, count: option.count + 1 }
          : option
      )
    );
  };

  const decreaseQuantity = (optionItem) => {
    setOptionsSelected((prevOptions) =>
      prevOptions.map((option) =>
        option.title === optionItem.title && option.count > 0
          ? { ...option, count: option.count - 1 }
          : option
      )
    );
  };

  const removeOption = (optionItem) => {
    setOptionsSelected((prevOptions) =>
      prevOptions.map((option) =>
        option.title === optionItem.title ? { ...option, count: 0 } : option
      )
    );
  };

  const [show, setShow] = useState(false);
  const toggleDropdown = () => {
    setShow(!show);
  };
  return (
    <BottomSheetContainer onClick={(e) => onClickCloseButton(e)}>
      <BottomSheetWrapper onClick={(e) => e.stopPropagation()}>
        <BottomSheetContentsWrapper>
          <BottomSheetWrapperTitle>상품 구매</BottomSheetWrapperTitle>
          {product.options !== null && product.options.length !== 0 && (
            <DropdownContainer>
              <DropdownButton onClick={toggleDropdown}>{"옵션"}</DropdownButton>
              <DropdownContent show={show}>
                {options &&
                  options.map((item, idx) => {
                    return (
                      <DropdownItem
                        key={`option-${idx}`}
                        onClick={() =>
                          product.countByOptions
                            ? item.count !== 0
                              ? selectOption(item)
                              : alert("해당 상품은 품절입니다.")
                            : product.count !== 0
                            ? selectOption(item)
                            : alert("해당 상품은 품절입니다.")
                        }
                      >
                        {item.title} ({Number(item.price) >= 0 && "+"}
                        {addCommaToNum(item.price)}원)
                        {product.countByOptions
                          ? item.count <= 10 && (
                              <ImportantText>
                                {" "}
                                / 잔여: {item.count}개
                              </ImportantText>
                            )
                          : product.count <= 10 && (
                              <ImportantText>
                                {" "}
                                / 잔여: {product.count}개
                              </ImportantText>
                            )}
                      </DropdownItem>
                    );
                  })}
              </DropdownContent>
            </DropdownContainer>
          )}
          {optionsSelected.length !== 0 ? (
            <SelectedOptionContainer>
              {optionsSelected
                .filter((option) => option.count > 0)
                .map((optionItem, idx) => {
                  return (
                    <OptionRow key={`option-row-${idx}`}>
                      <OptionTitleRow>
                        <OptionTitle>{optionItem.title}</OptionTitle>
                        <CloseButton
                          src={icon_close}
                          onClick={() => removeOption(optionItem)}
                        />
                      </OptionTitleRow>
                      <OptionNumberRow>
                        <QuantityControl>
                          <button onClick={() => decreaseQuantity(optionItem)}>
                            -
                          </button>
                          <span>{optionItem.count}</span>
                          <button onClick={() => increaseQuantity(optionItem)}>
                            +
                          </button>
                        </QuantityControl>
                        <PriceText>
                          <DiscountText>
                            {Number(product.discount).toFixed(1).toString()}
                            {"%"}
                          </DiscountText>
                          {addCommaToNum(
                            parseInt(
                              (1 - parseFloat(product.discount) / 100) *
                                optionItem.count *
                                (parseInt(product.price) + optionItem.price)
                            )
                          )}
                          원
                        </PriceText>
                      </OptionNumberRow>
                    </OptionRow>
                  );
                })}
            </SelectedOptionContainer>
          ) : (
            <SelectedOptionContainer>
              <OptionRow>
                <OptionTitleRow>
                  <OptionTitle>{product.title}</OptionTitle>
                </OptionTitleRow>
                <OptionNumberRow>
                  <QuantityControl>
                    <button
                      onClick={() =>
                        setSeletedCount((prev) => (prev <= 1 ? 1 : prev - 1))
                      }
                    >
                      -
                    </button>
                    <span>{selectedCount}</span>
                    <button onClick={() => setSeletedCount((prev) => prev + 1)}>
                      +
                    </button>
                  </QuantityControl>
                  <PriceText>
                    <DiscountText>
                      {product.discount}
                      {"%"}
                    </DiscountText>
                    {addCommaToNum(
                      parseInt(
                        (1 - parseFloat(product.discount) / 100) *
                          parseInt(product.price)
                      )
                    )}
                    원
                  </PriceText>
                </OptionNumberRow>
              </OptionRow>
            </SelectedOptionContainer>
          )}
          <FinalPriceContainer>
            <FinalPriceDesc>구매 금액</FinalPriceDesc>
            <FinalPriceWrapper>
              {/* {product.discount !== 0 && (
                <FinalDiscountedPrice>
                  {addCommaToNum(discountedPrice)}원 할인
                </FinalDiscountedPrice>
              )} */}
              <FinalPriceText>
                {product.options !== null && product.options.length !== 0
                  ? addCommaToNum(finalPrice)
                  : addCommaToNum(
                      selectedCount *
                        product.price *
                        (1 - parseFloat(product.discount) / 100)
                    )}
                원
              </FinalPriceText>
            </FinalPriceWrapper>
          </FinalPriceContainer>
        </BottomSheetContentsWrapper>
        <BottomSheetButtonRow>
          <BottomSheetBagButton
            active={
              finalPrice !== 0 ||
              product.options === null ||
              product.options.length === 0
            }
            onClick={() => {
              alert("장바구니는 현재 준비중입니다.");
            }}
          >
            장바구니
          </BottomSheetBagButton>
          <BottomSheetPurchaseButton
            active={
              finalPrice !== 0 ||
              product.options === null ||
              product.options.length === 0
            }
            onClick={(e) => onClickPurchaseFinalButton(e)}
          >
            구매하기
          </BottomSheetPurchaseButton>
        </BottomSheetButtonRow>
      </BottomSheetWrapper>
    </BottomSheetContainer>
  );
};

export default BottomSheetDefault;
