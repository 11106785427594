import { palette } from "modules/defines/styles";
import { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

interface SettingButtonProps {
  children: PropsWithChildren<ReactNode>;
  isOn: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const ButtonStyle = styled.button<{ isOn: boolean; disabled?: boolean }>`
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  color: ${(props) => (props.isOn ? palette.white : palette.neutral700)};
  background-color: ${(props) => (props.isOn ? palette.primary500 : palette.neutral200)};
  &:hover {
    color: ${(props) => (props.isOn ? palette.white : palette.neutral700)};
    background-color: ${(props) => (props.isOn ? palette.primary600 : palette.neutral400)};
  }
  &:disabled {
    cursor: default;
    color: ${(props) => (props.isOn ? palette.neutral400 : palette.neutral500)};
    background-color: ${palette.neutral200};
  }
`;

const SettingButton = ({ children, isOn, onClick, disabled }: SettingButtonProps) => {
  return (
    <ButtonStyle onClick={onClick} isOn={isOn} disabled={disabled}>
      {children}
    </ButtonStyle>
  );
};

export default SettingButton;
