import Icon from "assets/icons/Icon";
import styled from "styled-components";
import GlobalContainer from "../GlobalContainer";
import { palette } from "modules/defines/styles";
import {
  ButtonWrapper,
  CagtegoryRadioImg,
  CategoryItem,
  CategoryLabel,
  CategoryWrapper,
  ContentsContainer,
  ContentsLayout,
  ImageContainer,
  ImageWrapper,
  ImgButton,
  PageTitle,
  PageTitleWrapper,
  ProductInfoRow,
  ProductInfoRowLayout,
  ProductInfoTable,
  ProductSection,
  ProductSectionSubtitle,
  ProductSectionTitle,
  TopWrapper,
} from "components/GlobalStyles";
import { icon_radio_off, icon_radio_on } from "assets/icon";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button32r,
  Button34b,
  ButtonTextSolid,
} from "components/common/Button";
import {
  CellButton,
  CellDiv,
  CellDivTitleDesc,
  CellInput,
} from "components/common/TextField";
import { useCallback, useContext, useEffect, useState } from "react";
import { STATUS_TEXT_KOR } from "modules/defines/define";
import { ImageContents } from "components/common/layout/ImageContents";
import parse from "html-react-parser";
import { ImageUploadButton } from "components/common/layout/ImageUploadButton";
import {
  ProductItem,
  ProductItemOption,
  ProductStatus,
} from "modules/defines/interfaces";
import {
  useForm,
  Control,
  FieldValues,
  set,
  useWatch,
  SubmitHandler,
} from "react-hook-form";
import { productAPI } from "api";
import { CellInputField } from "components/common/layout/InputField";
import { ModalContext, ModalContextType } from "modules/context/ModalContext";
import ModalFAVConnect from "components/modal/ModalFAVConnect";
import ModalCancelReason from "components/modal/ModalCancelReason";
import { ProductDescriptionBar } from "components/common/layout/ProductDescriptionBar";
import { PlusIcon16 } from "assets/icons/Plus";
import spinner from "assets/lottie/spinner.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useQueryClient } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import ModalOrderList from "components/modal/ModalOrderList";
import ModalPreviewProduct from "components/modal/ModalPreviewProduct";
import { formatDateToKST } from "utils/util";
import Editor from "components/ui/Editor";
import TextButton from "components/ui/TextButton";
import ToolTip from "components/common/ToolTip";
import ModalManageCount from "components/modal/ModalManageCount";
import ModalCropRepImage from "components/modal/ModalCropRepImage";
import Calender from "components/ui/Calendar";
import { Td } from "components/ui/PaymentTable/PaymentTable";

const ProductDetailContainer: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;
  const PRODUCT_ID = pathName.split("/")[pathName.split("/").length - 1];
  const [cookies, setCookie] = useCookies();
  const queryClient = useQueryClient();

  const getDashboardManagerQuery = queryClient.getQueryData([
    "dashboardManager",
  ]) as {} as any | undefined;

  const ManagerData = getDashboardManagerQuery?.data.data.manager;
  const { openModal, closeModal } = useContext(
    ModalContext
  ) as ModalContextType;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [pageMode, setPageMode] = useState("default"); // 'register': 등록모드 | 'edit': 수정모드 | 'default': 기본모드
  const [isEdited, setIsEdited] = useState(true); // 수정모드일 시 내용이 수정 되면 버튼 활성화  >> FIXME: 일단 무조건 true로 해두었음. 나중에 input이 수정 될 시에만 true로 변경해야 함. (시안 상)

  const [productInfo, setProductInfo] = useState<ProductItem>(); // < product 정보 >

  //    [[  상품 정보  ]]
  const initialStatusValue: ProductStatus = {
    status: -1,
    buttonText: [""],
    buttonType: [""],
  };
  const [currentStatusInfo, setCurrentStatusInfo] =
    useState<ProductStatus>(initialStatusValue); // 현재 상태 관리 (status)
  const [productID, setProductID] = useState(
    PRODUCT_ID.split("-")[1] === "r" ? PRODUCT_ID.split("-")[0] : PRODUCT_ID
  ); // 상품 번호 7자리
  const [currentCategory, setCurrentCategory] = useState("푸드"); // 현재 선택된 카테고리
  // const [discountPrice, setDiscountPrice] = useState(0); // 할인 가격     :  할인 가격과 최종 판매 가격은 둘 중 하나를 입력하여 나머지 하나가 자동 완성될 수 있게 합니다.
  // const [finalPrice, setFinalPrice] = useState(0); // 최종 판매 금액
  const [isInputDiscount, setIsInputDiscount] = useState<boolean>(true); // 할인가격 입력 or 최종 판매가격 입력

  const [isOnlyAdult, setIsOnlyAdult] = useState<boolean>(false); // 19세 이상 제한 상품 여부
  const [willOpen, setWillOpen] = useState<boolean>(false); // 판매 예정 상품 여부
  const [havePeriod, setHavePeriod] = useState<boolean>(false); // 기간 설정 여부
  const [showPeriodCalendar, setShowPeriodCalendar] = useState<boolean>(false); // 기간 설정 캘린더 보이기 여부
  const [haveExpireDate, setHaveExpireDate] = useState<boolean>(false); // 상품 사용 만료일 설정 여부
  const [showExpireCalendar, setShowExpireCalendar] = useState<boolean>(false); // 상품 사용 만료일 캘린더 보이기

  type ValuePiece = Date | null;
  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const today = new Date();
  const [periodDate, setPeriodDate] = useState<Value>([today, today]);

  const [expireDate, setExpireDate] = useState<Value>(today);

  //     [[  옵션 정보  ]]
  const [countManageByOption, setCountManageByOption] = useState(false); // 옵션에 따른 재고 관리 여부
  const [countOptionSum, setCountOptionSum] = useState(0); // 옵션별 재고 합계
  const [productOption, setProductOption] = useState<ProductItemOption[]>([]); // 옵션 수정을 위해 사용 (Option Input)
  const [isHoveringOptAdd, setIsHoveringOptAdd] = useState(false); // 옵션 추가버튼 hover 여부

  //     [[  이미지 및 유의사항  ]]
  // const [imageMainUploadLoading, setImageMainUploadLoading] = useState(false); // 이미지 업로드 로딩
  const [imageDetailUploadLoading, setImageDetailUploadLoading] =
    useState(false);
  const [noticeValue, setNoticeValue] = useState<string>(""); // 유의사항
  const onChangeNoticeValue = (value: string) => {
    setNoticeValue(value);
  };

  const { control, watch, handleSubmit, setValue, reset, getValues } = useForm({
    mode: "all",
  });
  const watchPrice = watch("product-price");
  const watchDiscount = watch("product-discount");
  const watchPriceFinal = watch("product-price-final");

  // Data Initialize
  useEffect(() => {
    // Page Mode Setting [ default | register | edit ]
    if (PRODUCT_ID.split("-")[1] === "r") {
      setPageMode("register");
    } else {
      setPageMode("default");
    }
    productAPI
      .getProductDetail(productID, { mid: ManagerData.id })
      .then((res) => {
        if (res.data.success) {
          setProductInfo(res.data?.product);
          setCurrentStatusInfo(
            STATUS_TEXT_KOR.find(
              (item) => item.status === res.data?.product?.status
            ) || initialStatusValue
          );
          if (res.data?.product?.contents) {
            setNoticeValue(res.data?.product?.contents);
          }

          if (res.data?.product?.period !== null) {
            setHavePeriod(true);
            setPeriodDate([
              new Date(res.data?.product?.period.split("_")[0]),
              new Date(res.data?.product?.period.split("_")[1]),
            ]);
          }
          if (res.data?.product?.expire !== null) {
            setHaveExpireDate(true);
            setExpireDate(new Date(res.data?.product?.expire));
          }
        } else {
          alert(res.data.message);
          // /product 로 이동
          history.push("/product");
        }
      });
  }, []);
  // Rendering 예외처리 ( LOADING )
  useEffect(() => {
    if (productInfo && currentStatusInfo) {
      console.log(productInfo);
      setIsLoading(false);
    }
  }, [productInfo, currentStatusInfo]);

  // 할인 및 최종가격 입력 Logic
  useEffect(() => {
    if (
      isInputDiscount && // 1) 할인률 입력 시
      watchPrice !== undefined &&
      watchDiscount !== undefined &&
      watchPriceFinal !== undefined
    ) {
      if (watchDiscount <= 100 && watchDiscount >= 0) {
        const final_price =
          Math.floor((watchPrice * (100 - watchDiscount)) / 100 / 10) * 10; // 일의자리 버림
        setValue("product-price-final", final_price, { shouldDirty: true });
      }
    } else if (
      !isInputDiscount && // 2) 최종가격 입력 시
      watchPrice !== undefined &&
      watchDiscount !== undefined &&
      watchPriceFinal !== undefined
    ) {
      if (watchPriceFinal <= watchPrice && watchPriceFinal >= 0) {
        const discount = ((watchPrice - watchPriceFinal) / watchPrice) * 100;
        setValue("product-discount", discount, { shouldDirty: true });
      }
    }
  }, [watchPrice, watchDiscount, watchPriceFinal, isInputDiscount, setValue]);

  // INPUT 값 변경 시 체크
  useEffect(() => {
    const subscription = watch((data) => {
      // 재고 입력값에 따라 총 재고 계산
      if (productOption) {
        var sumCount: number = 0;
        productOption.map((item, idx) => {
          sumCount += Number(data[`product-option-count-${idx}`]);
        });
        setCountOptionSum(sumCount);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, productOption]);

  const inputTitle = watch("product-title");
  const inputDescription = watch("product-description");

  // API로 불러온 productInfo로 form 초기화
  useEffect(() => {
    if (productInfo) {
      // 카테고리 수정 반영
      if (productInfo?.category !== undefined) {
        setCurrentCategory(productInfo.category);
      }
      if (productInfo?.options) {
        setProductOption(productInfo?.options); // 옵션 정보
      }
      setCountManageByOption(productInfo?.countByOptions); // 옵션별 재고관리 여부
      setIsOnlyAdult(productInfo?.adultOnly); // 19세 이상 제한 상품 여부
      setWillOpen(productInfo?.willOpen); // 판매 예정 상품 여부
    }
  }, [productInfo]);

  // 총 재고 합 관리를 위함
  useEffect(() => {
    if (productInfo && productOption) {
      var optionCount: number = 0;
      productOption.map((item) => {
        optionCount += Number(item.count);
      });
      setCountOptionSum(optionCount);
    }
  }, [productInfo, countManageByOption]);

  // 카테고리 수정
  const setCurrentCategoryListener = (category: string) => {
    if (pageMode === "default") return;
    else setCurrentCategory(category);
  };

  // 삭제 버튼
  const handleDelete = async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      await productAPI.deleteProduct({ id: productID }).then((res) => {
        if (res.data.success) {
          alert("삭제되었습니다.");
          history.push("/product");
        } else {
          alert("삭제 실패");
        }
      });
    }
  };

  // 상태 변경 버튼
  const handleStatusBtn = async (order: number, status?: number) => {
    if (cookies.isAdmin === false || cookies.isAdmin === undefined) {
      alert("관리자 권한이 필요합니다.");
    } else {
      const statusInfo = STATUS_TEXT_KOR.find((item) => item.status === status);
      if (statusInfo !== undefined) {
        if (window.confirm(statusInfo.confirmAlert[order])) {
          if (ManagerData.bank === null || ManagerData.account === null) {
            // 계좌 등록 안되어있을 때 예외처리
            alert(
              "계좌 등록이 필요합니다.\n우측 상단 '설정' 탭에 들어가 계좌를 등록해주세요."
            );
          } else if (
            productInfo &&
            (productInfo.img === "" || productInfo.img === null)
          ) {
            // 사진 등록 안되어있을 시
            alert("상품 사진 등록이 필요합니다.\n메인 사진을 등록해주세요.");
          } else {
            await productAPI
              .updateStatusProduct({
                id: productInfo?.id,
                status: statusInfo.nextStep[order],
              })
              .then((res) => {
                if (res.data.success) {
                  alert(statusInfo.successAlert[order]);
                  window.location.reload();
                } else {
                  alert(statusInfo.failAlert[order]);
                }
              });
          }
        }
      }
    }
  };

  // 옵션 추가
  const handleOptionAdd = () => {
    if (pageMode === "default") {
      alert("수정모드에서 옵션 추가 가능합니다.");
    } else {
      if (productOption === null) {
        setProductOption([]);
      } else {
        const newOption = productOption.concat({
          title: "",
          price: 0,
          count: 0,
          soldout: false,
        });
        setProductOption(newOption);
      }
    }
  };

  // 옵션 삭제
  const handleOptionDelete = (idx: number) => {
    if (window.confirm("옵션을 삭제하시겠습니까?")) {
      const newOption = productOption.filter((_, index) => index !== idx);
      setProductOption(newOption);
    }
  };

  // 옵션 품절 처리
  const handleOptionSoldout = (idx: number) => {
    const newOption = productOption.map((item, index) => {
      if (index === idx) {
        item.soldout = !item.soldout;
      }
      return item;
    });
    setProductOption(newOption);
  };

  // 이미지 업로드
  const handleImageUpload = async (e: any, type: string) => {
    let typeText = "";
    if (type === "represent") {
      // setImageMainUploadLoading(true);
      typeText = "대표";
    } else if (type === "detail") {
      setImageDetailUploadLoading(true);
      typeText = "세부";
    }
    try {
      if (productInfo !== undefined) {
        if (type === "represent") {
          if (e.target.files[0]) {
            return openModal(
              <ModalCropRepImage
                subject="이미지 자르기"
                description={`대표이미지는 정방형으로 업로드됩니다. 이미지의 중요한 부분을 자르고 저장해주세요.`}
                onClose={() => {
                  closeModal();
                  // setImageMainUploadLoading(false);
                  // window.location.reload();
                }}
                file={e.target.files[0]}
                productId={productInfo.id}
                productIdStr={productID}
                mid={ManagerData.id}
                setProductInfo={setProductInfo}
                // setImageMainUploadLoading={setImageMainUploadLoading}
              />
            );
          } else {
            alert("이미지 업로드 중 오류가 발생했습니다. 다시 시도해주세요.");
          }
        } else if (type === "detail") {
          let postData = new FormData();
          const RandomNumber = (Math.random() * (99 - 1) + 1).toFixed();
          const newName = `${productInfo?.id}_${RandomNumber}`;
          const originalName = e.target.files[0].name;
          const lastDotIndex = originalName.lastIndexOf(".");
          const extension = originalName.substring(lastDotIndex);
          const newNameWithExtension = newName + extension;

          postData.append(
            "img",
            new File([e.target.files[0]], newNameWithExtension)
          );

          let headerData = { id: productInfo.id, type: type };
          await productAPI
            .uploadImage(postData, headerData)
            .then(async (res) => {
              if (res.status === 200) {
                alert(`${typeText} 이미지가 등록되었습니다.`);
              } else {
                alert(
                  "이미지 등록에 실패하였습니다. 해당 현상이 지속될 경우 관리자에게 문의바랍니다."
                );
              }
              await productAPI
                .getProductDetail(productID, { mid: ManagerData.id })
                .then((res) => {
                  setProductInfo(res.data?.product);
                });
            });
        }
      }
    } catch (err) {
      console.error(err);
    }
    setImageDetailUploadLoading(false);
    // setImageMainUploadLoading(false);
  };

  // 수정 버튼
  const handleEditBtn = async () => {
    if (cookies.isAdmin === false || cookies.isAdmin === undefined) {
      alert("관리자 권한이 필요합니다.");
    } else {
      if (productInfo && productInfo.status !== null) {
        // 1, 4, 6 상태일때는 disabled
        if (productInfo.status === 3 || productInfo.status === 5) {
          if (
            window.confirm(
              "수정 시 '작성 중' 상태로 변경되며 승인 재요청이 필요합니다.\n수정하시겠습니까?"
            )
          ) {
            await productAPI
              .updateStatusProduct({
                id: productInfo?.id,
                status: 0,
              })
              .then((res) => {
                if (res.data.success) {
                  setPageMode("edit");
                } else {
                  alert(
                    "시스템 오류가 발생하였습니다. 관리자에게 문의 바랍니다."
                  );
                }
              });
          }
        } else {
          setPageMode("edit");
        }
      }
    }
  };

  // 저장 버튼 ( SUBMIT )
  const onSubmit: SubmitHandler<FieldValues> = async (data: any, e) => {
    if (e) {
      try {
        const submitter = (e.nativeEvent as SubmitEvent)
          .submitter as HTMLButtonElement;
        var isDuplicateTitle = false;
        // 옵션 입력값 반영
        await Promise.all(
          productOption.map((item, idx) => {
            if (data[`product-option-title-${idx}`] !== undefined) {
              item.title = data[`product-option-title-${idx}`];
              const duplicateTitles = productOption.filter((item, index) => {
                return (
                  index !== idx &&
                  item.title === data[`product-option-title-${idx}`]
                );
              });
              isDuplicateTitle = duplicateTitles.length > 0;
            }
            if (data[`product-option-price-${idx}`] !== undefined) {
              item.price = Number(data[`product-option-price-${idx}`]); // FIXME : product-option-price 숫자말고 입력 되지 않도록 수정 (현재 디자인 적용을 위해 type="text"로 설정해두었음)
            }
            if (data[`product-option-count-${idx}`] !== undefined) {
              item.count = Number(data[`product-option-count-${idx}`]); // FIXME : product-option-count 숫자말고 입력 되지 않도록 수정 (현재 디자인 적용을 위해 type="text"로 설정해두었음)
            }
          })
        );
        if (isDuplicateTitle) {
          alert("중복된 옵션명이 있습니다.\n수정 후 다시 시도해주세요.");
          return;
        } else {
          const priceFinal = Math.floor(data["product-price-final"] / 10) * 10; // 일의자리 버림
          var periodDateStr = null;
          if (
            havePeriod &&
            Array.isArray(periodDate) &&
            periodDate[0] !== null &&
            periodDate[1] !== null
          ) {
            periodDateStr = `${formatDateToKST(periodDate[0]).split("T")[0]}_${
              formatDateToKST(periodDate[1]).split("T")[0]
            }`;
          }
          var expireDateStr = null;
          if (
            haveExpireDate &&
            expireDate !== null &&
            !Array.isArray(expireDate)
          ) {
            expireDateStr = formatDateToKST(expireDate).split("T")[0];
          }

          const postData = {
            id: productInfo?.id,
            manager_id: ManagerData.id,
            //
            title: data["product-title"].trim(),
            description: data["product-description"].trim(),
            category: currentCategory,
            price: data["product-price"],
            discount: data["product-discount"],
            priceFinal: priceFinal,
            count: data["product-count"],
            countByOptions: countManageByOption,
            options: productOption,
            contents: noticeValue,
            adultOnly: isOnlyAdult,
            willOpen: willOpen,
            period: periodDateStr,
            expire: expireDateStr,
          };
          if (currentCategory === "전시" && expireDateStr === null) {
            alert("저장 실패 : 전시 상품은 사용 만료일을 설정해주세요.");
          } else {
            await productAPI.editProduct(postData).then(() => {
              if (submitter.value === "save-tmp") {
                alert("임시 저장되었습니다.");
              } else if (submitter.value === "save") {
                alert("저장되었습니다.");
                return (window.location.href = `/product`);
              }
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  // 판매내역 버튼 (임시 비활성화)
  const handleOrderListBtn = () => {
    var productOptionNotExist = true;
    if (productInfo)
      productOptionNotExist =
        productInfo.options === null || productInfo.options.length === 0;
    return openModal(
      <ModalOrderList
        subject="판매 내역"
        description={`해당 상품에 대한 판매 내역입니다. 사용승인 버튼을 통해 구매에 대한 사용 승인을 해주세요.`}
        onClose={() => closeModal()}
        productId={productID}
        productOptionNotExist={productOptionNotExist}
      />
    );
  };

  // 재고관리 버튼
  const manageCountBtnListener = () => {
    if (isLoading) return;
    return openModal(
      <ModalManageCount
        productId={productInfo?.id || 0}
        managerId={ManagerData.id}
        subject="재고 관리"
        description={`해당 상품에 대한 재고 관리입니다. 사용승인 버튼을 통해 구매에 대한 사용 승인을 해주세요.`}
        onClose={() => {
          closeModal();
          window.location.reload();
        }}
        countByOption={productInfo?.countByOptions || false}
        productCount={productInfo?.count || 0}
        options={productInfo?.options || []}
      />
    );
  };

  // 미리보기 버튼
  const handlePreviewProductBtn = () => {
    if (isLoading) return;
    return openModal(
      <ModalPreviewProduct
        onclick={() => closeModal()}
        subject="미리보기"
        description={`해당 상품에 대한 판매 내역입니다. 사용승인 버튼을 통해 구매에 대한 사용 승인을 해주세요.`}
        onClose={() => closeModal()}
        product={productInfo}
        productId={productID}
      />
    );
  };

  // 취소 눌렀을 때 데이터 초기화
  const handleReset = () => {
    setPageMode("default");
    if (productInfo?.category !== undefined)
      setCurrentCategory(productInfo?.category);
    reset({
      "product-title": productInfo?.title || "",
      "product-description": productInfo?.description || "",
      "product-price": productInfo?.price,
      "product-discount": productInfo?.discount,
      "product-count": productInfo?.count,
      "product-price-final": productInfo?.priceFinal,
    });
  };

  return (
    <GlobalContainer>
      <ContentsContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TopWrapper>
            <PageTitle>
              {pageMode === "default" && "상품 상세 정보"}
              {pageMode === "register" && "상품 등록"}
              {pageMode === "edit" && "상품 수정"}
            </PageTitle>
            {pageMode === "default" ? (
              <ButtonWrapper>
                <RelativeLayout>
                  <TextButton
                    disabled={false}
                    onClick={() => manageCountBtnListener()}
                    size="14"
                    styleType="default"
                  >
                    재고관리
                  </TextButton>
                  <ToolTip
                    content={`재고 관리는 수정모드가\n아니어도 가능합니다.`}
                    location={"bottom-right"}
                    width={"140px"}
                    top={"-4px"}
                    right={"-4px"}
                  />
                </RelativeLayout>
                {/* FIXME : 위 버튼과 겹칠 시 '수정' 누르면 바로 submit 버튼 호출되는 요상한 현상 */}
                {/* <TextButton
                  disabled={false}
                  onClick={() => handleOrderListBtn()}
                  size="14"
                  styleType="default"
                >
                  판매내역
                </TextButton> */}

                <Button34b
                  width={"84px"}
                  btype={"primary"}
                  type={"button"}
                  disabled={
                    productInfo?.status === 1 ||
                    productInfo?.status === 4 ||
                    productInfo?.status === 6
                  }
                  onClick={() => handleEditBtn()}
                >
                  수정
                </Button34b>
                <Button34b
                  width={"84px"}
                  btype={"tertiary"}
                  onClick={() => handlePreviewProductBtn()}
                  type={"button"}
                >
                  미리보기
                </Button34b>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <ButtonTextSolid
                  btype="off"
                  // disabled={!isEdited}
                  type={"submit"}
                  value={"save-tmp"}
                >
                  임시 저장
                </ButtonTextSolid>

                <TextButton
                  disabled={false}
                  size="14"
                  styleType="default"
                  onClick={handleReset}
                >
                  취소
                </TextButton>
                <Button34b btype="primary" type={"submit"} value={"save"}>
                  저장
                </Button34b>
                <Button34b
                  btype="tertiary"
                  disabled={!isEdited}
                  type={"button"}
                  onClick={handleDelete}
                >
                  삭제
                </Button34b>
              </ButtonWrapper>
            )}
          </TopWrapper>
          <ContentsLayout marginTop={"12px"}>
            <Layout>
              {/* 상품 정보 */}
              <ProductSection>
                <ProductSectionTitle>
                  {pageMode !== "default" ? "상품 정보 입력" : "상품 정보"}
                </ProductSectionTitle>
                {isLoading ? (
                  <LoadingContainer>
                    <Player
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                      src={spinner}
                      autoplay
                      loop
                    />
                  </LoadingContainer>
                ) : (
                  <ProductInfoTable>
                    {/* 상태 및 상품번호 */}
                    <ProductInfoRowLayout>
                      {pageMode === "default" && (
                        <ProductInfoRow>
                          <CellDiv type={"title"} width={"92px"}>
                            상태
                          </CellDiv>
                          <CellInput
                            dtype={"lock"}
                            width={"100%"}
                            disabled
                            value={
                              STATUS_TEXT_KOR.find(
                                (item) => item.status === productInfo?.status
                              )?.title
                            }
                          />
                          {productInfo?.status === 2 && (
                            <CellButton
                              type={"tertiary"}
                              width={"200px"}
                              onClick={() => {
                                return openModal(
                                  <ModalCancelReason
                                    subject={"승인 취소 사유"}
                                    description={productInfo?.notice}
                                    onClose={() => closeModal()}
                                  />
                                );
                              }}
                            >
                              취소 사유
                            </CellButton>
                          )}
                          {currentStatusInfo?.buttonText.map(
                            (itemStatusButtonText, index) => (
                              <CellButton
                                key={`cell-button-${index}`}
                                type={currentStatusInfo.buttonType[index]}
                                width={"200px"}
                                onClick={() =>
                                  handleStatusBtn(index, productInfo?.status)
                                }
                              >
                                {itemStatusButtonText}
                              </CellButton>
                            )
                          )}
                        </ProductInfoRow>
                      )}
                      <ProductInfoRow>
                        <CellDiv type={"title"} width={"92px"}>
                          상품번호
                        </CellDiv>
                        <CellInput
                          dtype={"lock"}
                          width={"100%"}
                          disabled
                          value={productInfo?.productId}
                        />
                      </ProductInfoRow>
                    </ProductInfoRowLayout>
                    {/* 카테고리 */}
                    <ProductInfoRow>
                      <CellDiv type={"title"} width={"92px"}>
                        카테고리
                      </CellDiv>
                      <CellDiv
                        type={"empty"}
                        width={"100%"}
                        disabled={pageMode === "default"}
                      >
                        <CategoryWrapper>
                          <CategoryItem
                            enableClick={pageMode !== "default"}
                            onClick={() => setCurrentCategoryListener("푸드")}
                          >
                            <CagtegoryRadioImg
                              src={
                                currentCategory === "푸드"
                                  ? icon_radio_on
                                  : icon_radio_off
                              }
                            />
                            <CategoryLabel>푸드</CategoryLabel>
                          </CategoryItem>
                          <CategoryItem
                            enableClick={pageMode !== "default"}
                            onClick={() => setCurrentCategoryListener("굿즈")}
                          >
                            <CagtegoryRadioImg
                              src={
                                currentCategory === "굿즈"
                                  ? icon_radio_on
                                  : icon_radio_off
                              }
                            />
                            <CategoryLabel>굿즈</CategoryLabel>
                          </CategoryItem>
                          <CategoryItem
                            enableClick={pageMode !== "default"}
                            onClick={() => setCurrentCategoryListener("숙박")}
                          >
                            <CagtegoryRadioImg
                              src={
                                currentCategory === "숙박"
                                  ? icon_radio_on
                                  : icon_radio_off
                              }
                            />
                            <CategoryLabel>숙박</CategoryLabel>
                          </CategoryItem>
                          <CategoryItem
                            enableClick={pageMode !== "default"}
                            onClick={() => setCurrentCategoryListener("휴식")}
                          >
                            <CagtegoryRadioImg
                              src={
                                currentCategory === "휴식"
                                  ? icon_radio_on
                                  : icon_radio_off
                              }
                            />
                            <CategoryLabel>휴식</CategoryLabel>
                          </CategoryItem>
                          <CategoryItem
                            enableClick={pageMode !== "default"}
                            onClick={() => setCurrentCategoryListener("전시")}
                          >
                            <CagtegoryRadioImg
                              src={
                                currentCategory === "전시"
                                  ? icon_radio_on
                                  : icon_radio_off
                              }
                            />
                            <CategoryLabel>전시</CategoryLabel>
                          </CategoryItem>
                          <CategoryItem
                            enableClick={pageMode !== "default"}
                            onClick={() => setCurrentCategoryListener("클래스")}
                          >
                            <CagtegoryRadioImg
                              src={
                                currentCategory === "클래스"
                                  ? icon_radio_on
                                  : icon_radio_off
                              }
                            />
                            <CategoryLabel>클래스</CategoryLabel>
                          </CategoryItem>
                          <CategoryItem
                            enableClick={pageMode !== "default"}
                            onClick={() => setCurrentCategoryListener("기타")}
                          >
                            <CagtegoryRadioImg
                              src={
                                currentCategory === "기타"
                                  ? icon_radio_on
                                  : icon_radio_off
                              }
                            />
                            <CategoryLabel>기타</CategoryLabel>
                          </CategoryItem>
                        </CategoryWrapper>
                      </CellDiv>
                    </ProductInfoRow>
                    {/* 상품명 */}
                    <ProductInfoRow>
                      <CellDiv type={"title"} width={"92px"}>
                        상품명
                        <CellDivTitleDesc>
                          {inputTitle && inputTitle !== null
                            ? inputTitle.length
                            : 0}
                          /60
                        </CellDivTitleDesc>
                      </CellDiv>

                      <CellInputField
                        width={"100%"}
                        placeholder="상품명을 입력하세요"
                        dtype={pageMode === "default" ? "lock" : "text"}
                        type={"text"}
                        disabled={pageMode === "default"}
                        defaultValue={productInfo?.title || ""}
                        name={"product-title"}
                        control={control}
                      />
                    </ProductInfoRow>
                    {/* 상품 설명 */}
                    <ProductInfoRow>
                      <CellDiv type={"title"} width={"92px"}>
                        상품 설명
                        <CellDivTitleDesc>
                          {inputDescription && inputDescription !== null
                            ? inputDescription.length
                            : 0}
                          /80
                        </CellDivTitleDesc>
                      </CellDiv>
                      <CellInputField
                        width={"100%"}
                        placeholder="상품에 대한 설명 입력(80자 제한)"
                        dtype={pageMode === "default" ? "lock" : "text"}
                        type={"text"}
                        disabled={pageMode === "default"}
                        defaultValue={productInfo?.description || ""}
                        name={"product-description"}
                        control={control}
                      />
                    </ProductInfoRow>
                    {/* 가격 할인 최종금액 재고 */}
                    <ProductInfoRowLayout>
                      <ProductInfoRow>
                        <CellDiv type={"title"} width={"92px"}>
                          가격
                        </CellDiv>
                        <CellInputField
                          width={"100%"}
                          placeholder="0"
                          type={"number"}
                          dtype={pageMode === "default" ? "lock" : "text"}
                          disabled={pageMode === "default"}
                          defaultValue={Number(productInfo?.price) || 0}
                          name={"product-price"}
                          control={control}
                          textAlign={"right"}
                          unit={"원"}
                        />
                      </ProductInfoRow>
                      <ProductInfoRow>
                        <CellDiv
                          type={"title"}
                          width={"92px"}
                          flexDirection={"row"}
                        >
                          <ToolTip
                            content={`할인율 혹은 최종 판매할 가격을 선택하여 입력할 수 있습니다.\n\n스토어에서 할인율은 소수점 첫째자리 까지만 반올림되어 표기됩니다.`}
                            location={"bottom-right"}
                            width={"180px"}
                            top={"4px"}
                            right={"8px"}
                          />
                          {pageMode !== "default" && (
                            <CagtegoryRadioImg
                              src={
                                isInputDiscount ? icon_radio_on : icon_radio_off
                              }
                              onClick={() => setIsInputDiscount(true)}
                            />
                          )}
                          할인
                        </CellDiv>
                        <CellInputField
                          width={"100%"}
                          placeholder="0"
                          type={"number"}
                          dtype={
                            pageMode === "default"
                              ? "lock"
                              : isInputDiscount
                              ? "text"
                              : "lock"
                          }
                          disabled={pageMode === "default"}
                          readOnly={!isInputDiscount}
                          defaultValue={Number(productInfo?.discount) || 0}
                          name={"product-discount"}
                          control={control}
                          textAlign={"right"}
                          unit={"%"}
                        />
                      </ProductInfoRow>
                      <ProductInfoRow>
                        <CellDiv
                          type={"title"}
                          width={"92px"}
                          flexDirection="row"
                        >
                          <ToolTip
                            content={`할인율이 적용된 스토어 최종 판매가입니다.\n\n판매가는 일의자리에서 반올림하여 적용됩니다.`}
                            location={"bottom-right"}
                            width={"240px"}
                            top={"4px"}
                            right={"8px"}
                          />
                          {pageMode !== "default" && (
                            <CagtegoryRadioImg
                              src={
                                isInputDiscount ? icon_radio_off : icon_radio_on
                              }
                              onClick={() => setIsInputDiscount(false)}
                            />
                          )}
                          판매가
                        </CellDiv>
                        <CellInputField
                          dtype={
                            pageMode === "default"
                              ? "lock"
                              : isInputDiscount
                              ? "lock"
                              : "text"
                          }
                          width={"100%"}
                          placeholder="0"
                          disabled={pageMode === "default"}
                          readOnly={isInputDiscount}
                          defaultValue={Number(productInfo?.priceFinal) || 0}
                          name={"product-price-final"}
                          control={control}
                          textAlign={"right"}
                          unit={"원"}
                          currentPrice={watchPrice}
                        />
                      </ProductInfoRow>
                      <ProductInfoRow>
                        <CellDiv type={"title"} width={"92px"}>
                          재고
                        </CellDiv>
                        <CellInputField
                          width={"100%"}
                          placeholder="0"
                          dtype={
                            pageMode === "default" || countManageByOption
                              ? "lock"
                              : "text"
                          }
                          disabled={
                            pageMode === "default" || countManageByOption
                          }
                          defaultValue={
                            countManageByOption
                              ? countOptionSum
                              : productInfo?.count || 0
                          }
                          name={"product-count"}
                          control={control}
                          textAlign={"right"}
                          unit={"개"}
                        />
                      </ProductInfoRow>
                    </ProductInfoRowLayout>
                    {/* 등록일 & 수정일 */}
                    <ProductInfoRowLayout>
                      <ProductInfoRow>
                        <CellDiv type={"title"} width={"92px"}>
                          등록일
                        </CellDiv>
                        {productInfo && (
                          <CellInputField
                            width={"100%"}
                            placeholder="-"
                            type={"text"}
                            dtype={"lock"}
                            disabled
                            defaultValue={
                              formatDateToKST(new Date(productInfo.createdAt))
                                .split("+")[0]
                                .substring(2)
                                .replace("T", " ")
                                .replace(/-/g, "/") || "-"
                            }
                            name={"product-created-at"}
                            textAlign={"right"}
                            control={control}
                          />
                        )}
                      </ProductInfoRow>
                      <ProductInfoRow>
                        <CellDiv type={"title"} width={"92px"}>
                          수정일
                        </CellDiv>
                        {productInfo && (
                          <CellInputField
                            width={"100%"}
                            placeholder="-"
                            type={"text"}
                            dtype={"lock"}
                            disabled
                            defaultValue={
                              formatDateToKST(new Date(productInfo.updatedAt))
                                .split("+")[0]
                                .substring(2)
                                .replace("T", " ")
                                .replace(/-/g, "/") || "-"
                            }
                            name={"product-updated-at"}
                            textAlign={"right"}
                            control={control}
                          />
                        )}
                      </ProductInfoRow>
                    </ProductInfoRowLayout>
                    {/* 기타 정보 */}
                    <ProductInfoRowLayout>
                      <ProductInfoRow height={"50px"}>
                        <CellDiv type={"title"} width={"92px"}>
                          기타 정보
                        </CellDiv>
                        {productInfo && (
                          <CellDiv
                            type={"empty"}
                            disabled={pageMode === "default"}
                          >
                            <ItemRow>
                              {/* 미성년자 제한 상품 */}
                              <CategoryItem
                                enableClick={pageMode !== "default"}
                                onClick={() => {
                                  if (pageMode === "default")
                                    alert("수정모드에서만 변경 가능합니다.");
                                  else setIsOnlyAdult(!isOnlyAdult);
                                }}
                                style={{ marginLeft: "12px" }}
                              >
                                <CagtegoryRadioImg
                                  src={
                                    isOnlyAdult ? icon_radio_on : icon_radio_off
                                  }
                                />
                                <CategoryLabel>
                                  미성년자 제한 상품
                                </CategoryLabel>
                              </CategoryItem>
                              {/* 판매 예정 상품 */}
                              <CategoryItem
                                enableClick={pageMode !== "default"}
                                onClick={() => {
                                  if (pageMode === "default")
                                    alert("수정모드에서만 변경 가능합니다.");
                                  else setWillOpen(!willOpen);
                                }}
                                style={{ marginLeft: "24px" }}
                              >
                                <CagtegoryRadioImg
                                  src={
                                    willOpen ? icon_radio_on : icon_radio_off
                                  }
                                />
                                <CategoryLabel>
                                  판매 예정 상품
                                  <ToolTip
                                    content={`판매 예정 상품은 스토어에 노출되나 구매할 수 없습니다.`}
                                    location={"bottom-right"}
                                    width={"180px"}
                                    top={"-10px"}
                                    right={"-16px"}
                                  />
                                </CategoryLabel>
                              </CategoryItem>
                              {/* 판매기간 지정 */}
                              <CategoryItem
                                enableClick={pageMode !== "default"}
                                style={{ marginLeft: "24px" }}
                              >
                                <CagtegoryRadioImg
                                  onClick={() => {
                                    if (pageMode === "default")
                                      alert("수정모드에서만 변경 가능합니다.");
                                    else setHavePeriod(!havePeriod);
                                  }}
                                  src={
                                    havePeriod ? icon_radio_on : icon_radio_off
                                  }
                                />
                                <CategoryLabel
                                  onClick={() => {
                                    if (pageMode === "default")
                                      alert("수정모드에서만 변경 가능합니다.");
                                    else setHavePeriod(!havePeriod);
                                  }}
                                >
                                  판매기간 지정
                                  <ToolTip
                                    content={`판매 기간을 지정하여 표기할 수 있습니다.\n시작 날짜와 종료 날짜를\n우측 달력을 통해 입력해주세요.`}
                                    location={"bottom-right"}
                                    width={"220px"}
                                    top={"-10px"}
                                    right={"-16px"}
                                  />
                                </CategoryLabel>
                                {havePeriod && (
                                  <PeriodSettingWrapper>
                                    <CalenderWrapper
                                      onClick={() =>
                                        pageMode !== "default" &&
                                        setShowPeriodCalendar((prev) => !prev)
                                      }
                                    >
                                      {Array.isArray(periodDate) ? (
                                        periodDate[0]
                                          ?.toLocaleDateString("ko", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            formatMatcher: undefined,
                                          })
                                          .split(".") // 2024.05.02 -> ["2024", "05", "02"]
                                          .map((periodDate) =>
                                            periodDate.trim()
                                          )
                                          .join("-")
                                          .slice(0, -1)
                                      ) : (
                                        <span>날짜 선택</span>
                                      )}
                                    </CalenderWrapper>
                                    <PeriodText>~</PeriodText>
                                    <CalenderWrapper
                                      onClick={() =>
                                        pageMode !== "default" &&
                                        setShowPeriodCalendar((prev) => !prev)
                                      }
                                    >
                                      {Array.isArray(periodDate) ? (
                                        periodDate[1]
                                          ?.toLocaleDateString("ko", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            formatMatcher: undefined,
                                          })
                                          .split(".") // 2024.05.02 -> ["2024", "05", "02"]
                                          .map((periodDate) =>
                                            periodDate.trim()
                                          )
                                          .join("-")
                                          .slice(0, -1)
                                      ) : (
                                        <span>날짜 선택</span>
                                      )}
                                    </CalenderWrapper>
                                    <IconWrapper
                                      onClick={() =>
                                        pageMode !== "default" &&
                                        setShowPeriodCalendar((prev) => !prev)
                                      }
                                    >
                                      <Icon
                                        icon="calendar"
                                        fill={palette.neutral600}
                                      />
                                    </IconWrapper>
                                    <ParentPositionLayout>
                                      {showPeriodCalendar && (
                                        <Calender
                                          date={periodDate}
                                          setDate={setPeriodDate}
                                          setShowCalender={
                                            setShowPeriodCalendar
                                          }
                                          type="range"
                                        />
                                      )}
                                    </ParentPositionLayout>
                                  </PeriodSettingWrapper>
                                )}
                              </CategoryItem>
                              {/* 사용 유효기간 설정 (전시의 경우에만) */}
                              {currentCategory === "전시" && (
                                <CategoryItem
                                  enableClick={pageMode !== "default"}
                                  style={{ marginLeft: "24px" }}
                                >
                                  <CagtegoryRadioImg
                                    onClick={() => {
                                      if (pageMode === "default")
                                        alert(
                                          "수정모드에서만 변경 가능합니다."
                                        );
                                      else setHaveExpireDate(!haveExpireDate);
                                    }}
                                    src={
                                      haveExpireDate
                                        ? icon_radio_on
                                        : icon_radio_off
                                    }
                                  />
                                  <CategoryLabel
                                    onClick={() => {
                                      if (pageMode === "default")
                                        alert(
                                          "수정모드에서만 변경 가능합니다."
                                        );
                                      else setHaveExpireDate(!haveExpireDate);
                                    }}
                                  >
                                    사용 만료일 지정
                                    <ToolTip
                                      content={`사용 만료 기간을 지정할 수 있습니다.\n사용자는 해당 날짜 까지만 상품을 이용할 수 있습니다.\n\n판매 기간과는 별개로 해당 기간이 지나면 구매한 상품의 사용이 불가합니다.`}
                                      location={"bottom-right"}
                                      width={"220px"}
                                      top={"-10px"}
                                      right={"-16px"}
                                    />
                                  </CategoryLabel>
                                  {haveExpireDate && (
                                    <PeriodSettingWrapper>
                                      <CalenderWrapper
                                        onClick={() =>
                                          pageMode !== "default" &&
                                          setShowExpireCalendar((prev) => !prev)
                                        }
                                      >
                                        {!Array.isArray(expireDate) ? (
                                          expireDate
                                            ?.toLocaleDateString("ko", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              formatMatcher: undefined,
                                            })
                                            .split(".") // 2024.05.02 -> ["2024", "05", "02"]
                                            .map((expireDate) =>
                                              expireDate.trim()
                                            )
                                            .join("-")
                                            .slice(0, -1)
                                        ) : (
                                          <span>날짜 선택</span>
                                        )}
                                      </CalenderWrapper>
                                      <IconWrapper
                                        onClick={() =>
                                          pageMode !== "default" &&
                                          setShowExpireCalendar((prev) => !prev)
                                        }
                                      >
                                        <Icon
                                          icon="calendar"
                                          fill={palette.neutral600}
                                        />
                                      </IconWrapper>
                                      <ParentPositionLayout>
                                        {showExpireCalendar && (
                                          <Calender
                                            date={expireDate}
                                            setDate={setExpireDate}
                                            setShowCalender={
                                              setShowExpireCalendar
                                            }
                                            type="single"
                                          />
                                        )}
                                      </ParentPositionLayout>
                                    </PeriodSettingWrapper>
                                  )}
                                </CategoryItem>
                              )}
                            </ItemRow>
                          </CellDiv>
                        )}
                      </ProductInfoRow>
                    </ProductInfoRowLayout>
                  </ProductInfoTable>
                )}
              </ProductSection>
              {/* 옵션 선택 */}
              <ProductSection>
                <ProductSectionTitle>옵션 선택</ProductSectionTitle>
                <ProductDescriptionBar
                  marginTop="12px"
                  text={
                    "상품에 따른 선택사항입니다. '옵션 가격'은 기본 가격에 추가 혹은 차감 됩니다."
                  }
                />
                {isLoading ? (
                  <LoadingContainer>
                    <Player
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                      src={spinner}
                      autoplay
                      loop
                    />
                  </LoadingContainer>
                ) : (
                  <CellDivSectionTitle>
                    <>기본 옵션</>{" "}
                    <CategoryItem
                      enableClick={pageMode !== "default"}
                      onClick={() => {
                        if (pageMode === "default")
                          alert("수정모드에서만 변경 가능합니다.");
                        else if (
                          productOption === null ||
                          productOption.length === 0
                        )
                          alert("먼저 옵션을 추가해주세요");
                        else setCountManageByOption(!countManageByOption);
                      }}
                      style={{ marginLeft: "36px" }}
                    >
                      <CagtegoryRadioImg
                        src={
                          countManageByOption ? icon_radio_on : icon_radio_off
                        }
                      />
                      <CategoryLabel>
                        옵션별 재고관리
                        <ToolTip
                          content={`옵션별로 따로 재고를 관리할 수 있습니다.`}
                          location={"bottom-right"}
                          width={"140px"}
                          top={"-11px"}
                          right={"-14px"}
                        />
                      </CategoryLabel>
                    </CategoryItem>
                  </CellDivSectionTitle>
                )}

                {productOption &&
                  productOption.map((optionItem, idx) => {
                    return (
                      <ProductInfoRowLayout key={`option-row-${idx}`}>
                        <ProductInfoRow>
                          <CellDiv type={"title"} width={"92px"}>
                            옵션
                          </CellDiv>
                          <CellInputField
                            width={"100%"}
                            placeholder="옵션 내용을 입력하세요"
                            dtype={pageMode === "default" ? "lock" : "text"}
                            type={"text"}
                            disabled={pageMode === "default"}
                            defaultValue={optionItem.title}
                            name={`product-option-title-${idx}`}
                            control={control}
                          />
                        </ProductInfoRow>
                        <ProductInfoRow>
                          <CellDiv type="title" width="92px">
                            {idx === 0 && (
                              <ToolTip
                                content={`위에서 설정한 가격에서\n추가하거나 차감할 금액을 입력해주세요.`}
                                location={"bottom-right"}
                                width={"220px"}
                                top={"4px"}
                                right={"8px"}
                              />
                            )}
                            옵션 가격
                          </CellDiv>
                          <CellInputField
                            width={"100%"}
                            placeholder="0"
                            dtype={pageMode === "default" ? "lock" : "text"}
                            disabled={pageMode === "default"}
                            defaultValue={optionItem.price}
                            name={`product-option-price-${idx}`}
                            control={control}
                            textAlign={"right"}
                            type={"number"}
                            unit={"원"}
                          />
                        </ProductInfoRow>
                        {countManageByOption && (
                          <ProductInfoRow>
                            <CellDiv type="title" width="92px">
                              재고
                            </CellDiv>
                            {pageMode === "default" &&
                            (optionItem.soldout || optionItem.count === 0) ? (
                              <CellButton
                                type={"dangerVariant"}
                                width={"100%"}
                                disabled={true}
                              >
                                {"품 절"}
                              </CellButton>
                            ) : (
                              <CellInputField
                                width={"100%"}
                                placeholder="0"
                                dtype={pageMode === "default" ? "lock" : "text"}
                                disabled={pageMode === "default"}
                                defaultValue={optionItem.count}
                                name={`product-option-count-${idx}`}
                                control={control}
                                textAlign={"right"}
                                unit={"개"}
                              />
                            )}
                          </ProductInfoRow>
                        )}
                        {pageMode !== "default" && (
                          <>
                            <CellButton
                              key={`product-option-delete-${idx}`}
                              type={"variant"}
                              width={"200px"}
                              onClick={() =>
                                pageMode !== "default"
                                  ? handleOptionDelete(idx)
                                  : null
                              }
                              disabled={pageMode === "default"}
                            >
                              옵션 삭제
                            </CellButton>
                            <CellButton
                              key={`product-option-soldout-${idx}`}
                              type={optionItem.soldout ? "danger" : "tertiary"}
                              width={"200px"}
                              onClick={() =>
                                pageMode !== "default"
                                  ? handleOptionSoldout(idx)
                                  : null
                              }
                              disabled={pageMode === "default"}
                            >
                              {optionItem.soldout ? "품절 해제" : "옵션 품절"}
                            </CellButton>
                          </>
                        )}
                      </ProductInfoRowLayout>
                    );
                  })}
                <OptionAddButtonWrapper
                  onMouseEnter={() => {
                    if (pageMode !== "default") setIsHoveringOptAdd(true);
                  }}
                  onMouseLeave={() => {
                    if (pageMode !== "default") setIsHoveringOptAdd(false);
                  }}
                  pageMode={pageMode}
                  onClick={handleOptionAdd}
                >
                  <PlusIcon16
                    color={
                      isHoveringOptAdd ? palette.primary600 : palette.neutral400
                    }
                  />
                  <OptionAddButton
                    color={
                      isHoveringOptAdd ? palette.primary600 : palette.neutral400
                    }
                  >
                    옵션 내용 추가
                  </OptionAddButton>
                </OptionAddButtonWrapper>
              </ProductSection>
              {/* 이미지 등록 */}
              <ProductSection>
                <ProductSectionTitle>이미지 등록</ProductSectionTitle>
                <ProductDescriptionBar
                  marginTop="12px"
                  text={
                    "10mb이하의 jpg, png 파일만 등록할 수 있습니다. 이미지 등록 시 페이지가 새로고침되므로 작성 중인 내용을 저장해주세요."
                  }
                />
                <ImageContainer marginTop="12px">
                  <ProductSectionSubtitle>
                    메인 사진
                    <ToolTip
                      content={`메인 사진은 한 장만 등록 가능하며, 메인사진 뒤에는 공간 사진이 자동 등록됩니다.`}
                      location={"bottom-right"}
                      width={"190px"}
                      top={"-10px"}
                      right={"-14px"}
                    />
                  </ProductSectionSubtitle>
                  <ImageWrapper>
                    {productInfo !== undefined && productInfo.img && (
                      <ImageContents
                        img={productInfo.img ? productInfo.img : ""}
                        clickDisabled={pageMode === "default"}
                        type="represent"
                        idx={0}
                        productId={productInfo.id}
                        productNumber={productInfo.productId}
                        setProductInfo={setProductInfo}
                        mid={ManagerData.id}
                      />
                    )}
                    {pageMode !== "default" && (
                      <>
                        <label htmlFor="upload-img-represent">
                          <ImageUploadButton text="메인 사진" />
                        </label>
                        {!imageDetailUploadLoading && (
                          <ImageUploadInput
                            type="file"
                            id="upload-img-represent"
                            multiple={false}
                            onChange={(e: any) =>
                              handleImageUpload(e, "represent")
                            }
                            onClick={(e: any) => {
                              e.target.value = null;
                            }}
                          />
                        )}
                      </>
                    )}
                    {/* {imageMainUploadLoading && (
                      <Player
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                        src={spinner}
                        autoplay
                        loop
                      />
                    )} */}
                  </ImageWrapper>
                </ImageContainer>
                <ImageContainer marginTop="20px">
                  <ProductSectionSubtitle>
                    상세 정보
                    <ToolTip
                      content={`상세 정보는 상품에 대한 설명을 위한 이미지로, 세로로 긴 이미지가 필요합니다.`}
                      location={"bottom-right"}
                      width={"240px"}
                      top={"-10px"}
                      right={"-14px"}
                    />
                  </ProductSectionSubtitle>
                  <ImageWrapper>
                    {productInfo !== undefined &&
                      productInfo.details &&
                      productInfo.details.split("///").map((detail, idx) => {
                        return (
                          <ImageContents
                            img={detail}
                            clickDisabled={pageMode === "default"}
                            type="detail"
                            idx={idx}
                            productId={productInfo.id}
                            productNumber={productInfo.productId}
                            setProductInfo={setProductInfo}
                            mid={ManagerData.id}
                          />
                        );
                      })}
                    {pageMode !== "default" && (
                      <>
                        <label htmlFor="upload-img-detail">
                          <ImageUploadButton text="상세 이미지" />
                        </label>
                        {!imageDetailUploadLoading && (
                          <ImageUploadInput
                            type="file"
                            id="upload-img-detail"
                            multiple={false}
                            onChange={(e: any) =>
                              handleImageUpload(e, "detail")
                            }
                            onClick={(e: any) => {
                              e.target.value = null;
                            }}
                          />
                        )}
                      </>
                    )}
                    {imageDetailUploadLoading && (
                      <Player
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                        src={spinner}
                        autoplay
                        loop
                      />
                    )}
                  </ImageWrapper>
                </ImageContainer>
              </ProductSection>
              {/* 유의사항 작성 */}
              <ProductSection>
                <ProductSectionTitle>유의사항 작성</ProductSectionTitle>

                {pageMode === "default" ? (
                  <EditorContainer>
                    <NoticeContainer>{parse(noticeValue)}</NoticeContainer>
                  </EditorContainer>
                ) : (
                  <EditorContainer>
                    <Editor
                      text={noticeValue}
                      textOnChange={onChangeNoticeValue}
                    />
                    {noticeValue === "" && (
                      <EditorPlaceholder>
                        상품 정보, 주의사항, 환불정보 등 상품에 대한 유의사항을
                        입력해주세요.
                        <br />
                        <br />
                        ex{")"}
                        <br />
                        [전시 정보] <br />
                        전시 기간 : 24년 6월 1일~24년 6월 30일 (주말 휴무){" "}
                        <br />
                        전시 시간 : 10:00~19:00(입장마감 18:00)
                        <br />
                        *36개월 미만 보호자 동반 입장 시 무료입장 가능
                        <br />
                        <br />
                        [취소 및 환불]
                        <br />
                        해당 상품은 전시 기한 내 언제든 사용 가능한 상품으로,
                        전시 기한 내에는 100% 환불 가능하나, 그 이후는 환불이
                        불가합니다.
                      </EditorPlaceholder>
                    )}
                  </EditorContainer>
                )}
              </ProductSection>
            </Layout>
          </ContentsLayout>
        </form>
      </ContentsContainer>
    </GlobalContainer>
  );
};

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

// 피그마에 컴포넌트 정리가 안되어있어 일단 별개로 개발해두었습니다.
const CellDivSectionTitle = styled.div`
  border: 1px solid ${palette.neutral300};
  //font
  color: ${palette.neutral800};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-start;
  padding-left: 20px;

  /* TitleText */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;

const OptionAddButtonWrapper = styled.div<{ pageMode: string }>`
  margin-top: 6px;
  padding: 6px 12px 6px 8px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: ${(props) => (props.pageMode === "default" ? "default" : "pointer")};
`;

// 피그마 정리 후 컴포넌트화
const OptionAddButton = styled.div`
  color: ${(props) => (props.color ? props.color : palette.neutral400)};
  font-feature-settings: "clig" off, "liga" off;

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const ImageUploadInput = styled.input`
  display: none;
  cursor: pointer;
`;

const EditorContainer = styled.div`
  width: 100%;
  height: 320px;
  margin-bottom: 80px;
  margin-top: 20px;
  position: relative;
`;

const EditorPlaceholder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 52px;
  left: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 13px;
  color: ${palette.neutral400};
  font-family: Pretendard;
  font-weight: 400;
  line-height: 20px;

  pointer-events: none;
`;

const NoticeContainer = styled.div`
  width: 100%;
  height: 320px;
  margin-bottom: 80px;
  margin-top: 20px;
  padding: 16px;
  border: 1px solid ${palette.neutral300};
  border-radius: 8px;

  font-size: 13px;
  color: ${palette.neutral700};
  font-family: Pretendard;
  font-weight: 400;
  line-height: 20px;
  p {
    font-size: 13px;
  }
  strong {
    font-weight: 700;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const RelativeLayout = styled.div`
  position: relative;
  display: flex;
`;

const PeriodSettingWrapper = styled.div`
  margin-left: 24px;
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const PeriodText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${palette.neutral600};
  margin: 0px 4px;
`;

const IconWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
  margin-left: 8px;
`;

const CalenderWrapper = styled.div`
  text-align: center;
  border: 1px solid ${palette.neutral300};
  padding: 2px 12px;
  border-radius: 4px;
  background-color: ${palette.white100};
  margin: 0px;
  cursor: pointer;
`;

const ParentPositionLayout = styled.div`
  position: relative;
`;
export default ProductDetailContainer;
