import styled from "styled-components";
import { ModalStandardProps } from "modules/defines/interfaces";
import { motion } from "framer-motion";
import { palette } from "modules/defines/styles";
import { ButtonSolid48, ButtonTextSolid } from "components/common/Button";

const ModalCancelReason = ({ ...props }: ModalStandardProps) => {
  const { subject, description, onSuccess, onClose } = props;
  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <TitleWrapper>
        <Title>{subject}</Title>
        <Description>
          본 상품은 아래와 같은 이유로 승인 취소 되었습니다. 내용을 반영하여
          상품 정보를 수정하시고, 승인 재신청을 진행해 주세요.
        </Description>
      </TitleWrapper>
      <InputText value={description} readOnly></InputText>
      <ButtonWrapper>
        <ButtonSolid48 btype="primary" onClick={onClose} width="100%">
          확인
        </ButtonSolid48>
      </ButtonWrapper>
    </ModalWrapper>
  );
};
const ModalWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 420px;
  padding: 12px 4px;
  margin: 20px;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.div`
  color: ${palette.neutral1000};
  font-feature-settings: "clig" off, "liga" off;

  /* TitleContents */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;
const Description = styled.div`
  color: ${palette.neutral600};
  font-feature-settings: "clig" off, "liga" off;

  /* body14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const InputText = styled.textarea`
  //   all: unset;
  color: ${palette.neutral1000};
  //   font-feature-settings: "clig" off, "liga" off;

  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${palette.neutral200};
  margin-top: 12px;

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  height: 134px;
`;
const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;
`;
export default ModalCancelReason;
