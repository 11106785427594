import { Player } from "@lottiefiles/react-lottie-player";
import spinner_blue from "assets/lottie/spinner_blue.json";
import { subtitle14 } from "components/style/subtitle";
import { titlePage } from "components/style/title";
import { palette } from "modules/defines/styles";
import { useEffect } from "react";
import styled from "styled-components";

const LoadingModal = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.pointerEvents = "none";

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.pointerEvents = "auto";
    };
  }, []);
  return (
    <LoadingModalBackground>
      <LoadingModalContent>
        <Player
          style={{
            width: "32x",
            height: "32px",
          }}
          src={spinner_blue}
          autoplay
          loop
        />
        <LoadingModalMessage>
          <MessageTitle> 데이터를 불러오고 있습니다</MessageTitle>
          <MessageSubTitle>잠시만 기다려주세요.</MessageSubTitle>
        </LoadingModalMessage>
      </LoadingModalContent>
    </LoadingModalBackground>
  );
};

const LoadingModalBackground = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const LoadingModalContent = styled.div`
  position: fixed;

  top: 32%;
  left: calc(50% - 120px);
  /* transform: translate(-50%, -60%); */
  width: fit-content;
  padding: 38px 61.5px;
  border-radius: 12px;
  background-color: ${palette.opacityB60};
  display: grid;
  grid-template-rows: fit-content(100%);
  row-gap: 30px;
  justify-items: center;
`;

const LoadingModalMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const MessageTitle = styled.div`
  ${titlePage};
  color: white;
`;
const MessageSubTitle = styled.div`
  ${subtitle14};
  color: white;
`;

export default LoadingModal;
