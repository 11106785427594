import styled, { css } from "styled-components";

export const TextButton = css`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
`;

export const Button = css`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px; /* 117.647% */
`;
