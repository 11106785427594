import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  enabled: boolean;
  size: string;
}

const DashboardIcon: React.FC<IconProps> = ({ enabled, size = 0 }) => {
  const viewBox = `0 0 ${size} ${size}`;
  const color = enabled ? palette.white100 : palette.neutral700;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="dashboard">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 4C3 3.44772 3.44772 3 4 3H10C10.5523 3 11 3.44772 11 4V12C11 12.5523 10.5523 13 10 13H4C3.44772 13 3 12.5523 3 12V4ZM5 5V11H9V5H5ZM13 4C13 3.44772 13.4477 3 14 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 20.5523 9 20 9H14C13.4477 9 13 8.55228 13 8V4ZM15 5V7H19V5H15ZM13 12C13 11.4477 13.4477 11 14 11H20C20.5523 11 21 11.4477 21 12V20C21 20.5523 20.5523 21 20 21H14C13.4477 21 13 20.5523 13 20V12ZM15 13V19H19V13H15ZM3 16C3 15.4477 3.44772 15 4 15H10C10.5523 15 11 15.4477 11 16V20C11 20.5523 10.5523 21 10 21H4C3.44772 21 3 20.5523 3 20V16ZM5 17V19H9V17H5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default DashboardIcon;
