import { palette } from "modules/defines/styles";
import styled, { css } from "styled-components";

// Cell
export const CellDiv = styled.div<{
  type: string;
  width?: string;
  disabled?: boolean;
  flexDirection?: string;
}>`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  ${(props) =>
    props.flexDirection === "column" &&
    css`
      flex-direction: column;
      gap: 4px;
    `}
  ${(props) =>
    props.flexDirection === "row" &&
    css`
      flex-direction: row;
      gap: 4px;
    `}

  padding: 10px 8px;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* TitleText */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  flex-shrink: 0;

  width: ${(props) => (props.width ? props.width : "100%")};
  position: relative;

  ${(props) =>
    props.type === "title" &&
    css`
      font-weight: 700;
      color: ${palette.neutral800};
      background-color: ${palette.neutral200};
      border: 1px solid ${palette.neutral300};
    `}
  ${(props) =>
    props.type === "empty" &&
    css`
      background-color: ${palette.white100};
      border: 1px solid ${palette.neutral300};
      flex-shrink: 1;
      align-items: flex-start;
    `}
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${palette.neutral100};
    `}
`;

export const CellDivTitleDesc = styled.div`
  color: ${palette.neutral800};
  font-feature-settings: "clig" off, "liga" off;

  /* caption02 */
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: -0.2px;
`;

export const CellInput = styled.input<{
  dtype?: string;
  width?: string;
  textAlign?: string;
}>`
  all: unset;

  border: 1px solid ${palette.neutral300};

  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  padding-left: 8px;
  ${(props) =>
    props.textAlign === "right" &&
    css`
      padding-right: 40px;
    `}
  font-feature-settings: "clig" off, "liga" off;

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  width: ${(props) => (props.width ? props.width : "100px")};

  ${(props) =>
    props.dtype === "lock" &&
    css`
      color: ${palette.neutral500};
      background-color: ${palette.neutral100};
    `}

  ${(props) =>
    props.dtype === "text" &&
    css`
      color: ${palette.neutral800};
      background-color: ${palette.white100};
      ::placeholder {
        color: ${palette.neutral400};
      }
    `}
`;

// Button
export const CellButton = styled.div<{
  type: string;
  disabled?: boolean;
  width?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;

  color: ${palette.white100};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* TitleText */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */

  cursor: pointer;

  width: ${(props) => (props.width ? props.width : "100%")};
  height: 100%;

  ${(props) =>
    props.type === "primary" &&
    css`
      background-color: ${palette.primary500};
      &:hover {
        background-color: ${palette.primary600};
      }
    `}
  ${(props) =>
    props.type === "variant" &&
    css`
      background-color: ${palette.primary700};
      &:hover {
        background-color: ${palette.primary800};
      }
    `}
  ${(props) =>
    props.type === "danger" &&
    css`
      background-color: ${palette.secondary400};
      &:hover {
        background-color: ${palette.secondary500};
      }
    `}
  ${(props) =>
    props.type === "tertiary" &&
    css`
      background-color: ${palette.neutral600};
      &:hover {
        background-color: ${palette.neutral700};
      }
    `}
    ${(props) =>
    props.disabled &&
    css`
      background-color: ${palette.neutral300};
      cursor: default;
      color: ${palette.neutral400};
      &:hover {
        background-color: ${palette.neutral300};
      }
    `}
  ${(props) =>
    props.type === "dangerVariant" &&
    css`
      background-color: ${palette.secondary90};
      color: ${palette.secondary400};
      &:hover {
        background-color: ${palette.secondary90};
      }
    `}
`;

// Input
