import { palette } from "modules/defines/styles";
import styled from "styled-components";
import HeaderLogo from "./HeaderLogo";

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <HeaderLogo logoHeight="auto" clickEnabled={true} />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  z-index: 999;
  position: fixed;
  height: 62px;
  top: 0px;
  width: 100%;
  background-color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px 0 14px;
`;

export default Header;
