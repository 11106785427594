import { css } from "styled-components";

const body12 = css`
  font-size: ${(props) => props.theme.typographyTheme.font.body12.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.body12.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.body12.lineHeight};
  letter-spacing: ${(props) => props.theme.typographyTheme.font.body12.letterSpacing};
`;

const body14 = css`
  font-size: ${(props) => props.theme.typographyTheme.font.body14.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.body14.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.body14.lineHeight};
`;

const bodyText16 = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
`;

export { body12, body14, bodyText16 };
