import { palette } from "modules/defines/styles";
import styled, { css } from "styled-components";

import { ReactNode, useState } from "react";
import { icon_fav } from "assets/icon";
// import type { ManagerProps, SpaceProps } from "modules/defines/interfaces";
import { useHistory, useLocation } from "react-router-dom";
import DashboardIcon from "assets/icons/Dashboard";
import ListSearchIcon from "assets/icons/ListSearch";
import EditIcon from "assets/icons/Edit";
import CalculatorIcon from "assets/icons/Calculator";
import { ButtonText14 } from "components/common/Button";
import { authAPI, productAPI } from "api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { title, titleText } from "components/style/title";
import { caption } from "components/style/caption";
import { subtitle14 } from "components/style/subtitle";

interface IconProps {
  enabled: boolean;
  size: string;
}

interface NavItemProps {
  enabled: boolean;
  onClick: () => void;
  title: string;
  marginTop?: string;
  icon: React.ComponentType<IconProps>;
}

const GlobalContainer = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const location = useLocation();

  const queryClient = useQueryClient();
  const pathName = location.pathname;

  const getDashboardManagerQuery = queryClient.getQueryData([
    "dashboardManager",
  ]) as {} as any | undefined;

  const ManagerData = getDashboardManagerQuery?.data?.data?.manager;
  const alertData = getDashboardManagerQuery?.data?.data?.alert;
  const isLogin = getDashboardManagerQuery?.data?.success;
  const [cookies, setCookie, removeCookie] = useCookies();

  const handleProductRegister = async () => {
    if (cookies.isAdmin === false || cookies.isAdmin === undefined) {
      alert("관리자 권한이 필요합니다.");
    } else {
      if (window.confirm("새로운 상품을 등록하시겠습니까?")) {
        const postData = {
          manager_id: ManagerData.id,
        };
        await productAPI.registerProduct(postData).then((res) => {
          if (res.status === 200) {
            alert("상품이 등록되었습니다.");
            return (window.location.href = `/product/${res.data.product.productId}-r`);
          } else {
            alert(
              "상품이 등록 중 오류가 발생했습니다.\n관리자에게 문의 바랍니다."
            );
          }
        });
      }
    }
  };

  // const signout = useMutation({
  //   mutationFn: authAPI.signOut,
  //   onSuccess: (data, variables, context) => {
  //     // 모든 쿼리를 무효화하고 갱신
  //     alert("로그아웃 되었습니다.");

  //     history.push("/signin");
  //   },
  //   // 로그아웃 API 호출이 실패하면 실행되는 콜백
  //   onError: (error, variables, context) => {
  //     console.error("로그아웃 실패:", error);
  //   },
  // });

  const navItemClickHandler = (path: string, onlyAdmin: boolean) => {
    if (cookies.isAdmin === false || cookies.isAdmin === undefined) {
      if (onlyAdmin) alert("관리자 권한이 필요합니다.");
      else history.push(path);
    } else history.push(path);
  };

  return (
    <Container>
      <Navigation>
        <HeaderContainer onClick={() => (window.location.href = "/")}>
          <img alt="" src={icon_fav} style={{ width: 32, height: 32 }} />
          <HeaderTitle>FAV 스토어관리</HeaderTitle>
        </HeaderContainer>

        <NavItemContainer>
          <NavItem
            enabled={pathName === "/"}
            onClick={() => navItemClickHandler("/", true)}
            title={"대시보드"}
            marginTop={"0px"}
            icon={DashboardIcon}
          />
          <NavGroupTitle marginTop="16px">상품 관리</NavGroupTitle>
          <NavItem
            enabled={pathName === "/product"}
            onClick={() => navItemClickHandler("/product", false)}
            title={"상품 조회/관리"}
            marginTop={"8px"}
            icon={ListSearchIcon}
          />
          <NavItem
            enabled={pathName === "/product/register"}
            onClick={handleProductRegister}
            title={"상품 등록"}
            marginTop={"0px"}
            icon={EditIcon}
          />
          <NavGroupTitle marginTop="16px">정산 관리</NavGroupTitle>
          <NavItem
            enabled={pathName === "/payment"}
            onClick={() => navItemClickHandler("/payment", true)}
            title={"정산 내역"}
            marginTop={"8px"}
            icon={CalculatorIcon}
          />
        </NavItemContainer>
      </Navigation>
      <ContentsContainer>
        <TopBarContainer>
          <TopBarTitleWrapper>
            <TopBarTitle>
              {ManagerData?.corpName !== null
                ? ManagerData?.corpName
                : "상호명 없음"}
            </TopBarTitle>
            {cookies.isAdmin === true ? (
              <>
                <TopBarRole>
                  {ManagerData?.role === "default" && "관리자"}
                </TopBarRole>
                <TopBarName>
                  {ManagerData?.managerName !== null
                    ? ManagerData?.managerName
                    : "이름없음"}{" "}
                  님
                </TopBarName>
              </>
            ) : (
              <TopBarRole>
                {ManagerData?.role === "default" && "상품 관리"}
              </TopBarRole>
            )}
          </TopBarTitleWrapper>
          <TopBarButtonWrapper>
            <TopBarTextButtonAlertWrapper>
              <ButtonText14 onClick={() => alert("준비 중입니다.")} btype="bk">
                알림
              </ButtonText14>
              {alertData && alertData.length > 0 && (
                <TopBarTextButtonAlertIcon />
              )}
            </TopBarTextButtonAlertWrapper>
            <ButtonText14 onClick={() => history.push("/setting")} btype="bk">
              설정
            </ButtonText14>
            <ButtonText14
              onClick={async () => {
                await authAPI.signOut().then((res) => {
                  if (res.data.success === true) {
                    alert("로그아웃 되었습니다.");
                    removeCookie("isAdmin", { path: "/" });
                    window.location.reload();
                  }
                });
              }}
              btype="tertiary"
            >
              {isLogin ? "로그아웃" : "로그인"}
            </ButtonText14>
          </TopBarButtonWrapper>
        </TopBarContainer>

        <ContentsWrapper>{children}</ContentsWrapper>
      </ContentsContainer>
    </Container>
  );
};

const NavItem: React.FC<NavItemProps> = ({
  marginTop,
  title,
  enabled,
  onClick,
  icon: Icon,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <NavItemWrapper
      marginTop={marginTop}
      onClick={onClick}
      enabled={enabled}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      isHovering={isHovering}
    >
      <Icon enabled={enabled || isHovering} size={"24"} />

      <NavTitle>{title}</NavTitle>
    </NavItemWrapper>
  );
};

const Container = styled.div`
  width: 100vw;
  /* min-height: 100vh;
  height: 100vh; */
  // overflow: scroll;
`;

const Navigation = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 240px;
  background-color: ${palette.white};
  z-index: 101;
  border-right: 1px solid ${palette.neutral300};
  padding-top: 14px;
`;

const HeaderContainer = styled.div`
  margin-left: 14px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  cursor: pointer;
`;

const HeaderTitle = styled.div`
  color: ${palette.primary900};
  ${title};
`;

const NavItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  padding: 16px;
`;

const NavGroupTitle = styled.div<{ marginTop?: string }>`
  ${caption};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  padding: 0 16px;
  color: ${palette.neutral500};
`;

const NavItemWrapper = styled.div<{
  marginTop?: string;
  enabled: boolean;
  isHovering: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background-color: ${palette.secondary90};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  cursor: pointer;
  border-radius: 4px;
  ${(props) =>
    props.enabled
      ? css`
          background-color: ${palette.neutral500};
          color: ${palette.white};
          pointer-events: none;
        `
      : css`
          background-color: ${palette.white};
          color: ${palette.neutral700};
          &:hover {
            background-color: ${palette.opacityB40};
            color: ${palette.white};
          }
        `}
`;

const NavTitle = styled.div`
  ${titleText};
`;

const TopBarContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 998;
  width: calc(100vw - 240px);
  height: 62px;
  padding: 24px 32px 10px 32px;
  background-color: ${palette.white};
  display: flex;
  justify-content: space-between;
`;
const TopBarTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TopBarTitle = styled.div`
  color: ${palette.neutral800};
  ${title};
`;

const TopBarRole = styled.div`
  margin-left: 16px;
  color: ${palette.neutral800};
  ${titleText};
`;

const TopBarName = styled.div`
  margin-left: 4px;
  color: ${palette.neutral800};
  ${subtitle14};
`;

const TopBarButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
// const TopBarTextButton = styled.div<{ color?: string }>`
//   cursor: pointer;
//   padding: 4px 10px;
//   border-radius: 8px;
//   color: ${(props) => (props.color ? props.color : palette.neutral1000)};
//   ${subtitle14};

//   &:hover {
//     background-color: ${palette.opacityB40};
//   }
// `;

const TopBarTextButtonAlertWrapper = styled.div`
  position: relative;
`;
const TopBarTextButtonAlertIcon = styled.div`
  position: absolute;
  background-color: ${palette.secondary400};
  border-radius: 100%;
  top: 1px;
  right: 2px;
  width: 6px;
  height: 6px;
`;

const ContentsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100vh;
  width: calc(100vw - 240px);

  display: flex;
  flex-direction: column;
`;
const ContentsWrapper = styled.div`
  margin-top: 62px;
  padding: 16px 16px 16px 24px;
  height: calc(100vh - 62px);
  scrollbar-width: thin;
  scrollbar-color: ${palette.neutral500} transparent;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:start:increment {
    display: block;
    height: 25px;
  }

  background-color: ${palette.neutral200};
  width: 100%;
`;

export default GlobalContainer;
