import Icon from "assets/icons/Icon";
import { subtitle14 } from "components/style/subtitle";
import { titleContents, titleText } from "components/style/title";
import { palette } from "modules/defines/styles";
import styled from "styled-components";
import Calender from "components/ui/Calendar";
import { useContext, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { DashboardProductProps, ProductItem } from "modules/defines/interfaces";
import { ModalContext, ModalContextType } from "modules/context/ModalContext";
import ModalPaymentInfo from "components/modal/ModalPaymentInfo";
import { CellButton } from "components/common/TextField";
import Status from "components/common/badge/Status";
import { ProductSectionTitle } from "components/GlobalStyles";
import LoadingModal from "components/ui/state/LoadingModal";
import ToolTip from "components/common/ToolTip";

const COMMISSION_RATE = 0.07;

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface dashboardProductDataProps {
  data: {
    success: boolean;
    data: {
      product: ProductItem[] | [];
      order: DashboardProductProps[][];
    };
  };
}

const PaymentTable = () => {
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useContext(
    ModalContext
  ) as ModalContextType;

  const getDashboardProductQuery = queryClient.getQueryData([
    "dashboardProduct",
  ]) as dashboardProductDataProps;
  const storePaymentData =
    getDashboardProductQuery && getDashboardProductQuery?.data.data;
  const paymentConfirmationCount = storePaymentData?.order?.flatMap(
    (item) => item
  );

  const filterData = paymentConfirmationCount?.filter(
    (item) => item.used === true
  );

  const today = new Date();
  const [date, setDate] = useState<Value>([today, today]);
  const [modal, setModal] = useState<boolean>(false);
  const [showCalender, setShowCalender] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [targetData, setTargetData] = useState<any[]>([]);
  const handleDateChange = () => {
    if (Array.isArray(date) && date[0] !== null && date[1] !== null) {
      const startDate = new Date(date[0].toLocaleDateString());
      const endDate = new Date(date[1].toLocaleDateString());
      const diffInMilliseconds = Math.abs(
        endDate.getTime() - startDate.getTime()
      );
      const diffInMonths = diffInMilliseconds / (1000 * 60 * 60 * 24 * 30);
      setModal(true);
      setShowCalender(false);
      setTargetData([]);
      setTotalPrice(0);
      // if (diffInMonths >= 1) {
      //   alert("최대 1개월 이내에서 조회할 수 있습니다.");
      // }

      // if (diffInMonths < 1) {
      setTimeout(() => {
        setModal(false);

        const total = filterData.filter((data) => {
          const dataDate = new Date(data.usedAt);
          const soon = new Date(
            dataDate.getFullYear(),
            dataDate.getMonth() + 1,
            1
          );
          return soon >= startDate && soon <= endDate;
        });

        setTargetData(total);
        const totalPrice = total.reduce((acc, cur) => acc + cur.total_price, 0);
        setTotalPrice(totalPrice);
      }, 1200);
    }
    // }
  };

  const resetDate = () => {
    if (Array.isArray(date) && date[0] !== null && date[1] !== null) {
      const startDate = new Date(date[0].toLocaleDateString());
      const endDate = new Date(date[1].toLocaleDateString());
      const today = new Date();
      setDate([today, today]);
      setShowCalender(false);
      const totalPrice = filterData
        .filter((data) => {
          const today = new Date();
          return today >= startDate && today <= endDate;
        })
        .reduce((acc, cur) => acc + cur.total_price, 0);
      setTotalPrice(totalPrice);
      setTargetData([]);
    }
  };

  const handleDetailBtn = (item: DashboardProductProps) => {
    return openModal(
      <ModalPaymentInfo
        commissionRate={COMMISSION_RATE}
        subject="정산 정보"
        onClose={() => closeModal()}
        item={item}
      />
    );
  };

  return (
    <>
      {modal && <LoadingModal />}
      <Layout>
        <ProductSectionTitle>조회하기</ProductSectionTitle>
        <tbody>
          <CalenderSettingWrapper>
            <TooltipIconWrapper padding="10px 8px">
              정산 예정일
              <ToolTip
                content={`정산 예정일을 포함할 수 있도록 날짜를 설정해주세요.`}
                location={"bottom-right"}
                width={"180px"}
                top={"12px"}
                right={"8px"}
              />
            </TooltipIconWrapper>
            <CalenderWrapper>
              {Array.isArray(date) ? (
                date[0]
                  ?.toLocaleDateString("ko", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    formatMatcher: undefined,
                  })
                  .split(".") // 2024.05.02 -> ["2024", "05", "02"]
                  .map((date) => date.trim())
                  .join("-")
                  .slice(0, -1)
              ) : (
                <span>날짜 선택</span>
              )}
            </CalenderWrapper>
            <IconWrapper className="noCursor">
              <Icon icon="arrowRight" fill={palette.neutral600} />
            </IconWrapper>

            <CalenderWrapper>
              {Array.isArray(date) ? (
                date[1]
                  ?.toLocaleDateString("ko", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    formatMatcher: undefined,
                  })
                  .split(".") // 2024.05.02 -> ["2024", "05", "02"]
                  .map((date) => date.trim())
                  .join("-")
                  .slice(0, -1)
              ) : (
                <span>날짜 선택</span>
              )}
            </CalenderWrapper>
            <IconWrapper onClick={() => setShowCalender((prev) => !prev)}>
              <Icon icon="calendar" fill={palette.neutral600} />
            </IconWrapper>

            <ParentPositionLayout>
              {showCalender && (
                <Calender
                  date={date}
                  setDate={setDate}
                  setShowCalender={setShowCalender}
                  type="range"
                />
              )}
            </ParentPositionLayout>
            <SmallButton onClick={handleDateChange} type="button">
              검색
            </SmallButton>
            <IconWrapper onClick={resetDate}>
              <Icon icon="redo" fill={palette.neutral600} />
            </IconWrapper>
            <TooltipIconWrapper padding="10px 8px">
              정산 합계
            </TooltipIconWrapper>
            <Td className="full">
              <span>{totalPrice.toLocaleString()}</span>
              <span>원</span>
            </Td>
            <DownloadButton type="button">엑셀 다운로드</DownloadButton>
          </CalenderSettingWrapper>
        </tbody>
      </Layout>
      <OverflowContainer>
        <table>
          <thead>
            <tr>
              <Th>사용일자</Th>
              <Th>정산 예정일</Th>
              <Th>지급 상태</Th>
              <Th>정산 완료일</Th>
              <Th>정산 금액 합계</Th>
              <Th>결제 금액</Th>
              <Th>수수료 합계</Th>
              <Th>지급 보류</Th>
              <Th>정산 방식</Th>
              <Th minWidth={240}>정산 계좌</Th>
              <Th>상세 정보</Th>
            </tr>
          </thead>
          <tbody>
            {targetData?.map((item) => {
              const date = new Date(item.usedAt);
              const usedDate = ` ${date
                .getFullYear()
                .toString()
                .replace("20", "")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${date
                .getDate()
                .toString()
                .padStart(2, "0")}`;

              var addDate = 2;
              // if (date.getDate() < 15) addDate = 1;
              // if (date.getDate() >= 15) addDate = 2;

              const soonDate = ` ${date
                .getFullYear()
                .toString()
                .replace("20", "")}/${(date.getMonth() + addDate)
                .toString()
                .padStart(2, "0")}/15`;
              return (
                <tr>
                  <Td>{usedDate}</Td>
                  <Td>{soonDate}</Td>
                  <Td>
                    <Status
                      status={item.reconciledAt === null ? "primary" : "gray"}
                    >
                      {item.reconciledAt === null ? "정산 예정" : "정산 완료"}
                    </Status>
                  </Td>
                  <Td>
                    {item.reconciledAt === null
                      ? "-"
                      : item.reconciledAt.split("T")[0].replace(/-/g, "/")}
                  </Td>

                  <Td>
                    {(
                      item.total_price -
                      Math.round(item.total_price * COMMISSION_RATE)
                    ).toLocaleString()}
                    <TextBold>&nbsp;원</TextBold>
                  </Td>
                  <Td>
                    {item.total_price.toLocaleString()} <TextBold>원</TextBold>
                  </Td>
                  <Td>
                    -{Math.round(item.total_price * COMMISSION_RATE)}{" "}
                    <TextBold>원</TextBold>
                  </Td>
                  <Td>0</Td>
                  <Td>계좌이체</Td>
                  <Td>우리은행 100**********</Td>
                  <Td noPadding>
                    <CellButton
                      width={"100%"}
                      type={"tertiary"}
                      onClick={() => handleDetailBtn(item)}
                    >
                      보기
                    </CellButton>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {targetData?.length === 0 && (
          <NoContent colSpan={8} rowSpan={10}>
            정산 내역이 없습니다.
          </NoContent>
        )}
      </OverflowContainer>
    </>
  );
};

const OverflowContainer = styled.div`
  width: 100%;
  height: 360px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    height: 4px;
    border-radius: 12px;
    visibility: hidden;
  }
  &::-webkit-scrollbar-track {
    visibility: hidden;
  }
  &::-webkit-scrollbar-thumb {
    visibility: visible;
    background: ${palette.neutral400};
  }
  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:start:increment {
    display: block;
    height: 20px;
  }

  & > table {
    width: 100%;
    & > thead {
      position: sticky;
      top: 0;
    }
  }
`;

const Layout = styled.table`
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
`;

const ParentPositionLayout = styled.td`
  position: relative;
`;

const TextBold = styled.b`
  color: ${palette.neutral800};
  ${titleText};
`;

const CalenderSettingWrapper = styled.tr`
  margin-top: 16px;
  width: calc(100% - 16px);

  display: flex;
  /* position: relative; */
`;
const NoContent = styled.td`
  background-color: inherit;
  width: calc(100% - 40px);
  color: ${palette.neutral500};
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  ${titleContents};
`;

const DownloadButton = styled.button`
  ${titleText};
  color: white;
  float: left;
  background-color: ${palette.primary700};

  padding: 10px 22px;
  white-space: nowrap;
  &:hover,
  &:focus {
    background-color: ${palette.primary800};
  }
`;
const SmallButton = styled.button`
  min-width: 60px;
  height: 40px;
  ${titleText};
  padding: 10px 8px;
  color: white;
  border: 1px solid ${palette.primary500};
  background-color: ${palette.primary500};
  &:hover {
    background-color: ${palette.primary600};
  }
`;

export const Th = styled.th<{ minWidth?: number }>`
  ${titleText};
  min-width: ${(props) => props.minWidth}px;
  width: 100%;
  width: fit-content;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px ${palette.neutral300} inset;
  /* border: 1px solid ${palette.neutral300}; */
  background-color: ${palette.neutral200};
  color: ${palette.neutral1000};
  padding: 10px 8px;
  vertical-align: middle;
`;

export const Td = styled.td<{ noPadding?: boolean; width?: number }>`
  text-align: center;
  border: 1px solid ${palette.neutral300};
  background-color: ${palette.white};

  ${subtitle14};
  color: ${palette.neutral1000};
  & > span:first-of-type {
    ${titleText};
    color: ${palette.neutral1000};
  }

  & > span:last-of-type {
    ${subtitle14};
    color: ${palette.neutral1000};
  }
  padding: ${(props) => (props.noPadding ? "0px" : "10px 8px")};
  height: 40px;
  vertical-align: middle;
  width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  &.full {
    width: 30%;
    min-width: 304px;
    text-align: start;
    display: flex;
    gap: 4px;
    align-items: center;
    & > span:first-of-type {
      ${titleText};
      color: ${palette.neutral1000};
    }

    & > span:last-of-type {
      ${subtitle14};
      color: ${palette.neutral1000};
    }
  }
  &.notice {
    ${titleText};
    color: ${palette.primary500};
    text-align: start;
    width: 100%;
  }
`;

const CalenderWrapper = styled(Td)`
  width: 100%;
  min-width: 122px;
  text-align: center;
`;

const TooltipIconWrapper = styled(Th)<{ padding?: string }>`
  padding: ${(props) => (props.padding ? props.padding : "10px 34px")};
  display: flex;
  width: 100%;
  min-width: 120px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  & > i {
    cursor: pointer;
  }
  position: relative;
`;
const IconWrapper = styled(Td)`
  width: fit-content;
  min-width: 40px;
  height: 40px;
  padding: 10px 8px;
  cursor: pointer;
  &.noCursor {
    cursor: default;
    min-width: 40px;
  }
`;

export default PaymentTable;
