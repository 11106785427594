import { useState } from "react";

type Callback = (value: string) => boolean | void;

function useInput(initialValue: any, ...callbacks: Callback[]) {
  const [value, setValue] = useState(initialValue);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (event) => {
    let inputValue = event.target.value;

    for (const callback of callbacks) {
      const result = callback(inputValue);
      if (result === false) {
        return; // 유효하지 않은 값이므로 업데이트를 중단합니다.
      }
    }

    setValue(inputValue);
  };

  return [value, handleChange];
}

export default useInput;
