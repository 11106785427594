import axios, { AxiosResponse } from "axios";
import type { SignUpUserProps } from "modules/defines/interfaces";
import { apiPaths } from "modules/defines/path";

export const apiAWS = axios.create({
  baseURL: process.env.REACT_APP_LOCAL
    ? apiPaths.awsLocalUrl
    : process.env.HTTPS
    ? apiPaths.awsDevUrl
    : apiPaths.awsBaseUrl,
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true,
});

export const authAPI = {
  checkUser: () => apiAWS.get(apiPaths.checkUser),
  signUp: (data: SignUpUserProps) => apiAWS.post(apiPaths.signUp, data),
  signIn: (data: { email: string; password: string; adminCode?: string }) =>
    apiAWS.post(apiPaths.signIn, {
      email: data.email,
      password: data.password,
      adminCode: data.adminCode || "",
    }),
  temporarySignIn: (data: object) => {
    apiAWS.post(apiPaths.signIn, data);
  },

  signOut: () => apiAWS.post(apiPaths.signOut),
  sendCode: (data: {
    type: "signup" | "reset-password";
    email: string;
    code: string;
  }) => apiAWS.post(apiPaths.sendCode, data),
};

export const dashboardAPI = {
  getDashboardManager: () => apiAWS.get(apiPaths.getDashboardManager),
  getDashboardProduct: () => apiAWS.get(apiPaths.getDashboardProduct),
  getDashboardMonthInfo: () => apiAWS.get(apiPaths.getDashboardMonthInfo),
};

export const productAPI = {
  getProductList: (mid: string) => apiAWS.get(apiPaths.productList + mid),
  getProductDetail: (pid: string, header: object) =>
    apiAWS.get(apiPaths.productDetail + pid, { headers: header }),
  registerProduct: (data: object) =>
    apiAWS.post(apiPaths.registerProduct, data), // 상품 등록
  editProduct: (data: object) => apiAWS.post(apiPaths.editProduct, data), // 상품 수정
  updateStatusProduct: (data: object) =>
    apiAWS.post(apiPaths.updateStatusProduct, data), // 상품 상태 변경 (승인, 판매 시작, 판매 중지, 판매 종료...)
  deleteProduct: (header: object) =>
    apiAWS.delete(apiPaths.deleteProduct, { headers: header }), // 상품 삭제
  // Image
  uploadImage: (data: object, header: object) =>
    apiAWS.post(apiPaths.uploadImage, data, { headers: header }), // 이미지 업로드
  deleteImage: (data: object) => apiAWS.post(apiPaths.deleteImage, data), // 이미지 삭제

  // order
  getProductOrderList: (header: object) =>
    apiAWS.get(apiPaths.getProductOrderList, { headers: header }),
  setUsageOrder: (data: object) => apiAWS.post(apiPaths.setUsageOrder, data),
};

export const paymentAPI = {
  getPaymentInfo: (header: object) =>
    apiAWS.get(apiPaths.getPaymentInfo, { headers: header }),
};

export const spaceAPI = {
  getSpace: () => apiAWS.get(apiPaths.getSpace),
  registerSpace: (data: object) => apiAWS.post(apiPaths.registerSpace, data),
  uploadRegisterSpaceImage: (data: object, header: object) =>
    apiAWS.post(apiPaths.uploadRegisterSpaceImage, data, { headers: header }),
  requestSpaceEdit: (data: object) =>
    apiAWS.post(apiPaths.requestSpaceEdit, data),
};

export const userAPI = {
  useApproval: (data: object) => apiAWS.post(apiPaths.useApproval, data),
  getUnusedOrders: (header: object) =>
    apiAWS.get(apiPaths.getUnusedOrders, { headers: header }),
};
export const managerAPI = {
  renewalApprovalCode: (data: object) =>
    apiAWS.post(apiPaths.renewalApprovalCode, data),
  registerAccount: (data: object) =>
    apiAWS.post(apiPaths.registerAccount, data),
};
