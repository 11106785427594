import { Player } from "@lottiefiles/react-lottie-player";
import { productAPI } from "api";
import { m, motion } from "framer-motion";
import {
  ModalStandardProps,
  ProductItemOption,
} from "modules/defines/interfaces";
import { palette } from "modules/defines/styles";
import { useEffect, useState } from "react";
import { set } from "react-hook-form";
import spinner from "assets/lottie/spinner.json";
import styled from "styled-components";

interface ModalManageCountProps extends ModalStandardProps {
  // props
  productId: number;
  managerId: number;
  countByOption: boolean;
  productCount: number;
  options: ProductItemOption[];
}

const ModalManageCount = ({ ...props }: ModalManageCountProps) => {
  const {
    subject = "",
    onClose,
    productId,
    managerId,
    countByOption,
    productCount,
    options,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);

  // 1 ) 총 재고 관리의 경우
  const [totalCountText, setTotalCountText] = useState<string>("");
  const onChangeTotalCount = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTotalCountText(e.target.value);

  // 2 ) 옵션별 재고 관리의 경우
  const [optionsInput, setOptionsInput] = useState<ProductItemOption[]>([]);
  const onChangeOptionCount = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const newOptionsInput = [...optionsInput];
    newOptionsInput[idx] = {
      ...newOptionsInput[idx],
      count: parseInt(e.target.value),
    };
    setOptionsInput(newOptionsInput);
  };

  // Data Initialize
  useEffect(() => {
    if (productCount) {
      setTotalCountText(productCount.toString());
    }
    if (options) {
      setOptionsInput(options);
    }
  }, []);

  const submitListener = async () => {
    if (productId === 0) {
      alert("오류가 발생했습니다.\n잠시후 다시 시도해주세요.");
      return;
    } else {
      setLoading(true);
      if (!countByOption) {
        await productAPI
          .editProduct({
            count: parseInt(totalCountText),
            id: productId,
            manager_id: managerId,
            only_edit_count: true,
          })
          .then(() => {
            alert("재고 수정이 완료되었습니다.");
            if (onClose) {
              return onClose();
            }
          });
      } else {
        await productAPI
          .editProduct({
            options: optionsInput,
            id: productId,
            manager_id: managerId,
            only_edit_count: true,
          })
          .then(() => {
            alert("옵션별 재고 수정이 완료되었습니다.");
            if (onClose) {
              return onClose();
            }
          });
      }
    }
  };

  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <Subject>{subject}</Subject>
      <ContentsWrapper>
        {!countByOption ? (
          <>
            <Label>총 재고</Label>
            <CountInput
              type={"number정"}
              value={totalCountText}
              onChange={onChangeTotalCount}
            />
          </>
        ) : (
          <>
            <LabelTitle>옵션별 재고</LabelTitle>
            {optionsInput &&
              optionsInput.length > 0 &&
              optionsInput.map((option, idx) => {
                return (
                  <div key={idx}>
                    <Label>{option.title}</Label>
                    <CountInput
                      type={"number"}
                      value={optionsInput[idx].count}
                      onChange={(e) => {
                        onChangeOptionCount(e, idx);
                      }}
                    />
                  </div>
                );
              })}
          </>
        )}

        <SubmitButton
          onClick={() => !loading && submitListener()}
          disabled={loading}
        >
          {loading && (
            <LoadingWrapper>
              <Player
                style={{
                  width: "32px",
                  height: "32px",
                }}
                src={spinner}
                autoplay
                loop
              />
            </LoadingWrapper>
          )}
          재고 수정
        </SubmitButton>
      </ContentsWrapper>
    </ModalWrapper>
  );
};
const LoadingWrapper = styled.div`
  margin-right: 12px;
`;

const ModalWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 20px;
  padding: 0px 4px;
  min-width: 372px;
`;

const Subject = styled.h2`
  color: ${palette.neutral1000};
  font-feature-settings: "clig" off, "liga" off;
  /* TitleContents */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;
const ContentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
`;

const Label = styled.div`
  color: ${palette.neutral600};

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const LabelTitle = styled.div`
  color: ${palette.neutral700};

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  margin-bottom: 8px;
`;

const CountInput = styled.input`
  all: unset;
  border: 1px solid ${palette.neutral300};
  border-radius: 4px;
  padding: 10px;
  //
  color: ${palette.neutral700};
  font-feature-settings: "clig" off, "liga" off;
  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const SubmitButton = styled.button`
  all: unset;
  margin-top: 32px;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${palette.primary500};
  color: ${palette.white100};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.primary600};
  }
  &:disabled {
    background-color: ${palette.neutral300};
    color: ${palette.neutral500};
  }
`;

export default ModalManageCount;
