import React, { MouseEventHandler } from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  color: string;
  onClick?: MouseEventHandler<SVGSVGElement> | undefined;
}

const CircleCancel: React.FC<IconProps> = ({
  color = palette.neutral600,
  onClick,
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
      onClick={onClick}
    >
      <g id="circle-x_filled">
        <path
          id="Vector"
          d="M11.333 2.22627C12.3385 2.80684 13.175 3.63995 13.7596 4.64314C14.3441 5.64632 14.6566 6.78478 14.666 7.94583C14.6753 9.10687 14.3813 10.2502 13.813 11.2627C13.2447 12.2752 12.4218 13.1217 11.4258 13.7185C10.4298 14.3152 9.29525 14.6415 8.13441 14.665C6.97356 14.6885 5.82671 14.4084 4.80738 13.8524C3.78805 13.2965 2.93159 12.484 2.32279 11.4953C1.71398 10.5066 1.37395 9.37608 1.33634 8.2156L1.33301 7.9996L1.33634 7.7836C1.37368 6.63226 1.70871 5.51024 2.30877 4.52693C2.90883 3.54362 3.75344 2.73257 4.76027 2.17286C5.76709 1.61314 6.90177 1.32387 8.05368 1.33323C9.20559 1.34259 10.3354 1.65027 11.333 2.22627ZM7.00701 6.09294C6.86733 6.00979 6.70208 5.98054 6.54235 6.01069C6.38261 6.04084 6.2394 6.12831 6.13964 6.25666C6.03988 6.385 5.99045 6.54537 6.00065 6.70761C6.01084 6.86984 6.07996 7.02276 6.19501 7.1376L7.05634 7.9996L6.19501 8.8616L6.13967 8.92427C6.03607 9.05826 5.98735 9.22667 6.00341 9.39529C6.01947 9.5639 6.09911 9.72008 6.22616 9.8321C6.3532 9.94413 6.51812 10.0036 6.68742 9.99842C6.85672 9.99325 7.0177 9.92383 7.13767 9.80427L7.99967 8.94294L8.86167 9.80427L8.92434 9.8596C9.05834 9.96321 9.22674 10.0119 9.39536 9.99587C9.56397 9.97981 9.72015 9.90017 9.83218 9.77312C9.9442 9.64608 10.0037 9.48116 9.99849 9.31186C9.99332 9.14256 9.92391 8.98158 9.80434 8.8616L8.94301 7.9996L9.80434 7.1376L9.85967 7.07494C9.96328 6.94094 10.012 6.77253 9.99594 6.60392C9.97988 6.4353 9.90024 6.27912 9.77319 6.1671C9.64615 6.05508 9.48123 5.99561 9.31193 6.00078C9.14263 6.00595 8.98165 6.07537 8.86167 6.19494L7.99967 7.05627L7.13767 6.19494L7.07501 6.1396L7.00701 6.09294Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CircleCancel;
