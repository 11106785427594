import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  color?: string;
  size?: string;
}

const CircleInfo: React.FC<IconProps> = ({ color, size = 14 }) => {
  const viewBox = `0 0 ${size} ${size}`;
  const fill = color ? color : "#0079CC";
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="circle_info">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00008 1.16663C3.77842 1.16663 1.16675 3.7783 1.16675 6.99996C1.16675 10.2216 3.77842 12.8333 7.00008 12.8333C10.2217 12.8333 12.8334 10.2216 12.8334 6.99996C12.8334 3.7783 10.2217 1.16663 7.00008 1.16663ZM7.00008 3.20829C6.51683 3.20829 6.12508 3.60004 6.12508 4.08329C6.12508 4.56654 6.51683 4.95829 7.00008 4.95829C7.48333 4.95829 7.87508 4.56654 7.87508 4.08329C7.87508 3.60004 7.48333 3.20829 7.00008 3.20829ZM7.00008 5.83329C6.51683 5.83329 6.12508 6.22504 6.12508 6.70829V9.91663C6.12508 10.3999 6.51683 10.7916 7.00008 10.7916C7.48333 10.7916 7.87508 10.3999 7.87508 9.91663V6.70829C7.87508 6.22504 7.48333 5.83329 7.00008 5.83329Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default CircleInfo;
