import { ReactNode, useState } from "react";

export const useModal = () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>("0");

  const closeModal = (content = false) => {
    setModal(false);
    setModalContent(content);
  };
  const openModal = (content: ReactNode = false) => {
    setModal(true);
    if (content) {
      setModalContent(content);
    }
  };

  return { modal, closeModal, openModal, modalContent };
};
