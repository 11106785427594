/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Dispatch, Fragment, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { palette } from "modules/defines/styles";
import GlobalContainer from "./GlobalContainer";
import RedoIcon from "assets/icons/Redo";
import ArrowRightIcon from "assets/icons/ArrowRight";
import SettingButton from "../components/ui/SettingButton";
import { INFO_ARRAY } from "modules/defines/define";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useQueryClient, QueryKey } from "@tanstack/react-query";
import type {
  DashboardProductProps,
  SettingsProps,
  InsightProps,
} from "modules/defines/interfaces";

import DashboardNotice from "components/ui/DashboardNotice/DashboardNotice";
import GraphTooltip from "components/ui/GraghTooptip";
import { getSalesValue } from "utils/getSalesValue";
import { subtitle14 } from "components/style/subtitle";
import { titleContents, titleText } from "components/style/title";
import { caption, caption02 } from "components/style/caption";
import { AxiosResponse } from "axios";

/**
 * @function generateRandomData
 * @description API연결 전 테스트를 위한 랜덤 데이터를 생성합니다.
 * @param startDate {string | number | Date} 시작 날짜
 * @param endDate {number | Date} 끝나는 날짜
 * @returns {Array} 랜덤 데이터
 */
const generateRandomData = (startDate: string | number | Date, endDate: number | Date) => {
  const data = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    data.push({
      name: `${currentDate.getMonth() + 1}.${currentDate.getDate()}`,
      data: Math.floor(Math.random() * 40),
    });

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return data;
};

const MainContainer: React.FC = () => {
  const queryClient = useQueryClient();
  /**
   * @description `getMonthInfo`는 유저의 매출 통계 데이터를 담고 있습니다.
   */

  const getMonthInfo = queryClient.getQueryData(["dashboardMonthInfo"]) as AxiosResponse<any, any>;
  const getDashboardProduct = queryClient.getQueryData(["dashboardProduct"]) as AxiosResponse<
    any,
    any
  >;
  const getProductList = queryClient.getQueryData(["userProductList"]) as AxiosResponse<any, any>;

  /**
   * @description `totalViewCount`는 페이지 유입 통계의 노출 수를 나타냅니다. --> 임시
   * @description `totalClickCount`는 페이지 유입 통계의 클릭 수를 나타냅니다. --> 임시
   */
  const totalViewCount = 1000;
  const totalClickCount = 500;

  const { data: productData } = { ...getDashboardProduct };
  const { data } = { ...getMonthInfo };

  const monthInfo = data?.data;
  const isMonthInfo = data?.success;
  const dateSplit = monthInfo?.date.split("~");
  const date = new Date();

  const orderArray: DashboardProductProps[][] = productData?.data?.order;

  const storeProductManagementData = getProductList?.data;
  const totalOrderCount = getSalesValue(monthInfo?.count);
  const totalOrderCustomerCount = getSalesValue(monthInfo?.user);
  const totalOrderPrice = getSalesValue(monthInfo?.profit);

  const [settings, setSettings] = useState<SettingsProps>({
    count: true,
    people: false,
    price: false,
  });

  const generateGraphDate = (
    startDate: string | number | Date,
    endDate: number | Date,
    dataArray: [value: string][]
  ) => {
    const data: { name: string; data: number }[] = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      data.push({
        name: `${currentDate.getMonth() + 1}.${currentDate.getDate()}`,
        data: dataArray?.map((item) => Number(item))[data.length] || 0,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return data;
  };

  const [insightSettings, setInsightSettings] = useState<InsightProps>({
    view: true,
    click: false,
  });

  const [getCurrentDate, setCurrentData] = useState<Date>(date);
  const [refreshTimePayment, setRefreshTimePayment] = useState<Date>(new Date());
  const [startDateStr, endDateStr] = dateSplit || ["", ""];

  useEffect(() => {}, [getCurrentDate, settings.price]);

  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const dates: string[] = [];

  for (let date = new Date(endDate); date >= startDate; date.setDate(date.getDate() - 7)) {
    dates.unshift(`${new Date(date).getMonth() + 1}.${new Date(date).getDate()}`);
  }

  const nextDay = new Date(startDate);
  const getNextDate = new Date(nextDay.setDate(startDate.getDate() + 1));
  const restDate = [
    `${startDate.getMonth() + 1}.${startDate.getDate()}`,
    `${getNextDate.getMonth() + 1}.${getNextDate.getDate()}`,
  ];

  dates.unshift(...restDate);

  const graphData = (data: any[]) =>
    isMonthInfo ? generateGraphDate(startDate, endDate, data) : [];

  // monthInfo?.count;

  const graphDatatype = () => {
    switch (true) {
      case settings.count:
        return monthInfo?.count;
      case settings.people:
        return monthInfo?.user;
      case settings.price:
        return monthInfo?.profit;
      default:
        return monthInfo?.count;
    }
  };

  const max = Math.max(...graphData(graphDatatype()).map((item) => item.data));

  const units = [
    1000, 1500, 5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 50000, 100000, 200000,
    500000, 1000000,
  ];

  let roundedPrices =
    monthInfo?.profit.map((price: number) => {
      let closestUnit = units.reduce((prev, curr) =>
        Math.abs(curr - price) < Math.abs(prev - price) ? curr : prev
      );
      return Math.round(price / closestUnit) * closestUnit;
    }) || [];

  const priceMax = Math.max(...roundedPrices);
  const yAxisValues = [];
  for (let i = 0; i <= priceMax; i += 5000) {
    yAxisValues.push(i);
  }

  const step = Math.ceil(max / 7);
  const ticks = Array.from({ length: 8 }, (_, i) => i * step);

  const randomData = generateRandomData(startDate, endDate);

  const toggleSetting = <T extends Record<string, boolean>>(
    category: keyof T,
    setState: React.Dispatch<React.SetStateAction<T>>
  ) => {
    setState((prevSettings: any) => {
      const newSettings = Object.keys(prevSettings).reduce(
        (acc, key) => {
          acc[key] = key === category;
          return acc;
        },
        { ...prevSettings }
      );
      return newSettings;
    });
  };

  const DATA_refresh_time_product = getCurrentDate.toLocaleTimeString("ko-KR", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const DATA_refresh_time_payment = refreshTimePayment.toLocaleTimeString("ko-KR", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const handleRefreshCurrentTime = (
    queryKey: QueryKey | undefined,
    setState: Dispatch<React.SetStateAction<Date>>
  ) => {
    setState(new Date());
    queryClient.refetchQueries({
      queryKey: queryKey,
      type: "active",
      exact: true,
      stale: true,
    });
  };

  const nowDate = new Date();
  const startOfMonth = new Date(Date.UTC(nowDate.getUTCFullYear(), nowDate.getUTCMonth(), 1));
  const totalSettlementAmount =
    orderArray
      ?.filter((item: string | any[]) => item.length > 0)
      .flatMap((item) => {
        return item.filter((item) => new Date(item.createdAt) >= startOfMonth && item.used);
      })
      .map((item) => item.total_price)
      .reduce((acc, item) => acc + item, 0) || 0;

  const paymentCompletedCount = orderArray
    ?.flatMap((item) => item)
    .filter((item) => item.paid).length;

  const paymentConfirmationCount = orderArray
    ?.flatMap((item) => item)
    .filter((item) => item.used).length;

  const getStoreProductManagementStatus = (statusNumber: number) => {
    const filterStatusArray = storeProductManagementData
      ?.map((item: { status: any }) => item.status)
      .filter((item: number) => item === statusNumber);
    const reduceStatus = filterStatusArray?.length;
    return reduceStatus;
  };

  return (
    <GlobalContainer>
      <DashboardContainer>
        <ColumnContainer>
          <DashboardItemContainer>
            <DashboardItemHeader>
              <DashboardItemTitleWrapper>
                <DashboardItemTitle>스토어 매출 통계</DashboardItemTitle>
                <DashboardItemTitleDesc>오전 8:00 업데이트</DashboardItemTitleDesc>
              </DashboardItemTitleWrapper>
            </DashboardItemHeader>
            <DashBoardCategoryWrapper>
              <DashboardButtonWrapper>
                <SettingButton
                  isOn={settings.count}
                  onClick={() => toggleSetting("count", setSettings)}
                  disabled={false}
                >
                  결제건 수
                </SettingButton>
                <SettingButton
                  onClick={() => toggleSetting("people", setSettings)}
                  isOn={settings.people}
                  disabled={false}
                >
                  결제자 수
                </SettingButton>
                <SettingButton
                  onClick={() => toggleSetting("price", setSettings)}
                  isOn={settings.price}
                  disabled={false}
                >
                  결제 금액
                </SettingButton>
              </DashboardButtonWrapper>
              <DashBoardInfoWrapper>
                <Caption>지난 30일 기준</Caption>
                <DashboardSubtitle>
                  총
                  {settings.count && (
                    <Fragment>
                      <DashboardTitle>{totalOrderCount}</DashboardTitle> 건
                    </Fragment>
                  )}
                  {settings.people && (
                    <Fragment>
                      <DashboardTitle>{totalOrderCustomerCount}</DashboardTitle>명
                    </Fragment>
                  )}
                  {settings.price && (
                    <Fragment>
                      <DashboardTitle>{totalOrderPrice.toLocaleString()}</DashboardTitle>원
                    </Fragment>
                  )}
                </DashboardSubtitle>
              </DashBoardInfoWrapper>
            </DashBoardCategoryWrapper>
            <GraphContainer className={!isMonthInfo ? "noData" : ""} width="100%" height={"78%"}>
              <LineChart
                width={506}
                height={160}
                data={graphData(graphDatatype())}
                margin={{
                  top: 32,
                  right: 0,
                  left: settings.price ? -25 : -40,
                  bottom: 29,
                }}
              >
                <CartesianGrid
                  strokeDasharray="0 0"
                  stroke={palette.neutral200}
                  vertical={false}
                  strokeWidth={1}
                />
                <XAxis
                  color={palette.neutral900}
                  fontWeight={10}
                  letterSpacing={-0.2}
                  fontSize={10}
                  tickLine={false}
                  tickMargin={4}
                  padding={{ left: 8, right: 8 }}
                  ticks={dates}
                  dataKey="name"
                  stroke={palette.neutral400}
                />
                <YAxis
                  fill="red"
                  fontSize={10}
                  color={palette.neutral500}
                  fontWeight={10}
                  letterSpacing={-0.2}
                  fontStyle={"normal"}
                  // domain={[0, settings.price ? priceMax : max]}
                  // ticks={settings.price ? yAxisValues : ticks}
                  tickLine={false}
                  axisLine={false}
                />
                <Tooltip
                  cursor={false}
                  contentStyle={{
                    textAlign: "center",
                    padding: "6px 12px 4px 12px",
                    borderRadius: "4px",
                    boxShadow: `0 0 1px 0 ${palette.opacityB25}`,
                  }}
                  content={
                    <GraphTooltip type={settings.count ? "건" : settings.people ? "명" : "원"} />
                  }
                />

                <Line
                  animationEasing="ease-in-out"
                  type="monotone"
                  dataKey="data"
                  stroke={palette.primary500}
                  strokeWidth={1}
                  dot={{
                    r: 3,
                    fill: palette.primary500,
                    stroke: "none",
                    enableBackground: "red",
                  }}
                  activeDot={{
                    r: 5,
                    fill: palette.primary500,
                    stroke: "#CCEAFF",
                    strokeWidth: 3,
                  }}
                />
              </LineChart>
            </GraphContainer>
          </DashboardItemContainer>
          <DashboardItemContainer className="waiting">
            <DashboardItemHeader>
              <DashboardItemTitleWrapper>
                <DashboardItemTitle>페이지 유입 통계</DashboardItemTitle>
                <DashboardItemTitleDesc>오전 8:00 업데이트</DashboardItemTitleDesc>
              </DashboardItemTitleWrapper>
            </DashboardItemHeader>
            <DashBoardCategoryWrapper>
              <DashboardButtonWrapper>
                <SettingButton
                  isOn={insightSettings.view}
                  onClick={() => toggleSetting("view", setInsightSettings)}
                  disabled={false}
                >
                  노출 수
                </SettingButton>
                <SettingButton
                  onClick={() => toggleSetting("click", setInsightSettings)}
                  isOn={insightSettings.click}
                  disabled={false}
                >
                  클릭 수
                </SettingButton>
              </DashboardButtonWrapper>
              <DashBoardInfoWrapper>
                <Caption>지난 30일 기준</Caption>
                <DashboardSubtitle>
                  총
                  {insightSettings.click && (
                    <Fragment>
                      <DashboardTitle>{totalClickCount.toLocaleString()}</DashboardTitle> 회
                    </Fragment>
                  )}
                  {insightSettings.view && (
                    <Fragment>
                      <DashboardTitle>{totalViewCount.toLocaleString()}</DashboardTitle>회
                    </Fragment>
                  )}
                </DashboardSubtitle>
              </DashBoardInfoWrapper>
            </DashBoardCategoryWrapper>
            <ResponsiveContainer width="100%" height={"78%"}>
              <LineChart
                width={506}
                height={160}
                data={randomData}
                margin={{
                  top: 32,
                  right: 0,
                  left: -34,
                  bottom: 29,
                }}
              >
                <CartesianGrid
                  strokeDasharray="0 0"
                  stroke={palette.neutral200}
                  vertical={false}
                  strokeWidth={1}
                />
                <XAxis
                  color={palette.neutral900}
                  fontWeight={10}
                  letterSpacing={-0.2}
                  fontSize={10}
                  tickLine={false}
                  tickMargin={4}
                  padding={{ left: 8, right: 8 }}
                  ticks={settings.price ? units : dates}
                  dataKey="name"
                  stroke={palette.neutral400}
                />
                <YAxis
                  fontSize={10}
                  color={palette.neutral500}
                  fontWeight={10}
                  letterSpacing={-0.2}
                  fontStyle={"normal"}
                  domain={[0, 300]}
                  ticks={[0, 50, 100, 150, 200, 250, 300]}
                  tickLine={false}
                  axisLine={false}
                />
                <Tooltip
                  cursor={false}
                  contentStyle={{
                    textAlign: "center",
                    padding: "6px 12px 4px 12px",
                    borderRadius: "4px",
                    boxShadow: `0 0 1px 0 ${palette.opacityB25}`,
                  }}
                  content={<GraphTooltip type={insightSettings.click ? "회" : "건"} />}
                />
              </LineChart>
            </ResponsiveContainer>
          </DashboardItemContainer>
        </ColumnContainer>
        <ColumnContainer>
          <DashboardItemContainer>
            <DashboardItemHeader>
              <DashboardItemTitleWrapper>
                <DashboardItemTitle>상품 관리</DashboardItemTitle>
              </DashboardItemTitleWrapper>
              <DashboardItemButtonWrapper gap={"6px"}>
                <DashboardItemButtonDesc>{DATA_refresh_time_product} 기준</DashboardItemButtonDesc>
                <DashboardItemButton
                  onClick={() => handleRefreshCurrentTime(["userProductList"], setCurrentData)}
                >
                  <RedoIcon size="14" color={palette.neutral600} />
                </DashboardItemButton>
              </DashboardItemButtonWrapper>
            </DashboardItemHeader>
            <DashboardLabelUl columns marginBottom="25px">
              {INFO_ARRAY.filter((item) => item.status > 0).map((item, index) => {
                return (
                  <DashboardLabelItem key={index}>
                    <DashboardLabelItemTitle>{item.statusName}</DashboardLabelItemTitle>
                    <DashboardLabelItemInfo>
                      <TitleContents>{getStoreProductManagementStatus(item.status)}</TitleContents>
                      건
                    </DashboardLabelItemInfo>
                  </DashboardLabelItem>
                );
              })}
            </DashboardLabelUl>
          </DashboardItemContainer>
          <DashboardItemContainer>
            <DashboardItemHeader>
              <DashboardItemTitleWrapper>
                <DashboardItemTitle>정산 관리</DashboardItemTitle>
              </DashboardItemTitleWrapper>
              <DashboardItemButtonWrapper gap={"6px"}>
                <DashboardItemButtonDesc>{DATA_refresh_time_payment} 기준</DashboardItemButtonDesc>
                <DashboardItemButton
                  onClick={() =>
                    handleRefreshCurrentTime(["dashboardProduct"], setRefreshTimePayment)
                  }
                >
                  <RedoIcon size="14" color={palette.neutral600} />
                </DashboardItemButton>
              </DashboardItemButtonWrapper>
            </DashboardItemHeader>
            <DashboardLabelUl columns={false} marginBottom="23px">
              <DashboardLabelItem className="full" width="100%" justify="space-between">
                <DashboardLabelItemTitle width="291px">구매 완료</DashboardLabelItemTitle>
                <DashboardLabelItemInfo>
                  <TitleContents>{paymentCompletedCount}</TitleContents>건
                </DashboardLabelItemInfo>
              </DashboardLabelItem>
              <DashboardLabelItem className="full" width="100%" justify="space-between">
                <DashboardLabelItemTitle width="291px">구매 확정</DashboardLabelItemTitle>
                <DashboardLabelItemInfo>
                  <TitleContents>{paymentConfirmationCount}</TitleContents>건
                </DashboardLabelItemInfo>
              </DashboardLabelItem>
              <DashboardLabelItem className="full" width="100%" justify="space-between">
                <DashboardLabelItemTitle width="291px">
                  다음달 1일 정산 금액
                </DashboardLabelItemTitle>
                <DashboardLabelItemInfo>
                  <TitleContents>{totalSettlementAmount.toLocaleString()}</TitleContents>원
                </DashboardLabelItemInfo>
              </DashboardLabelItem>
            </DashboardLabelUl>
          </DashboardItemContainer>

          <DashboardItemContainer className="small waiting">
            <DashboardItemHeader>
              <DashboardItemTitleWrapper>
                <DashboardItemTitle>알림</DashboardItemTitle>
              </DashboardItemTitleWrapper>
              <DashboardItemTextBtnWrapper>
                <DashboardItemTextBtn>더보기</DashboardItemTextBtn>
                <ArrowRightIcon size="12" color={palette.neutral400} />
              </DashboardItemTextBtnWrapper>
            </DashboardItemHeader>
            <DashBoardNoticeList>
              <DashboardNotice
                read={true}
                NoticeType={"정산"}
                detail="정산금액 520,00원이 24/4/16 17:40 신한은행계좌로 입금되었습니다. 정산관리 > 정산내역에서 확인"
              />
              <DashboardNotice
                read={true}
                detail="오전 3:00-5:00 서버 업데이트가 있을 예정입니다. 해당 시간 동안 서비스 이용이 일시적으로 중단됨을 안내드립니다. 양해 부탁드리며 빠른 시간 내로 정상 서비스가 가능하도록 노력하겠습니다."
                NoticeType={"공지"}
              />
              <DashboardNotice
                read={false}
                detail="24/12/20 오전 3:00-5:00 서버 업데이트가 있을 예정입니다. 해당 시간 동안 서비스 이용이 일시적으로 중단됨을 안내드립니다. 양해 부탁드리며 빠른 시간 내로 정상 서비스가 가능하도록 노력하겠습니다."
                NoticeType={"공지"}
              />
              <DashboardNotice
                read={false}
                detail="24/4/20 오전 3:00-5:00 서버 업데이트가 있을 예정입니다. 해당 시간 동안 서비스 이용이 일시적으로 중단됨을 안내드립니다. 양해 부탁드리며 빠른 시간 내로 정상 서비스가 가능하도록 노력하겠습니다."
                NoticeType={"공지"}
              />
              <DashboardNotice
                read={true}
                detail="24/4/20 오전 3:00-5:00 서버 업데이트가 있을 예정입니다. 해당 시간 동안 서비스 이용이 일시적으로 중단됨을 안내드립니다. 양해 부탁드리며 빠른 시간 내로 정상 서비스가 가능하도록 노력하겠습니다."
                NoticeType={"공지"}
              />
            </DashBoardNoticeList>
          </DashboardItemContainer>
        </ColumnContainer>
      </DashboardContainer>
    </GlobalContainer>
  );
};

const GraphContainer = styled(ResponsiveContainer)`
  border-radius: 16px;
  width: 100%;
  height: 100%;
  background-color: ${palette.white};

  &.noData {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &::after {
      ${titleText};
      color: ${palette.neutral600};
      content: "아직 데이터가 수집되지 않았습니다";
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      border-radius: 16px;
      backdrop-filter: blur(4px);
    }
  }
`;

const DashBoardNoticeList = styled.ul`
  height: calc(100% - 30px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;

const Caption = styled.div`
  color: ${palette.neutral500};
  ${caption};
`;

const Subtitle = styled.div`
  color: ${palette.neutral1000};
  ${subtitle14};
`;
const TitleContents = styled.div`
  color: ${palette.neutral800};
  ${titleContents};
`;

const DashboardLabelUl = styled.ul<{ columns: boolean; marginBottom: string }>`
  display: grid;
  ${(props) =>
    props.columns &&
    css`
      grid-template-columns: repeat(2, 1fr);
    `}
  row-gap: 5px;
  column-gap: 6px;
  margin-top: 10px;
  margin-bottom: ${(props) => props.marginBottom};
`;

const DashboardLabelItem = styled.li<{
  width?: string;
  justify?: string;
}>`
  list-style: none;
  width: ${(props) => props.width || "100%"};
  aspect-ratio: 250 / 42;
  /* padding: 10px; */
  border-radius: 4px;
  border: 1px solid ${palette.primary100};
  background-color: ${palette.primary20};
  display: flex;
  justify-content: ${(props) => props.justify || "space-evenly"};
  align-items: center;
  &.full {
    aspect-ratio: 506 / 42;
    padding: 10px 30px;
  }
`;
const DashboardLabelItemTitle = styled(Subtitle)<{ width?: string }>`
  color: ${palette.neutral800};
  display: flex;
  align-items: center;

  width: ${(props) => props.width || "70px"};
  position: relative;
  &::after {
    content: "|";
    position: absolute;
    display: block;
    color: ${palette.neutral800};
    right: 0;
  }
`;

const DashboardLabelItemInfo = styled(Subtitle)`
  color: ${palette.neutral800};
  display: flex;
  align-items: center;
  gap: 2px;
`;
const DashboardSubtitle = styled(Subtitle)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.small`
  color: ${palette.neutral1000};
  font-size: ${(props) => props.theme.typographyTheme.font.title.size};
  font-weight: ${(props) => props.theme.typographyTheme.font.title.weight};
  line-height: ${(props) => props.theme.typographyTheme.font.title.lineHeight};
`;

const DashboardTitle = styled(Title)`
  margin-right: 2px;
  margin-left: 8px;
`;

const DashBoardCategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DashBoardInfoWrapper = styled.div`
  text-align: end;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  height: calc(100vh - 100px);
`;

const DashboardButtonWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 12px;
`;
const ColumnContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const DashboardItemContainer = styled.div`
  border-radius: 16px;

  width: 100%;
  height: 100%;
  background-color: ${palette.white};
  padding: 16px 24px 0px 24px;
  position: relative;
  &.small {
    height: 30.5%;
  }

  &.waiting {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &::after {
      ${titleText};
      color: ${palette.neutral600};
      content: "준비 중입니다..";
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      border-radius: 16px;
      backdrop-filter: blur(4px);
    }
  }
`;
const DashboardItemHeader = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const DashboardItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
const DashboardItemTitle = styled.div`
  color: ${palette.neutral1000};
  ${titleContents};
`;
const DashboardItemTitleDesc = styled.div`
  color: ${palette.neutral500};
  ${caption02};
`;
const DashboardItemButtonWrapper = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? props.gap : "0px")};
  align-items: center;
`;
const DashboardItemButtonDesc = styled.div`
  color: ${palette.neutral500};
  ${caption};
`;
const DashboardItemButton = styled.div`
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${palette.neutral300};
  background: ${palette.neutral100};
  cursor: pointer;
`;
const DashboardItemTextBtnWrapper = styled.div`
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;
const DashboardItemTextBtn = styled.div`
  color: ${palette.neutral500};
  ${subtitle14};
`;

export default MainContainer;
