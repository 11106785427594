import Icon from "assets/icons/Icon";
import { body12 } from "components/style/body";
import { caption02 } from "components/style/caption";
import { titleText } from "components/style/title";
import { palette } from "modules/defines/styles";
import styled from "styled-components";

const GraphTooltip = ({ active, payload, label, type }: any) => {
  if (active) {
    const dateParts = label?.split(".");
    const year = new Date().getFullYear();

    const date = dateParts && new Date(year, dateParts[0] - 1, dateParts[1]);
    const formattedLabel = date?.toLocaleDateString("ko-KR", { month: "long", day: "numeric" });
    return (
      <div>
        <CustomTooltip className="custom-tooltip">
          <Label className="label">{formattedLabel}</Label>
          <Desc className="desc">
            {payload[0]?.value}
            <span>{type}</span>
          </Desc>
        </CustomTooltip>
        <CustomTooltipIcon icon={"arrowBubble"} fill={palette.neutral100} />
      </div>
    );
  }

  return null;
};

const CustomTooltip = styled.div`
  display: flex;
  padding: 6px 12px 4px 12px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: ${palette.neutral100};
  box-shadow: 0 0 1px 0 ${palette.opacityB25};
  position: relative;
`;
const Label = styled.p`
  color: ${palette.neutral700};
  ${body12}
`;

const CustomTooltipIcon = styled(Icon)`
  z-index: -1;
  position: absolute;
`;

const Desc = styled.p`
  ${titleText}
  display: flex;
  gap: 2px;
  align-items: center;
  color: ${palette.primary500};
  & > span {
    ${caption02};
    color: ${palette.neutral1000};
  }
`;

export default GraphTooltip;
