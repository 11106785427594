import { Player } from "@lottiefiles/react-lottie-player";
import { userAPI } from "api";
import { base64ToBytes, decodeFromBase64 } from "modules/defines/encrypt";
import { palette } from "modules/defines/styles";
import spinner_blue from "assets/lottie/spinner_blue.json";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import spinner from "assets/lottie/spinner.json";
import styled from "styled-components";
import { Button, TextButton } from "components/style/button";
import { bodyText16 } from "components/style/body";
import { titleContents, titlePage } from "components/style/title";
import { subTitle, subtitle14 } from "components/style/subtitle";
import { set } from "react-hook-form";
import { useHistory } from "react-router-dom";

interface OrderInfoProps {
  isTotal: boolean;
  orderId: string; // DB에서 사용하는 orderId
  orderIdNumber: string;
  orderName: string;
  orderNumber: string;
  spaceTitle: string;
  expireAt: string;
  option: {
    idx: number;
    price: number;
    title: string;
  } | null;
}

// 일괄 사용을 위해 API에서 받아온 데이터
interface OrderDataDetail {
  // orderId: string;
  // orderIdNumber: string;
  // orderName: string;
  orderNumber: string;
  option: {
    idx: number;
    price: number;
    title: string;
  };
}

const UseApprovalContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const orderInfo = queryParams.get("orderInfo");
  const approvalCoderRegex = /^[0-9]{0,4}$/;
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isApprovalLoading, setIsApprovalLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [wrongError, setWrongError] = useState(false);
  const [standardError, setStandardError] = useState(false);
  const [quickButton, setQuickButton] = useState(false);

  const [orderDetailInfos, setOrderDetailInfos] = useState<
    OrderDataDetail[] | null
  >(null);
  const [clusterText, setClusterText] = useState<string>("");

  let orderInfoJson: OrderInfoProps | null = null;

  try {
    orderInfoJson = orderInfo && JSON.parse(decodeFromBase64(orderInfo));
  } catch (e) {
    console.error("Failed to parse orderInfo:", orderInfo, e);
  }
  const [approvalCode, setApprovalCode] = useState<string>("");
  const onChangeApprovalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (approvalCoderRegex.test(e.target.value)) {
      setApprovalCode(e.target.value);
    }
  };

  const divRef = useRef<HTMLDivElement>(null);
  const [isWrongOrder, setIsWrongOrder] = useState(false);

  const getUnusedOrderInfo = async (
    oid: string,
    haveOption: boolean,
    orderName: string
  ) => {
    await userAPI.getUnusedOrders({ oid }).then((res) => {
      if (res.status === 200) {
        setOrderDetailInfos(res.data.orders);

        if (haveOption) {
          const groupedOrders: { [key: string]: OrderDataDetail[] } = {};

          res.data.orders.forEach((order: OrderDataDetail) => {
            const optionTitle = order.option?.title;
            if (groupedOrders[optionTitle]) {
              groupedOrders[optionTitle].push(order);
            } else {
              groupedOrders[optionTitle] = [order];
            }
          });
          var clusterTextStr = "";
          Object.keys(groupedOrders).forEach((key) => {
            clusterTextStr += `${key}(${groupedOrders[key].length}) `;
          });
          setClusterText(clusterTextStr);
        } else {
          setClusterText(`${orderName}(${res.data.orders.length})`);
        }
      }
    });
  };

  useEffect(() => {
    if (!orderInfo) {
      alert("잘못된 접근입니다.");
      setIsWrongOrder(true);
    }
    // 일괄 사용의 경우 API를 호출합니다.
    if (orderInfoJson) {
      console.log(orderInfoJson);
      if (orderInfoJson.isTotal) {
        getUnusedOrderInfo(
          orderInfoJson.orderId,
          orderInfoJson.option !== null,
          orderInfoJson.orderName
        );
      } else {
        setIsDataLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (orderDetailInfos) {
      if (orderDetailInfos.length === 0) {
        setIsWrongOrder(true);
      }
      setIsDataLoading(false);
    }
  }, [orderDetailInfos]);

  const useApprovalHandler = () => {
    setIsApprovalLoading(true);
    if (!approvalCode) {
      alert("사용승인코드를 입력해주세요.");
      setIsApprovalLoading(false);
      return;
    } else {
      userAPI
        .useApproval({
          orderNumber: orderInfoJson && orderInfoJson.orderNumber,
          orderId: orderInfoJson && orderInfoJson.orderId,
          approvalCode: approvalCode,
          isTotal: orderInfoJson && orderInfoJson.isTotal,
        })
        .then((res) => {
          if (
            res.data.error === "WRONG_CODE" ||
            res.data.error === "APPROVAL_CODE_UNDEFINED" ||
            res.data.error === "ALREADY_USED"
          ) {
            alert(res.data.message);
          } else if (res.data.error === "BODY_UNDEFINED") {
            alert("잘못된 접근입니다. QR 코드를 다시 스캔해주세요");
            // window.open("", "_self")?.close();

            window.close();
          } else if (res.data.message === "APPROVAL_COMPLETE") {
            history.push({
              pathname: "/state",
              state: {
                type: "success",
              },
            });
          } else if (res.data.error === "INTERNAL_SERVER_ERROR") {
            alert("서버 오류입니다. 잠시 후에 다시 시도해주세요");
            setStandardError(true);
            // window.close();
          } else if (res.data.error === "WRONG_CODE") {
            inputRef.current?.focus();
            inputRef.current?.style.setProperty("border", "1px solid #e31d1c");
            setStandardError(true);
            setWrongError(true);
          } else {
            alert("잘못된 접근입니다. QR 코드를 다시 스캔해주세요");
            window.close();
          }
        })
        .catch((err) => {
          alert("서버 오류입니다. 잠시 후에 다시 시도해주세요");
          setStandardError(true);
          inputRef.current?.focus();
        })
        .finally(() => {
          setIsApprovalLoading(false);
        });
    }
  };

  return (
    <Container ref={divRef}>
      <HeaderContainer>
        <Title>관리자 사용 승인</Title>
      </HeaderContainer>
      {!isDataLoading ? (
        <>
          {!isWrongOrder && orderInfoJson ? (
            <>
              <Description>
                <ul>
                  <ApprovalListItemTitle className="brand">
                    {orderInfoJson.spaceTitle}
                  </ApprovalListItemTitle>
                  <ApprovalListItemTitle className="product">
                    {orderInfoJson.orderName}
                  </ApprovalListItemTitle>
                  <ApprovalListItem>
                    <ApprovalListItemName> 주문 ID</ApprovalListItemName>
                    <ApprovalListItemDescription>
                      {orderInfoJson.orderIdNumber}
                    </ApprovalListItemDescription>
                  </ApprovalListItem>
                  {orderInfoJson.isTotal ? (
                    <>
                      <ApprovalListItem>
                        <ApprovalListItemName>옵션</ApprovalListItemName>
                        <ApprovalListItemDescription>
                          {clusterText}
                        </ApprovalListItemDescription>
                      </ApprovalListItem>
                      <ApprovalListItem>
                        <ApprovalListItemName>수량</ApprovalListItemName>
                        <ApprovalListItemDescription>
                          {orderDetailInfos && orderDetailInfos.length}개
                        </ApprovalListItemDescription>
                      </ApprovalListItem>
                    </>
                  ) : (
                    <>
                      <ApprovalListItem>
                        <ApprovalListItemName>옵션</ApprovalListItemName>
                        <ApprovalListItemDescription>
                          {orderInfoJson.option
                            ? orderInfoJson.option.title
                            : orderInfoJson.orderName}{" "}
                          (1)
                        </ApprovalListItemDescription>
                      </ApprovalListItem>
                      <ApprovalListItem>
                        <ApprovalListItemName>수량</ApprovalListItemName>
                        <ApprovalListItemDescription>
                          1개
                        </ApprovalListItemDescription>
                      </ApprovalListItem>
                    </>
                  )}

                  <ApprovalListItem>
                    <ApprovalListItemName>유효 기간</ApprovalListItemName>
                    <ApprovalListItemDescription className="deadLine">
                      {orderInfoJson.expireAt ? (
                        <>
                          {new Date(orderInfoJson.expireAt).toLocaleDateString(
                            "ko-KR",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                          까지
                        </>
                      ) : (
                        <>없음</>
                      )}
                    </ApprovalListItemDescription>
                  </ApprovalListItem>
                </ul>
                <ApprovalCodeInput
                  ref={inputRef}
                  inputMode="decimal"
                  placeholder="매장 코드를 입력해주세요"
                  type="number"
                  value={approvalCode}
                  onChange={onChangeApprovalCode}
                  onBlur={() => {
                    if (!standardError) setQuickButton(false);

                    if (approvalCode.length >= 4) {
                      setQuickButton(true);
                    } else {
                      setQuickButton(false);
                    }
                  }}
                  onFocus={() => {
                    if (inputRef.current)
                      window.scrollTo(0, inputRef.current.offsetTop);
                    setQuickButton(true);
                  }}
                />
                {wrongError && (
                  <Error>입력하신 코드를 다시 확인해 주세요.</Error>
                )}
                {quickButton && (
                  <ButtonContainer narrow>
                    <ApprovalButton
                      disabled={approvalCode.length < 4}
                      type="button"
                      onClick={useApprovalHandler}
                    >
                      사용 승인
                    </ApprovalButton>
                  </ButtonContainer>
                )}
                <Divider />
                <Em>유의 사항</Em>
                <Notice>
                  {
                    "매장에 할당된 인증 코드를 입력하여 사용을 승인해 주세요. 사용 승인 취소는 <스토어 관리자> PC 환경에서 가능합니다."
                  }
                </Notice>
                <Divider />
                <Em>상세 내역</Em>
                {orderInfoJson.option ? (
                  <>
                    {orderDetailInfos?.map((order, idx) => {
                      return (
                        <OptionListItem>
                          <Layout>
                            <OptionListItemTitle>
                              옵션 {order.option.idx}
                            </OptionListItemTitle>
                            |
                            <OptionListItemTitle>
                              {order.option.title}
                            </OptionListItemTitle>
                          </Layout>
                          <OptionListItemNumber>
                            상품번호 {order.orderNumber}
                          </OptionListItemNumber>
                        </OptionListItem>
                      );
                    })}
                  </>
                ) : (
                  <OptionListItem>
                    <Layout>
                      <OptionListItemTitle>1</OptionListItemTitle>|
                      <OptionListItemTitle>
                        {orderInfoJson.orderName}
                      </OptionListItemTitle>
                    </Layout>
                    <OptionListItemNumber>
                      상품번호 {orderInfoJson.orderNumber}
                    </OptionListItemNumber>
                  </OptionListItem>
                )}
              </Description>
              {!quickButton && (
                <ButtonContainer narrow={false}>
                  <ApprovalButton
                    disabled={approvalCode.length < 4}
                    type="button"
                    onClick={useApprovalHandler}
                  >
                    사용 승인
                  </ApprovalButton>
                </ButtonContainer>
              )}
            </>
          ) : (
            <WrongOrderLayout>
              잘못된 접근입니다
              <br />
              QR코드를 다시 확인해주세요
            </WrongOrderLayout>
          )}
        </>
      ) : (
        <LoadingContainer>
          <Player
            style={{
              width: "32x",
              height: "32px",
            }}
            src={spinner_blue}
            autoplay
            loop
          />
        </LoadingContainer>
      )}
    </Container>
  );
};

const Error = styled.span`
  margin-top: 4px;
  ${TextButton};
  color: #e31d1c;
`;

const Layout = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  ${subtitle14};
  color: #d9d9d9;
  margin-bottom: 8px;
`;

const ApprovalListItem = styled.li`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`;

const ApprovalListItemName = styled.div`
  min-width: 60px;
  ${TextButton};
  font-weight: 700;
  color: #000;
`;

const ApprovalListItemDescription = styled.div`
  ${TextButton};
  color: #000;
  &.deadLine {
    color: ${palette.primary500};
  }
`;
const ApprovalListItemTitle = styled(ApprovalListItem)`
  color: ${palette.neutral700};
  &.brand {
    ${subTitle};
    margin-bottom: 0;
  }
  &.product {
    width: 95%;
    word-break: break-all;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px; /* 120% */
    margin-bottom: 20px;
  }
`;

const Em = styled.em`
  width: 100%;
  display: block;
  color: ${palette.neutral700};
  ${titlePage};
  margin-bottom: 15px;
`;

const Divider = styled.hr`
  margin: 30px 0;
  background-color: ${palette.neutral500};
`;

const Notice = styled.p`
  color: ${palette.neutral500};
  ${bodyText16};
`;

const Container = styled.div`
  padding: 0 24px;
  margin: 0 auto;
  max-width: 40rem;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${palette.white};
  box-shadow: 0 0 20px 0 #e6e6e6;
  padding-bottom: 80px;
`;

const HeaderContainer = styled.div`
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const Title = styled.div`
  color: ${palette.neutral1000};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  width: 100%;
  &::after {
    display: block;
    content: "";
    margin-top: 16px;
    background-color: ${palette.neutral500};
    height: 1px;
  }
`;

const Description = styled.div`
  margin-top: 24px;
  color: ${palette.neutral500};
  ${TextButton};
`;

const ApprovalCodeInput = styled.input`
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  border: 1px solid #c1c0c9;
  border-radius: 20px;
  color: ${palette.neutral1000};
  box-sizing: border-box;
  background-color: ${palette.white};
  outline: none;
  ${TextButton};
  &.error {
    border: 1px solid #e31d1c;
  }

  ::placeholder {
    color: #979797;
  }
  :focus {
    border: 1px solid ${palette.primary500};
  }

  -webkit-outer-spin-button,
  -webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const OptionListItem = styled.div`
  padding: 16px 16px 14px 16px;
  border-radius: 8px;
  background-color: #efefef;
  margin-top: 5px;
`;

const OptionListItemTitle = styled.div`
  ${Button};
  color: #1e1e1e;
`;

const OptionListItemNumber = styled.div`
  ${TextButton};
  color: #717171;
`;

const ButtonContainer = styled.div<{ narrow: boolean }>`
  width: 100%;
  margin-bottom: 24px;
  margin-top: ${(props) => (props.narrow ? "16px" : "50px")};
`;

const ApprovalButton = styled.button`
  all: unset;
  background-color: ${palette.primary500};
  color: ${palette.white};
  border-radius: 8px;
  ${Button};
  text-align: center;
  padding: 16px 0;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  :active {
    background-color: ${palette.primary600};
  }
  :disabled {
    pointer-events: none;
    background-color: #f4f4f4;
    color: ${palette.secondary002};
  }
`;

const WrongOrderLayout = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px; /* 120% */
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${palette.secondary400};
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 20px;
`;

export default UseApprovalContainer;
