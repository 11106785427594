/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export const useVerificationCode = () => {
  const [code, setCode] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const shakeCode = () => {
    return 0.5 - Math.random();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      let randomString = "";
      for (let index = 0; index < 4; index++) {
        randomString += Math.floor(Math.random() * 10);
      }

      for (let index = 0; index < 4; index++) {
        randomString += String.fromCharCode(97 + Math.floor(Math.random() * 26));
      }
      randomString = randomString.split("").sort(shakeCode).join("");
      setCode(randomString);
      if (!isAuthenticating) {
        setAuthCode(randomString);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isAuthenticating]);

  return { authCode, setIsAuthenticating };
};
