export const pagePaths = {
  // sign
  signIn: "/signin",
  signUp: "/signup",

  // main
  main: "/",

  // product manage
  product: "/product",
  productDetail: "/product/:id",
  productRegister: "/product/register",

  // payment manage
  payment: "/payment",

  //setting
  setting: "/setting",

  // 매니저 사용승인 페이지 (QR 스캔 하면 나오는 인증 페이지)
  useApproval: "/use-approval",

  //상태 관련 페이지(접근 불가, 승인 성공 등)
  state: "/state",
};

export const apiPaths = {
  ///////////////////////////////////////////////////////// AWS 클라우드 API
  awsBaseUrl: "https://path.favapp.io/",
  awsDevUrl: "https://apidev.pkbroz.com/",
  awsLocalUrl: "http://localhost:5030/",

  //dashboard
  getDashboardManager: "store/dashboard/manager",
  getDashboardProduct: "store/dashboard/product",
  getDashboardMonthInfo: "store/dashboard/dashboard",

  signUp: "auth/store/signup",
  signIn: "auth/store/signin",
  signOut: "auth/store/sign-out",
  checkUser: "auth/store/check-manager",
  sendCode: "auth/store/send-verification-code",
  resetPassword: "auth/store/reset-password",

  // product
  productList: "store/product/list-user/",
  productDetail: "store/product/detail/",
  registerProduct: "store/product/register/",
  editProduct: "store/product/edit/",
  updateStatusProduct: "store/product/update-status/",
  deleteProduct: "store/product/delete/",
  // product / image
  uploadImage: "store/product/img/upload/",
  deleteImage: "store/product/img/delete/",
  // product / order
  getProductOrderList: "store/product/order/list/",
  setUsageOrder: "store/product/order/usage-confirm/",

  // payment
  getPaymentInfo: "store/payment/info/",

  //space
  getSpace: "store/space/",
  registerSpace: "store/space/register/",
  uploadRegisterSpaceImage: "store/space/img/upload/",
  requestSpaceEdit: "store/space/request-edit",

  // user
  useApproval: "store/user/use-approval/",
  getUnusedOrders: "store/user/get-unused-orders/",

  // manager
  renewalApprovalCode: "store/manager/renewal-approval-code/",
  registerAccount: "store/manager/register-account/",
};
