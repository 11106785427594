import { useQueryClient } from "@tanstack/react-query";
import { managerAPI } from "api";
import Icon from "assets/icons/Icon";
import { useCookies } from "react-cookie";
import {
  ContentsContainer,
  PageTitle,
  TopWrapper,
} from "components/GlobalStyles";
import TableInput from "components/common/layout/TableInput";
import ModalFAVEditRequest from "components/modal/ModalFAVEditRequest";
import { body12 } from "components/style/body";
import { subtitle14 } from "components/style/subtitle";
import { titleContents, titleText } from "components/style/title";
import BankList from "components/ui/BankList/BankList";
import GlobalContainer from "containers/GlobalContainer";
import { ModalContext, ModalContextType } from "modules/context/ModalContext";
import { bankList, categoryEng } from "modules/defines/define";
import { palette } from "modules/defines/styles";
import { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import makeAccountDash from "utils/makeAccountDash";
import useInput from "utils/useInput";

const SettingContainer: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const handleIsValid = (value: string) => {
    const isValid = /^[0-9-]*$/.test(value);
    if (!isValid) {
      alert("숫자와 '-'만 입력 가능합니다.");
      return false;
    }
  };
  const [accountNameKor, setAcceptNameKor] = useState<string>("은행 선택");
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [checked, setChecked] = useState(categoryEng.map(() => false));
  const [editBankCount, setEditBankCount] = useState<boolean>(false);
  const [accountNumber, handleAccountNumberChange] = useInput(
    "",
    handleIsValid
  );
  const [accountUserName, handleAccountUserNameChange] = useInput("");
  const [accountName, handleAccountNameChange] = useInput("은행 선택");
  const [bankListOpen, setBackListOpen] = useState(false);
  const { openModal } = useContext(ModalContext) as ModalContextType;
  // useEffect(() => {
  //   console.log(accountNumber);
  // }, [accountNumber]);
  const waitingAlert = () => {
    if (cookies.isAdmin === true) {
      return openModal(
        <ModalFAVEditRequest
          spaceId={spaceData.id}
          managerName={managerData.managerName}
          managerContact={managerData.phone}
        />
      );
    } else {
      alert("관리자 권한이 필요합니다.");
    }
  };
  const queryClient = useQueryClient();

  const getDashboardManagerQuery = queryClient.getQueryData([
    "dashboardManager",
  ]) as any;

  const managerData = getDashboardManagerQuery?.data.data.manager;
  const spaceData = getDashboardManagerQuery?.data.data.space;

  const maskingName = (name: string) => {
    const makeNameArray = name.split("");
    if (makeNameArray.length % 2 === 1) {
      makeNameArray[Math.floor(makeNameArray.length / 2)] = "*";
    } else {
      makeNameArray[Math.floor(makeNameArray.length / 2)] = "*";
      makeNameArray[Math.floor(makeNameArray.length / 2) - 1] = "*";
    }
    return makeNameArray.join("");
  };

  const hyphenPhoneNumber = (phone: string) => {
    const phoneArray = phone.split("");
    phoneArray.splice(3, 0, "-");
    phoneArray.splice(8, 0, "-");
    return phoneArray.join("");
  };

  const maskPhoneNumber = (phone: string) => {
    const phoneArray = phone.split("");
    phoneArray.splice(6, 2, "*", "*");
    phoneArray.splice(11, 2, "*", "*");
    return phoneArray.join("");
  };

  const maskEmail = (email: string) => {
    const emailArray = email.split("@");

    for (let i = 2; i < emailArray[0].length; i++) {
      emailArray[0] = emailArray[0].replace(emailArray[0][i], "*");
    }
    return emailArray[0] + "@" + emailArray[1];
  };

  useEffect(() => {
    if (spaceData === undefined) return;
    const newChecked = [...checked];
    spaceData?.category?.split("/").map((category: any) => {
      const index = parseInt(category);
      newChecked[index] = true;
      setChecked(newChecked);
    });
  }, []);

  const ApprovalCodeRenewalHandler = async () => {
    if (cookies.isAdmin === true) {
      if (
        window.confirm(
          "승인코드를 재설정하시겠습니까?\n재설정 시 기존 승인코드는 무효화됩니다."
        )
      ) {
        const res = await managerAPI.renewalApprovalCode({
          id: managerData.id,
        });
        if (res.status === 200) {
          alert("승인코드가 재설정되었습니다.");
          window.location.reload();
        } else {
          alert("승인코드가 재설정 중 오류가 발생하였습니다.");
        }
      }
    } else {
      alert("관리자 권한이 필요합니다.");
    }
  };

  const handleRegisterAccount = async () => {
    await managerAPI
      .registerAccount({
        id: managerData.id,
        bank: accountName,
        account: accountNumber,
        holder: accountUserName,
      })
      .then((res) => {
        if (res.status === 200) {
          alert("계좌번호가 등록되었습니다.");
          //  setEditBankCount(false);
          window.location.reload();
        }
      });
  };
  return (
    <GlobalContainer>
      <ContentsContainer>
        <TopWrapper>
          <PageTitle>설정</PageTitle>
        </TopWrapper>
        <Section marginTop="12px">
          <Wrapper>
            <SettingCategoryWrapper>
              <SettingCategory
                isActive={tabIndex === 0}
                onClick={() => setTabIndex(0)}
              >
                관리자 정보
              </SettingCategory>
              |
              <SettingCategory
                isActive={tabIndex === 1}
                onClick={() => setTabIndex(1)}
              >
                공간 정보
              </SettingCategory>
            </SettingCategoryWrapper>
            <EditButton
              isShow={managerData && tabIndex === 1}
              className="bold16"
              onClick={waitingAlert}
              type="button"
            >
              정보 수정
            </EditButton>
          </Wrapper>

          {managerData && tabIndex === 0 && (
            <>
              <ListTitleWrapper>
                <ListTitle>기본 정보</ListTitle>
                {/* <EditButton onClick={waitingAlert} isShow type="button">
                  수정
                </EditButton> */}
              </ListTitleWrapper>
              <ListWrapper marginBottom="32px">
                <List>
                  <ListName>이름</ListName>
                  <ListValue>
                    {managerData.managerName === null
                      ? "이름 정보 없음"
                      : maskingName(managerData.managerName)}
                  </ListValue>
                </List>
                <List>
                  <ListName>휴대폰 번호</ListName>
                  <ListValue>
                    {managerData.phone === null
                      ? "휴대폰 번호 정보 없음"
                      : maskPhoneNumber(hyphenPhoneNumber(managerData.phone))}
                  </ListValue>
                </List>
                <SettingButton
                  onClick={() => {
                    if (cookies.isAdmin === true) {
                      setEditBankCount(true);

                      if (editBankCount) {
                        if (window.confirm("계좌번호를 등록하시겠습니까?")) {
                          handleRegisterAccount();
                        }
                      }
                    } else {
                      alert("관리자 권한이 필요합니다.");
                    }
                  }}
                  type="button"
                >
                  {editBankCount ? "등록 완료" : "계좌번호 등록"}
                </SettingButton>
                <List>
                  <ListName>계좌정보</ListName>
                  {editBankCount ? (
                    <Fragment>
                      <TableInput
                        value={accountUserName}
                        onChange={handleAccountUserNameChange}
                        placeholder="예금주 입력"
                      />
                      <SelectBox>
                        <div onClick={() => setBackListOpen(true)}>
                          {accountNameKor}
                          <Icon icon="arrowDown" fill={palette.neutral600} />
                        </div>

                        {bankListOpen ? (
                          <BankList
                            onComplete={() => {
                              setBackListOpen(false);
                              setAcceptNameKor(
                                bankList.find(
                                  (name) => accountName === name.code
                                )?.name || "은행 선택"
                              );
                            }}
                            setOpen={setBackListOpen}
                            onChange={handleAccountNameChange}
                          />
                        ) : null}
                      </SelectBox>
                      <TableInput
                        value={accountNumber}
                        onChange={handleAccountNumberChange}
                        placeholder="계좌번호 입력 ('-' 제외하고 입력해주세요) (사업자 통장으로 등록바랍니다)"
                        flexGrow={1}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {managerData.bank !== null ? (
                        <ListValue className="bold" borderRight>
                          {
                            bankList.find(
                              (name) => managerData.bank === name.code
                            )?.name
                          }
                        </ListValue>
                      ) : null}
                      <ListValue isAccount={managerData.account === null}>
                        {managerData.account === null
                          ? "등록하신 계좌번호가 없습니다."
                          : managerData.account}
                      </ListValue>
                    </Fragment>
                  )}
                </List>
                <List>
                  <ListName>승인코드</ListName>
                  <ListValue className="bold" borderRight>
                    {managerData.approvalCode === null
                      ? "-"
                      : managerData.approvalCode}
                  </ListValue>
                  <ApprovalCodeRenewalButton
                    onClick={ApprovalCodeRenewalHandler}
                  >
                    재설정
                  </ApprovalCodeRenewalButton>
                  <ApprovalCodeDesc>
                    승인 코드는 구매자의 사용승인을 위한 코드로, 결제 담당자분이
                    기억하고 계셔야 합니다.
                  </ApprovalCodeDesc>
                </List>
              </ListWrapper>
              <ListTitleWrapper>
                <ListTitle>계정 관리</ListTitle>
              </ListTitleWrapper>
              <ListWrapper>
                <List>
                  <ListName>이메일(아이디)</ListName>
                  <ListValue>{maskEmail(managerData.email)}</ListValue>
                </List>
                <SettingButton
                  onClick={() =>
                    alert("관리자에게 문의 바랍니다.\ncs@broz.co.kr")
                  }
                  type="button"
                >
                  비밀번호 변경
                </SettingButton>
                <List className="noneBorder">
                  <ListName>비밀번호</ListName>
                  <ListValue>*********</ListValue>
                </List>
                <List>
                  <ListName>&nbsp;&nbsp;</ListName>
                  <ListValue className="full" borderTop>
                    비밀번호는 주기적으로 변경하여 개인정보를 안전하게
                    보호하세요.
                  </ListValue>
                </List>
                <SettingButton
                  onClick={() =>
                    alert("관리자에게 문의 바랍니다.\ncs@broz.co.kr")
                  }
                  className="outline"
                  type="button"
                >
                  탈퇴하기
                </SettingButton>
                <List>
                  <ListName>계정 탈퇴</ListName>
                  <ListValue>
                    계정을 탈퇴하면 해당 계정과 관련된 모든 데이터가 7일 후
                    완전히 삭제됩니다.
                  </ListValue>
                </List>
              </ListWrapper>
            </>
          )}
          {managerData && tabIndex === 1 && (
            <>
              <ListWrapper marginBottom="32px">
                <List>
                  <ListName>상호명</ListName>
                  <SpaceListValue className="full">
                    {spaceData.title}
                  </SpaceListValue>
                </List>
                <List>
                  <ListName>카테고리 선택</ListName>
                  <SpaceListValue className="full">
                    {categoryEng.map((category, index) => {
                      return (
                        <ImitationCheckbox
                          key={category}
                          onClick={(prev) => {
                            const newChecked = [...checked];
                            newChecked[index] = !newChecked[index];
                            setChecked(newChecked);
                          }}
                        >
                          <Icon
                            icon={checked[index] ? "checked" : "noChecked"}
                            fill={
                              checked[index]
                                ? palette.primary500
                                : palette.neutral400
                            }
                          />
                          <Checkbox
                            onChange={(prev) => {
                              const newChecked = [...checked];
                              newChecked[index] = !newChecked[index];
                              setChecked(newChecked);
                            }}
                            checked={checked[index]}
                            type="checkbox"
                            name={category}
                            id={category}
                          />
                          <CheckboxLabel
                            onClick={(prev) => {
                              const newChecked = [...checked];
                              newChecked[index] = !newChecked[index];
                              setChecked(newChecked);
                            }}
                            htmlFor={category}
                          >
                            {category}
                          </CheckboxLabel>
                        </ImitationCheckbox>
                      );
                    })}
                  </SpaceListValue>
                </List>
                <List>
                  <ListName>공간 주소</ListName>
                  <SpaceListValue className="full">
                    {spaceData.address}
                  </SpaceListValue>
                </List>
                <List>
                  <ListName>공간 설명</ListName>
                  <SpaceListValue className="full">
                    {spaceData.description}
                  </SpaceListValue>
                </List>
              </ListWrapper>
              <ImageWrapper>
                대표 이미지
                <ThumbnailImage
                  width={120}
                  height={120}
                  src={spaceData.img.replace(
                    /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/,
                    "_500.webp"
                  )}
                  alt="대표 이미지"
                />
              </ImageWrapper>
            </>
          )}
        </Section>
      </ContentsContainer>
    </GlobalContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Checkbox = styled.input`
  appearance: none;
  pointer-events: none;
`;

const ImitationCheckbox = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;
`;

const CheckboxLabel = styled.label`
  ${body12};
  color: #000;
  pointer-events: none;
`;

const SelectBox = styled(Wrapper)`
  cursor: pointer;
  position: relative;
  width: 160px;

  box-shadow: 0 0 0 1px ${palette.neutral300} inset;
  background-color: white;
  & > div:first-of-type {
    ${subtitle14};
    color: ${palette.neutral800};
    width: 100%;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ${palette.neutral1000};
  ${titleText};
`;

const ThumbnailImage = styled.img`
  border-radius: 8px;
  border: 1px solid ${palette.neutral300};
`;

const Section = styled.section<{ marginTop?: string }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  width: 100%;

  background-color: ${palette.white};
  padding: 24px 16px 152px 16px;
  border-radius: 16px;
`;

const SettingCategoryWrapper = styled.ul`
  color: ${palette.neutral300};
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
`;

const SettingCategory = styled.li<{ isActive?: boolean }>`
  padding: 1px 2px;
  ${titleContents};
  color: ${(props) =>
    props.isActive ? palette.neutral1000 : palette.neutral400};
  cursor: pointer;
`;
const ListTitleWrapper = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;
const ListTitle = styled.div`
  color: ${palette.neutral1000};
  ${titleText};
`;

const EditButton = styled.button<{ isShow?: boolean }>`
  display: ${(props) => (props.isShow ? "block" : "none")};
  ${subtitle14};
  color: white;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: ${palette.neutral500};
  &:hover {
    border-radius: 8px;
    background-color: ${palette.neutral600};
  }

  &:disabled {
    border-radius: 8px;
    color: ${palette.neutral400};
    background-color: ${palette.neutral300};
  }

  &.bold16 {
    color: white;
    background-color: ${palette.neutral500};
    border-radius: 8px;
    ${titleContents};
    padding: 6px 10px;
    justify-content: right;
    margin-bottom: 10px;
  }
`;

const SettingButton = styled.button`
  ${titleText};
  width: 120px;
  color: white;
  float: right;
  background-color: ${palette.primary700};
  padding: 10px 8px;

  &:hover,
  &:focus {
    background-color: ${palette.primary800};
  }

  &.outline {
    ${subtitle14};
    color: ${palette.neutral500};
    box-shadow: 0 0 0 1px ${palette.neutral300} inset;
    background-color: ${palette.white};
    &:hover,
    &:focus {
      color: ${palette.neutral300};
      background-color: ${palette.neutral500};
    }
  }
`;

const ListWrapper = styled.ul<{ marginBottom?: string }>`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};
  border: 1px solid ${palette.neutral300};
`;
const List = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${palette.neutral300};
  &:last-of-type {
    border-bottom: none;
  }
  &.noneBorder {
    border-bottom: none;
  }
`;

const ListName = styled.div`
  ${titleText};
  min-width: 120px;
  border-right: 1px solid ${palette.neutral300};
  background-color: ${palette.neutral200};
  color: ${palette.neutral1000};
  padding: 10px 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ListValue = styled.div<{
  borderRight?: boolean;
  borderTop?: boolean;
  isAccount?: boolean;
}>`
  ${subtitle14};
  min-width: 120px;
  max-width: 100%;
  background-color: ${palette.white};
  color: ${(props) =>
    props.isAccount ? palette.neutral400 : palette.neutral1000};
  padding: 10px 16px;
  height: 40px;
  border-right: ${(props) =>
    props.borderRight ? `1px solid ${palette.neutral300}` : "none"};
  border-top: ${(props) =>
    props.borderTop ? `1px solid ${palette.neutral300}` : "none"};
  &.full {
    width: 100%;
  }
  &.bold {
    ${titleText};
    text-align: center;
  }
`;
const ApprovalCodeRenewalButton = styled.button`
  all: unset;
  cursor: pointer;
  background-color: ${palette.neutral200};
  color: ${palette.neutral1000};
  padding: 6px 10px;
  border-radius: 8px;
  margin-left: 10px;
  &:hover {
    background-color: ${palette.neutral300};
  }
  font-size: 12px;
  font-weight: 700;
  font-family: Pretendard;
  line-height: 18px;
`;

const ApprovalCodeDesc = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: ${palette.neutral400};
  margin-left: 10px;
  width: 200px;
  line-height: 14px;
`;

const SpaceListValue = styled(ListValue)`
  background-color: ${palette.neutral100};
  display: flex;
  align-items: center;
  gap: 16px;
`;
export default SettingContainer;

// export const Th = styled.th`
//   ${titleText};
//   border: 1px solid ${palette.neutral300};
//   background-color: ${palette.neutral200};
//   color: ${palette.neutral1000};
//   padding: 10px 8px;
// `;

// export const Td = styled.td`
//   text-align: center;
//   border: 1px solid ${palette.neutral300};
//   background-color: ${palette.white};
//   ${subtitle14};
//   color: ${palette.neutral1000};
//   /* padding: 10px 8px; */
//   height: 40px;
// `;
