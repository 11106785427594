const emailValidation = /\S+@\S+\.\S+/;
const passwordValidation =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d~!@#$%^&*()_+{}":;'?/<>,.`\-\\|=]{8,30}$/;

const phoneValidation =
  /^((02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4})|(01[016789][1-9]{1}[0-9]{2,3}[0-9]{4}|(050[0-9]{8,12}))$/;

const mobileValidation = /^01[016789][1-9]{1}[0-9]{2,3}[0-9]{4}$/;
const numberValidation = /^[0-9]{6}$/;

export const ruleGroup = {
  email: {
    required: "이메일은 필수 입력입니다.",
    pattern: {
      value: emailValidation,
      message: "이메일을 다시 확인해주세요.",
    },
  },

  password: {
    required: "비밀번호는 필수 입력입니다.",
    pattern: {
      value: passwordValidation,
      message: "8-30자 영문, 숫자, 특수문자 조합만 가능합니다.",
    },
  },
  adminCode: {
    required: "관리자 로그인 시 관리자코드는 필수 입력입니다.",
    pattern: {
      value: numberValidation,
      message: "여섯자리 숫자를 입력해주세요.",
    },
  },
  name: {
    required: "이름은 필수 입력입니다.",
  },
  mobile: {
    required: "휴대폰 번호는 필수 입력입니다.",
    maxLength: {
      value: 11,
      message: "휴대폰 번호를 다시 확인해주세요.",
    },
    pattern: {
      value: mobileValidation,
      message: "휴대폰 번호를 다시 확인해주세요.",
    },
  },
  phone: {
    required: "전화번호를 입력해주세요.",
    maxLength: {
      value: 12,
      message: "전화번호를 다시 확인해주세요.",
    },
    pattern: {
      value: phoneValidation,
      message: "전화번호를 다시 확인해주세요.",
    },
  },

  agreeTermsOfService: {
    required: "필수 약관입니다.",
  },
};

export const loginRuleGroup = {
  email: {
    required: "이메일은 필수 입력입니다",
  },

  password: {
    required: "비밀번호는 필수 입력입니다.",
  },
};
