import Header from "components/common/_test/Header";
import Button from "components/ui/Button";
import PageTitle from "components/ui/PageTitle";
import RHForm from "components/ui/RHForm";
import { RHInput } from "components/ui/RHInput";
import TextButton from "components/ui/TextButton";
import { palette } from "modules/defines/styles";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ruleGroup } from "modules/defines/rules";
import { authAPI } from "api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useForm,
  UseFormSetError,
  useFormContext,
  set,
  FormProvider,
} from "react-hook-form";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { subtitle14 } from "components/style/subtitle";
import { body12, body14 } from "components/style/body";
import Icon from "assets/icons/Icon";

const SignInContainer: React.FC = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [isAdminChecked, setIsAdminChecked] = useState(false);
  const [cookies, setCookie] = useCookies();

  const methods = useForm({
    mode: "all",
  });
  // 컴포넌트 내부
  const { setError } = methods;
  const LoginMutation = useMutation({
    mutationFn: (data: {
      email: string;
      password: string;
      adminCode: string;
    }) => {
      return authAPI.signIn({
        email: data.email,
        password: btoa(data.password),
        adminCode: isAdminChecked ? data.adminCode : "",
      });
    },
    onError(error, variables, context) {
      setLoading(false);
      let warningMessage = "";
      if (!isAdminChecked) {
        warningMessage = "이메일 또는 비밀번호가 일치하지 않습니다.";
      } else {
        warningMessage =
          "이메일, 비밀번호 또는 관리자코드가 일치하지 않습니다.";
        setError("adminCode", {
          type: "manual",
          message: warningMessage,
        });
      }
      alert(warningMessage);
      setError("email", { type: "manual", message: warningMessage });
      setError("password", {
        type: "manual",
        message: warningMessage,
      });
    },
    onMutate(variables) {
      setLoading(true);
      return { ...variables };
    },
    onSuccess: async (data) => {
      if (data.data.success === true) {
        try {
          if (isAdminChecked) {
            setCookie("isAdmin", "true");
            history.push("/");
          } else {
            setCookie("isAdmin", "false");
            history.push("/product");
          }
          window.location.reload();
          await queryClient.invalidateQueries({
            queryKey: ["checkUser"],
          });
          await queryClient.invalidateQueries({
            queryKey: ["userPaymentInfo"],
          });
          await queryClient.invalidateQueries({
            predicate: (query: any) => query.queryKey[0].includes("dashboard"),
          });

          if (isAdminChecked) {
            setCookie("isAdmin", "true");
            history.push("/");
          } else {
            setCookie("isAdmin", "false");
            history.push("/product");
          }
        } catch (error) {
          console.error("쿼리 무효화 실패:", error);
        }
      }
    },
  });

  const getDashboardManagerQuery = queryClient.getQueryData([
    "dashboardManager",
  ]) as {} as any | undefined;

  const isLogin = getDashboardManagerQuery?.data.success;

  useLayoutEffect(() => {
    if (isLogin) {
      if (cookies.isAdmin === true) history.push("/");
      else if (cookies.isAdmin === "true") history.push("/");
      else history.push("/product");
    } else {
      queryClient.invalidateQueries();
    }
  }, [isLogin]);

  return (
    <Container>
      <Header />
      <FormProvider {...methods}>
        <Form
          onSubmit={methods.handleSubmit((data) => {
            LoginMutation.mutate({
              email: data.email,
              password: data.password,
              adminCode: data.adminCode,
            });
          })}
        >
          <PageTitle textCenter="center">로그인</PageTitle>
          <SigninInputGroup
            isAdminChecked={isAdminChecked}
            setIsAdminChecked={setIsAdminChecked}
          />
          <Button
            type="submit"
            size="48"
            isLoading={loading}
            styleType={loading ? "outlineDisabled" : "primary"}
            disabled={false}
          >
            {loading ? "로그인 중입니다" : " 로그인"}
          </Button>
          <SignInButtonGroup>
            <TextButton
              onClick={() => history.push("/signup")}
              disabled={false}
              size="14"
              styleType="tertiary"
            >
              회원가입
            </TextButton>
            |
            <TextButton
              onClick={() => alert("준비중입니다.")}
              disabled={false}
              size="14"
              styleType="tertiary"
            >
              아이디 찾기
            </TextButton>
            |
            <TextButton
              onClick={() => alert("준비중입니다.")}
              disabled={false}
              size="14"
              styleType="tertiary"
            >
              비밀번호 찾기
            </TextButton>
          </SignInButtonGroup>
        </Form>
      </FormProvider>
    </Container>
  );
};

const SigninInputGroup = ({
  isAdminChecked,
  setIsAdminChecked,
}: {
  isAdminChecked: boolean;
  setIsAdminChecked: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Fragment>
      <RHInput
        type="email"
        name="email"
        placeholder="이메일 입력"
        labelName="이메일"
        rules={ruleGroup.email}
      />
      <RHInput
        type="password"
        name="password"
        placeholder="비밀번호 입력"
        labelName="비밀번호"
        rules={ruleGroup.password}
      />
      <IsAdminCheckbox
        type="checkbox"
        id="isAdmin"
        name="isAdmin"
        checked={isAdminChecked}
        onChange={() => setIsAdminChecked(!isAdminChecked)}
      />
      <IsAdminLabel htmlFor="isAdmin">
        <Icon
          icon={isAdminChecked ? "checked" : "noChecked"}
          fill={isAdminChecked ? palette.primary500 : palette.neutral400}
        />
        관리자 입니다
      </IsAdminLabel>

      {isAdminChecked && (
        <RHInput
          type="password"
          name="adminCode"
          placeholder="관리자코드 6자리 입력"
          labelName="관리자코드"
          rules={isAdminChecked ? ruleGroup.adminCode : {}}
        />
      )}
    </Fragment>
  );
};
const Container = styled.div`
  background-color: ${palette.neutral200};
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 16px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 40px 90px;
  width: 500px;
  & > button {
    margin-top: 16px;
  }
`;
const SignInButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-content: center;
  color: ${palette.neutral300};
`;

// 디자인 적용 전 임시 코드입니다.
const IsAdminWrapper = styled.div`
  display: flex;

  align-items: center;
`;

const IsAdminLabel = styled.label`
  ${body12};
  color: ${palette.neutral900};
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const IsAdminCheckbox = styled.input`
  display: none;
`;

const TestButton = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${palette.primary500};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: ${palette.primary400};
  }
`;

export default SignInContainer;
