import Notice from "components/common/badge/Notice";
import { body12 } from "components/style/body";
import { titleText } from "components/style/title";
import { palette } from "modules/defines/styles";
import styled from "styled-components";
interface DashboardNoticeProps {
  NoticeType: "정산" | "공지";
  detail: string;
  read: boolean;
}

const DashboardNotice: React.FC<DashboardNoticeProps> = ({ NoticeType, detail, read }) => {
  return (
    <NoticeTitleWrapper read={read}>
      <NoticeTitleGrid badgeTitle={NoticeType} />
      <NoticeTitle>
        <TopBarTextButtonAlertIcon />
        정산금액 입금
      </NoticeTitle>
      <NoticeTime>2024. 04. 15</NoticeTime>
      <NoticeDetail>{detail}</NoticeDetail>
    </NoticeTitleWrapper>
  );
};

const NoticeTitleWrapper = styled.li<{ read: boolean }>`
  list-style: none;
  display: grid;
  grid-template-columns: fit-content(100%) 4fr 1fr;
  grid-template-areas:
    "badge title title title time"
    "detail detail detail detail detail";
  padding: 12px 17px 12px 16px;
  border-top: 1px solid ${palette.neutral300};
  border-bottom: 1px solid v ${palette.neutral300};
  background-color: ${(props) => (props.read ? palette.neutral100 : "white")};
  column-gap: 12px;
  row-gap: 8px;
`;

const NoticeTime = styled.time`
  ${body12}
  color: ${palette.neutral1000};
  grid-area: time;
`;

const NoticeDetail = styled.p`
  grid-area: detail;
  max-width: 482px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;
const NoticeTitleGrid = styled(Notice)`
  grid-area: badge;
`;
const NoticeTitle = styled.div`
  ${titleText};
  grid-area: title;
  color: ${palette.neutral900};
  display: flex;
  gap: 4px;
  align-items: center;
`;

const TopBarTextButtonAlertIcon = styled.div`
  background-color: ${palette.secondary400};
  border-radius: 100%;
  width: 6px;
  height: 6px;
`;
export default DashboardNotice;
