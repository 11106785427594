import { palette } from "modules/defines/styles";
import { useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

interface EditorProps {
  text: string;
  textOnChange: any;
  width?: string;
}

const Editor = ({ text, textOnChange, width }: EditorProps) => {
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          [
            {
              header: [1, 2, 3, false],
            },
            { color: [] },
            { background: [] },
          ],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          ["link"],
          ["clean"],
        ],
      },
    }),
    []
  );
  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];
  return (
    <CustomEditorView>
      <ReactQuill
        value={text}
        onChange={textOnChange}
        style={{ width: width ?? "100%", height: "100%" }}
        formats={formats}
        modules={modules}
        theme="snow"
      />
    </CustomEditorView>
  );
};
const CustomEditorView = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  .ql-editor {
    strong {
      font-weight: 700;
    }
    em {
      font-style: italic;
    }
    p {
      font-size: 14px;
    }
    h3 {
      font-size: 17px;
    }
    h2 {
      font-size: 20px;
    }
    h1 {
      font-size: 24px;
    }
  }
`;
export default Editor;
