import React from "react";
import { palette } from "modules/defines/styles";

interface IconProps {
  enabled: boolean;
  size: string;
}

const ListSearchIcon: React.FC<IconProps> = ({ enabled, size = 0 }) => {
  const viewBox = `0 0 ${size} ${size}`;
  const color = enabled ? palette.white100 : palette.neutral700;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list-search">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6ZM11.4645 11.4645C12.4021 10.5268 13.6739 10 15 10C16.3261 10 17.5979 10.5268 18.5355 11.4645C19.4732 12.4021 20 13.6739 20 15C20 15.9881 19.7075 16.946 19.1705 17.7581C19.1829 17.7693 19.1951 17.7809 19.2071 17.7929L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L17.7929 19.2071C17.7809 19.1951 17.7693 19.1829 17.7581 19.1705C16.946 19.7075 15.9881 20 15 20C13.6739 20 12.4021 19.4732 11.4645 18.5355C10.5268 17.5979 10 16.3261 10 15C10 13.6739 10.5268 12.4021 11.4645 11.4645ZM15 12C14.2044 12 13.4413 12.3161 12.8787 12.8787C12.3161 13.4413 12 14.2044 12 15C12 15.7956 12.3161 16.5587 12.8787 17.1213C13.4413 17.6839 14.2044 18 15 18C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15C18 14.2044 17.6839 13.4413 17.1213 12.8787C16.5587 12.3161 15.7956 12 15 12ZM3 12C3 11.4477 3.44772 11 4 11H8C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13H4C3.44772 13 3 12.5523 3 12ZM3 18C3 17.4477 3.44772 17 4 17H8C8.55228 17 9 17.4477 9 18C9 18.5523 8.55228 19 8 19H4C3.44772 19 3 18.5523 3 18Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ListSearchIcon;
