import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { palette } from "modules/defines/styles";
import { productAPI } from "api";
import spinner from "assets/lottie/spinner.json";
import { ButtonTextSolid } from "components/common/Button";
import { Player } from "@lottiefiles/react-lottie-player";
import { CellButton } from "components/common/TextField";
import { OrderItem, ModalStandardProps } from "modules/defines/interfaces";
import { maskName } from "utils/util";
import SearchBarIcon from "assets/icons/SearchBarIcon";
import CircleCancel from "assets/icons/CirlcleCancel";
import CircleInfo from "assets/icons/CircleInfo";
import CheckBox from "components/common/CheckBox";
import { subtitle14 } from "components/style/subtitle";
import { titleContents, titleText } from "components/style/title";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

interface ModalOrderListProps extends ModalStandardProps {
  productId: string;
  productOptionNotExist: boolean;
}

type CheckItems = {
  id: number;
  checked: boolean;
};

interface MutationDataProps {
  oid: number;
  confirm: boolean;
}
const ModalOrderList = ({ ...props }: ModalOrderListProps) => {
  const { subject = "", productId, productOptionNotExist } = props;
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryClient = useQueryClient();
  const searchRef = useRef<HTMLInputElement | null>(null);

  const [searchText, setSearchText] = useState<string>("");
  const [checkItems, setCheckItems] = useState<CheckItems[] | []>([]);
  const [filterTerm, setFilterTerm] = useState(() => {
    return params.get("filter") || "";
  });

  const { data, isLoading } = useQuery<OrderItem[]>({
    queryKey: ["approveList", productId],
    queryFn: async () => {
      const response = await productAPI.getProductOrderList({ pid: productId });
      return response.status === 200 && response.data;
    },
    select: (data) => {
      return data.filter(
        (item) =>
          item.orderIdNumber.includes(filterTerm) ||
          item.orderNumber.includes(filterTerm) ||
          item.customerPhone.includes(filterTerm)
      );
    },
    staleTime: 1000 * 60 * 5,
  });

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.target.value);

  const handleSingleCheck = (checked: boolean, id: number) => {
    if (checked) {
      setCheckItems([...checkItems, { id: id, checked: true }]);
    } else {
      setCheckItems(checkItems.filter((el) => el.id !== id));
    }
  };

  const handleAllCheck = (checked: boolean) => {
    if (checked) {
      const idArray: any[] | ((prevState: number[]) => number[]) = [];
      data &&
        data
          .filter((item) => !item.used)
          .forEach((el) =>
            idArray.push({
              id: el.id,
            })
          );
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("filter", filterTerm);
    history.push({ search: params.toString() });
  }, [filterTerm, history]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [checkItems]);

  const searchHandler = () => {
    if (data === undefined) return;
    if (searchText === "") {
      alert("검색어를 입력해주세요");
      queryClient.setQueryData(["approveList", searchText], []);
      setFilterTerm("");
    } else {
      setFilterTerm(encodeURIComponent(searchText));
    }
  };

  const resetSearch = () => {
    if (data === undefined) return;
    setSearchText("");
    setFilterTerm("");
  };

  const updateMutation = useMutation({
    mutationFn: (data: MutationDataProps) => productAPI.setUsageOrder(data),
    onMutate: async (newData) => {
      const previousData: OrderItem[] | undefined = queryClient.getQueryData([
        "approveList",
        productId,
      ]);
      queryClient.setQueryData(["approveList", productId], (oldData: OrderItem[]) => {
        return oldData.map((item) =>
          item.id === newData.oid ? { ...item, used: !item.used } : item
        );
      });
      return { previousData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["approveList", productId],
      });

      setCheckItems([]);
    },
    onError: (error, variable, context) => {
      alert("사용승인 중 에러가 발생했습니다. 다시 시도해주세요.");
      if (context?.previousData) {
        queryClient.setQueryData(["approveList"], context.previousData);
      }
    },
  });

  const handleConfirmUsage = async (clickedItemId: number, confirm: boolean) => {
    if (
      window.confirm(
        confirm
          ? "사용을 승인하시겠습니까?"
          : "상품 사용을 취소하여 다시 사용할 수 있도록 하시겠습니까?\n<사용 승인> 단계로 돌아가며, 다시 상품을 사용할 수 있습니다."
      )
    ) {
      updateMutation.mutate({ oid: clickedItemId, confirm: confirm });
      updateMutation.isSuccess && alert("승인 상태 변경을 완료했습니다.");
    }
  };

  const handleConfirmArrayUsage = async (confirm: boolean) => {
    if (checkItems.length === 0) {
      alert("선택된 목록이 없습니다.");
      return;
    } else {
      if (
        window.confirm(
          confirm
            ? "사용을 승인하시겠습니까?"
            : "상품 사용을 취소하여 다시 사용할 수 있도록 하시겠습니까?\n<사용 승인> 단계로 돌아가며, 다시 상품을 사용할 수 있습니다."
        )
      ) {
        const idList = checkItems.map((el) => el.id);
        Promise.allSettled(idList.map((id) => updateMutation.mutate({ oid: id, confirm: confirm })))
          .then((res) => {
            const fulfilledResult = res.filter((el) => el.status === "fulfilled");
            if (fulfilledResult.length === idList.length) {
              alert("일괄 사용승인이 완료되었습니다.");
              setCheckItems([]);
            } else {
              alert("일괄 사용승인 중 에러가 발생했습니다. 다시 시도해주세요.");
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      }
    }
  };
  // set 'Enter' keystroke to search ========================
  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      searchHandler();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [searchText]);

  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <Subject>{subject}</Subject>

      <ContentsWrapper>
        {isLoading ? (
          <LoadingContainer>
            데이터 로딩 중입니다...
            <Player
              style={{
                width: "40px",
                height: "40px",
              }}
              src={spinner}
              autoplay
              loop
            />
          </LoadingContainer>
        ) : (
          <TableContainer>
            <Table>
              <Thead>
                <tr>
                  {data && data.length > 0 && (
                    <Th>
                      {data.filter((item) => !item.used).length > 0 ? (
                        <CheckBox
                          id="selectAll"
                          type="checkbox"
                          name="selectAll"
                          onChange={(e) => handleAllCheck(e.target.checked)}
                          checked={
                            checkItems.length === data.filter((item) => !item.used).length
                              ? true
                              : false
                          }
                        />
                      ) : (
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                          }}
                        ></div>
                      )}
                    </Th>
                  )}
                  <Th>주문ID</Th>
                  <Th>주문번호</Th>
                  <Th>구매자 정보</Th>
                  {!productOptionNotExist && <Th>옵션</Th>}
                  <Th>할인</Th>
                  <Th>구매 가격</Th>
                  <Th>구매일자</Th>
                  <Th>사용일자</Th>
                  <Th>사용기한</Th>
                  <Th>환불일자</Th>
                  <Th>승인 상태</Th>
                </tr>
              </Thead>
              {data && data.length === 0 ? (
                <OrderItemEmpty>판매 내역이 없습니다.</OrderItemEmpty>
              ) : (
                <Tbody>
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => {
                      const customerInfo = `${maskName(item.customerName)} (${
                        item.customerPhone.split("-")[item.customerPhone.split("-").length - 1]
                      })`;

                      return (
                        <Tr
                          className={checkItems.find((el) => el.id === item.id) ? "checked" : ""}
                          key={`table-row-${index}`}
                        >
                          <Th>
                            {item.used ? (
                              " "
                            ) : (
                              <CheckBox
                                id={`selectSingle${item.id}`}
                                type="checkbox"
                                name={`select-${item.id}`}
                                onChange={(e) => handleSingleCheck(e.target.checked, item.id)}
                                checked={checkItems.find((el) => el.id === item.id) ? true : false}
                              />
                            )}
                          </Th>
                          <Td textAlign="center" noPadding fontSize="12px">
                            {item.orderIdNumber}
                          </Td>
                          <Td textAlign="center" noPadding fontSize="12px">
                            {item.orderNumber}
                          </Td>
                          <Td textAlign="center" noPadding>
                            {customerInfo}
                          </Td>
                          {/* 옵션 제목 */}
                          {item.option !== null && <Td textAlign="left">{item.option.title}</Td>}

                          <Td textAlign="center" noPadding>
                            {item.discount}%
                          </Td>
                          <Td textAlign="right">
                            {item.total_price}
                            <TextBold> 원</TextBold>
                          </Td>
                          {item.paidAt ? (
                            <Td textAlign="center" noPadding fontSize="12px">
                              {item.paidAt.split("T")[0].substring(2).replace(/-/g, "/")}
                              {"  "}
                              {item.paidAt.split("T")[1].split("+")[0]}
                            </Td>
                          ) : (
                            <Td textAlign="center" noPadding fontSize="12px">
                              -
                            </Td>
                          )}
                          {item.usedAt ? (
                            <Td textAlign="center" noPadding fontSize="12px">
                              {item.usedAt.split("T")[0].substring(2).replace(/-/g, "/")}
                              {"  "}
                              {item.usedAt.split("T")[1].split("+")[0]}
                            </Td>
                          ) : (
                            <Td textAlign="center" noPadding fontSize="12px">
                              -
                            </Td>
                          )}
                          {item.expireAt ? (
                            <Td textAlign="center" noPadding fontSize="12px">
                              {item.expireAt.split("T")[0].substring(2).replace(/-/g, "/")}
                              {/* {item.expireAt.split("T")[1].split("+")[0]} */}
                            </Td>
                          ) : (
                            <Td textAlign="center" noPadding fontSize="12px">
                              -
                            </Td>
                          )}
                          {item.refundAt ? (
                            <Td textAlign="center" noPadding fontSize="12px">
                              {item.refundAt.split("T")[0].substring(2).replace(/-/g, "/")}
                              {/* {item.refundAt.split("T")[1].split("+")[0]} */}
                            </Td>
                          ) : (
                            <Td textAlign="center" noPadding fontSize="12px">
                              -
                            </Td>
                          )}

                          <Td noPadding>
                            {!item.refundAt ? (
                              <CellButton
                                width="100%"
                                type="primary"
                                disabled={item.used}
                                onClick={() => handleConfirmUsage(item.id, !item.used)}
                              >
                                {item.used ? "사용 완료" : "사용 승인"}
                              </CellButton>
                            ) : (
                              // item.usedAt === null ? (
                              //   <CellButton
                              //     width={"100%"}
                              //     type={"primary"}
                              //     onClick={() => handleConfirmUsage(item.id, true)}
                              //   >
                              //     사용 승인
                              //   </CellButton>
                              // ) : (
                              //   <CellButton
                              //     width={"100%"}
                              //     type={"primary"}
                              //     disabled={true}
                              //     onClick={() => handleConfirmUsage(item.id, false)}
                              //   >
                              //     사용 완료
                              //   </CellButton>
                              // )
                              <CellButton width={"100%"} type={"primary"} disabled={true}>
                                환불 완료
                              </CellButton>
                            )}
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        )}
      </ContentsWrapper>
      <SearchContainer>
        <SearchBarWrapper>
          <SearchBarIcon color={palette.neutral400} />
          <SearchInput
            type="text"
            placeholder="주문ID / 주문번호 / 휴대전화 뒤 4자리 검색"
            value={searchText}
            onChange={onChangeSearchText}
            ref={searchRef}
          />

          <ButtonContainer>
            <CircleCancel color={palette.neutral600} onClick={resetSearch} />
            <ButtonTextSolid btype="on" onClick={searchHandler}>
              검색
            </ButtonTextSolid>
          </ButtonContainer>
        </SearchBarWrapper>
        <DescWrapper>
          <CircleInfo color={palette.neutral500} />
          <DescText>사용 취소 처리가 필요한 경우, 사용 완료를 한 번 더 클릭해 주세요.</DescText>
        </DescWrapper>

        <ApproveButton onClick={() => handleConfirmArrayUsage(true)} type="button">
          일괄 승인
        </ApproveButton>
      </SearchContainer>
    </ModalWrapper>
  );
};
export default ModalOrderList;

const ApproveButton = styled.button`
  ${titleContents};
  width: fit-content;
  padding: 6px 14px;
  color: white;
  background: ${palette.primary500};
  border-radius: 8px;
  &:hover {
    background: ${palette.primary600};
  }
`;
const ModalWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 20px;
  padding: 0px 4px;
  min-width: 372px;
  min-height: 394px;
  width: 90vw;
  height: 80vh;
`;
const Subject = styled.h2`
  color: ${palette.neutral1000};
  font-feature-settings: "clig" off, "liga" off;
  /* TitleContents */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;
const ContentsWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 10px;
`;
const TableContainer = styled.div`
  height: calc(100% - 80px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
    min-height: 24px;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${palette.opacityB40};
    border-radius: 100px;
    border: 2px solid white;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0); /* 스크롤바 뒷 배경을 투명 처리한다 */
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    display: block;
    height: 10px;
  }
  &::-webkit-scrollbar-button:vertical:end:increment {
    display: block;
    height: 10px;
  }
`;
const Table = styled.table`
  width: 100%;
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  font-size: 14px;
  color: ${palette.primary500};
  height: 50%;
  background-color: inherit;
  align-items: center;
  justify-content: center;
`;
const Thead = styled.thead`
  background-color: ${palette.neutral200};
`;
const Tbody = styled.tbody``;
const Tr = styled.tr`
  &:hover {
    background-color: ${palette.neutral100};
  }
  &.checked {
    background-color: ${palette.neutral300};
    box-shadow: 0 0 0 1px ${palette.neutral200} inset;
  }
`;
const OrderItemEmpty = styled.div`
  height: 100%;
  width: 100%;

  font-size: 13px;
  color: ${palette.neutral400};
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 48%;
`;
const Td = styled.td<{
  width?: string;
  textAlign?: string;
  noPadding?: boolean;
  fontSize?: string;
}>`
  border: 1px solid ${palette.neutral300};
  padding: ${(props) => (props.noPadding ? "0px" : "10px 8px")};
  color: ${palette.neutral800};
  ${subtitle14};
  text-align: ${(props) => props.textAlign === "center" && "center"};
  text-align: ${(props) => props.textAlign === "left" && "left"};
  text-align: ${(props) => props.textAlign === "right" && "right"};

  vertical-align: middle;
  height: 40px;

  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
`;
const TextBold = styled.span`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;
const Th = styled.th<{ width?: string }>`
  color: ${palette.neutral800};
  border: 1px solid ${palette.neutral300};
  text-align: center;
  ${titleText};
  padding: 10px 8px;
`;
const SearchContainer = styled.div`
  background-color: ${palette.neutral100};
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  padding: 5px 16px 5px 24px;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 6px 0px 16px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background-color: ${palette.white100};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  width: 500px;
  position: relative;
  height: 40px;
`;

const SearchInput = styled.input`
  all: unset;
  width: 100%;

  color: ${palette.neutral1000};
  font-feature-settings: "clig" off, "liga" off;

  /* Subtitle14 */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding-right: 67px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 6px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DescText = styled.div`
  color: ${palette.neutral500};
  font-feature-settings: "clig" off, "liga" off;

  /* body12 */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.24px;
`;

const DescWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  flex-grow: 1;
`;
