import { motion } from "framer-motion";
import styled from "styled-components";
import { palette } from "modules/defines/styles";
import { ModalStandardProps } from "modules/defines/interfaces";
import { body12, body14 } from "components/style/body";
import { titleContents, titleText } from "components/style/title";
import { RHInput } from "components/ui/RHInput";
import RHForm from "components/ui/RHForm";
import { useContext, useEffect, useRef, useState } from "react";
import Icon from "assets/icons/Icon";
import Category from "components/common/badge/Category";
import { allowScroll, preventScroll } from "utils/handleScroll";
import { useQuery } from "@tanstack/react-query";
import { spaceAPI } from "api";
import Button from "components/ui/Button";
import { categoryEng } from "modules/defines/define";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
  set,
  useForm,
  useFormContext,
} from "react-hook-form";
import { ModalContext, ModalContextType } from "modules/context/ModalContext";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { postcodeScriptUrl } from "react-daum-postcode/lib/loadPostcode";
import { RHMultiCategory } from "components/ui/RHMultiCategory";
import { RHTextArea } from "components/ui/RHTextArea";
import RHImageFileInput from "components/ui/RHImageFileInput";
import { DevTool } from "@hookform/devtools";

interface SpaceProps {
  id: number;
  title: string;
  address: string;
  category: string;
}

interface ModalFAVConnectProps extends ModalStandardProps {
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  onclick?: () => void;
}
const ModalFAVConnect = ({ ...props }: ModalFAVConnectProps) => {
  const { subject = "삭제하시겠습니까", description, setValue, onclick, clearErrors } = props;
  const { openModal, closeModal } = useContext(ModalContext) as ModalContextType;

  const { data } = useQuery({
    queryKey: ["searchFavSpace"],
    queryFn: spaceAPI.getSpace,
    staleTime: 1000 * 60 * 60,
  });

  const spaceList = data?.data;

  useEffect(() => {
    const prevScrollY = preventScroll();
    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  const [filterData, setFilterData] = useState<SpaceProps[]>([]);
  const [text, setText] = useState<string>("");

  const inputRef = useRef<HTMLInputElement | null>(null);

  const filter = (search: string) => {
    if (search !== undefined || "")
      return setFilterData(spaceList.filter((item: SpaceProps) => item.title.includes(search)));
  };

  const ConvertCategoryItem = (category: string) => {
    return categoryEng[Number(category.split("/")[0])];
  };
  const makeNewSpace = () => {
    return openModal(
      <RHForm
        onValid={async (data: any) => {
          try {
            const response = await spaceAPI.registerSpace({
              title: data.title,
              description: data.description,
              address: data.address + " " + data.addressDetail,
              category: data.category
                .map((item: string) => categoryEng.findIndex((el) => el === item))
                .join("/"),
            });

            if (response.status === 200) {
              setValue("corpName", response.data.space.title);
              setValue("space_id", response.data.space.id);
              setValue("corpAddr", response.data.space.address);
              clearErrors("corpName");
              const file = data.thumbnail[0];
              if (file) {
                const newName = `${response.data.space.id}}`;
                const originalName = file.name;
                const lastDotIndex = originalName.lastIndexOf(".");
                const extension = originalName.substring(lastDotIndex);
                const newNameWithExtension = newName + extension;
                const formData = new FormData();
                formData.append("img", new File([file], newNameWithExtension));

                await spaceAPI.uploadRegisterSpaceImage(formData, {
                  id: response.data.space.id.toString(),
                });
              }
              alert("공간이 추가되었습니다.");
            }
            clearErrors("storeName");
            closeModal();
          } catch (error) {
            console.error(error);
          }
        }}
        formLayout="search"
      >
        <MakeAddSpace />
      </RHForm>
    );
  };

  useEffect(() => {}, [filterData, text]);

  const handleSearch = () => {
    if (inputRef.current !== null) {
      if (inputRef.current.value.trim() !== "") {
        filter(inputRef.current.value);
        setText("검색 결과가 없습니다. 공간을 추가해주세요.");
      }
    }
  };

  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <RHForm onValid={handleSearch} formLayout={"search"}>
        <Subject>{subject}</Subject>

        <Description>{description}</Description>
        <SearchInputWrapper>
          <Input
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                e.preventDefault();
                handleSearch();
              }
            }}
            ref={inputRef}
          />
          <Button onClick={handleSearch} size="48" styleType="primary" type="button" width="80px">
            검색
          </Button>
        </SearchInputWrapper>
        <SearchResultWrapper>
          <SearchResultItemAdd onClick={() => makeNewSpace()}>
            <Icon icon="circlePlus" fill={palette.primary500} />
            공간 추가하기
          </SearchResultItemAdd>
          {filterData.length > 0 ? (
            filterData.map(
              (item: { id: number; title: string; address: string; category: string }) => {
                return (
                  <SearchResultItem
                    onClick={() => {
                      onclick && onclick();
                      setValue("corpName", item.title);
                      setValue("space_id", item.id);
                      setValue("corpAddr", item.address);
                      clearErrors("corpName");
                    }}
                    key={item.id}
                  >
                    <SearchResultItemTitle>{item.title}</SearchResultItemTitle>
                    <SearchResultItemAddress>{item.address}</SearchResultItemAddress>
                    <Category outline={false}>
                      {item.category ? ConvertCategoryItem(item.category) : "ETC."}
                    </Category>
                  </SearchResultItem>
                );
              }
            )
          ) : (
            <SearchResultItem className="initial">{text}</SearchResultItem>
          )}
        </SearchResultWrapper>
      </RHForm>
    </ModalWrapper>
  );
};

const MakeAddSpace = () => {
  const {
    control,
    setValue,
    clearErrors,
    formState: { isValid, isSubmitting },
  } = useFormContext();
  const open = useDaumPostcodePopup(postcodeScriptUrl);
  const handleComplete = (data: {
    address: any;
    addressType: string;
    bname: string;
    buildingName: string;
  }) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setValue("address", fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    clearErrors("address");
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  useEffect(() => {
    const prevScrollY = preventScroll();
    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isSubmitting) {
      setLoading(true);
    }
  }, [loading]);

  return (
    <MakeAddSpaceWrapper>
      <MakeAddSpaceTitleWrapper>
        <Subject>공간 추가하기</Subject>
        <Description>등록하실 공간의 정보를 입력해 주세요</Description>
      </MakeAddSpaceTitleWrapper>
      <RHInput
        type="text"
        name="title"
        labelName="상호명"
        placeholder="상호명을 입력해주세요"
        rules={{ required: "상호명은 필수입니다." }}
      />
      <RHMultiCategory control={control} />
      <div>
        <RHInput
          noSpace={true}
          type="text"
          name="address"
          labelName="주소"
          placeholder="주소"
          isButton
          readOnly
          onclick={handleClick}
          onClick={handleClick}
          rules={{
            required: true,
          }}
        />
        <RHInput
          rules={{
            required: true,
            setValueAs: (value) => value.trim(),
          }}
          type="text"
          name="addressDetail"
          labelName=""
          placeholder="상세주소"
        />
      </div>
      <RHTextArea
        labelName="공간 설명"
        placeholder="공간에 대한 설명을 입력해주세요"
        name={"description"}
        rules={{
          required: "공간 설명은 필수입니다.",
          maxLength: 22,
        }}
      />
      <RHImageFileInput name="thumbnail" />
      <Button
        disabled={isSubmitting}
        isLoading={isSubmitting}
        styleType={isSubmitting ? "outlineDisabled" : "primary"}
        size="60"
        type="submit"
      >
        {isSubmitting ? "공간 추가 중" : "작성 완료 "}
      </Button>
    </MakeAddSpaceWrapper>
  );
};

const MakeAddSpaceWrapper = styled.div`
  width: 100%;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 500px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MakeAddSpaceTitleWrapper = styled.legend`
  display: flex;
  flex-direction: column;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  width: calc(100% - 40px);
`;
const Input = styled.input`
  width: 100%;
  border: 0;
  padding: 10px 16px;
  height: 48px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px ${palette.neutral200} inset;
  background-color: ${palette.white};
  color: ${palette.neutral1000};
  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::-ms-input-placeholder {
    color: ${palette.neutral500};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${palette.primary500};
    background-color: ${palette.white};
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: 0 0 0 1px ${palette.neutral400};
    background-color: ${palette.primary20};
    color: ${palette.neutral500};
  }

  &:read-only {
    box-shadow: 0 0 0 1px ${palette.neutral400};
    background-color: ${palette.primary20};
    color: ${palette.neutral500};
  }
  &.error {
    box-shadow: 0 0 0 1px ${palette.secondary400};
    background-color: ${palette.secondary20};
  }
`;

const ModalWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 4px;
  margin: 20px;
  min-width: 372px;
  min-height: 394px;
  width: 100%;
`;

const Subject = styled.h2`
  ${titleContents}
  color: ${palette.neutral1000};
`;

const Description = styled.div`
  width: 100%;
  ${body12}
  color: ${palette.neutral600};
  white-space: pre-line;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const SearchResultWrapper = styled.ul`
  margin-top: 8px;
  padding: 12px;
  width: 380px;
  height: 260px;
  display: flex;
  flex-direction: column;

  border-radius: 12px;
  border: 1px solid ${palette.neutral200};
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
    min-height: 24px;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${palette.opacityB40};
    border-radius: 100px;
    border: 2px solid white;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0); /* 스크롤바 뒷 배경을 투명 처리한다 */
  }
  &::-webkit-scrollbar-button:vertical:start:decrement {
    display: block;
    height: 10px;
  }
  &::-webkit-scrollbar-button:vertical:end:increment {
    display: block;
    height: 10px;
  }
`;

const SearchResultItemAdd = styled.li`
  ${titleText};

  cursor: pointer;
  display: flex;
  gap: 4px;

  /* align-items: center; */
  padding: 16px 24px;

  background-color: ${palette.white};
  color: ${palette.primary500};
  &:hover {
    background-color: ${palette.primary20};
    border-radius: 12px;
  }
`;

const SearchResultItem = styled.li`
  padding: 6px 24px;
  width: 100%;
  display: grid;
  grid-template-areas:
    "title category"
    "address address";
  grid-template-columns: 254px 42px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.neutral200};
    border-radius: 12px;
  }

  &.initial {
    ${body12};
    display: flex;
    margin-top: 48px;
    justify-content: center;
    align-items: center;
    color: ${palette.neutral500};
    padding: 6px 24px;
    width: 348px;
    cursor: default;
    pointer-events: none;
  }
`;

const SearchResultItemTitle = styled.div`
  ${body14}
  color: ${palette.neutral1000};
  grid-area: title;
`;
const SearchResultItemAddress = styled.address`
  ${body12}
  color: ${palette.neutral500};
  grid-area: address;
  max-width: 212px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ModalFAVConnect;
