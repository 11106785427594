import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { titleContents, titleText } from "components/style/title";
import { palette } from "modules/defines/styles";
import { body12 } from "components/style/body";
import {
  DashboardProductProps,
  ModalStandardProps,
} from "modules/defines/interfaces";
import { paymentAPI } from "api";
import spinner from "assets/lottie/spinner.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { subtitle14 } from "components/style/subtitle";
import Status from "components/common/badge/Status";

interface ModalPaymentInfoProps extends ModalStandardProps {
  item: DashboardProductProps;
  commissionRate: number;
}
const ModalPaymentInfo = ({ ...props }: ModalPaymentInfoProps) => {
  const { subject = "", description, item, commissionRate } = props;
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [paymentInfo, setPaymentInfo] = useState<DashboardProductProps>(item);

  useEffect(() => {
    paymentAPI.getPaymentInfo({ id: item.id }).then((res) => {
      setPaymentInfo(res.data);
      setDataLoading(false);
    });
  }, []);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const dateString = (data: Date) => {
    let time =
      data.toISOString().split("T")[0].replace(/-/g, "/") +
      " " +
      data.toTimeString().split(" ")[0];
    return time;
  };
  const hyphenPhoneNumber = (phone: string) => {
    const phoneArray = phone.split("");
    phoneArray.splice(3, 0, "-");
    phoneArray.splice(8, 0, "-");
    return phoneArray.join("");
  };

  const maskPhoneNumber = (phone: string) => {
    const phoneArray = phone.split("");
    phoneArray.splice(4, 4, "*", "*", "*", "*");
    return phoneArray.join("");
  };

  let usedAtDate = new Date(paymentInfo.usedAt);
  usedAtDate.setMonth(usedAtDate.getMonth() + 1, 15);
  return (
    <ModalWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
      }}
    >
      <Subject>{subject}</Subject>
      <Description>{description}</Description>
      <ContentsWrapper>
        {dataLoading ? (
          <LoadingContainer>
            <Player
              style={{
                width: "40px",
                height: "40px",
              }}
              src={spinner}
              autoplay
              loop
            />
            <WaitingComment>데이터를 불러오고 있습니다</WaitingComment>
          </LoadingContainer>
        ) : (
          <table cellSpacing={0}>
            <tr>
              <TableName colSpan={2}>지급 상태</TableName>
              <TableData>
                <Status
                  status={
                    paymentInfo.reconciledAt === null ? "primary" : "gray"
                  }
                >
                  {paymentInfo.reconciledAt === null
                    ? "정산 예정"
                    : "정산 완료"}
                </Status>
              </TableData>
              <TableName colSpan={2}>정산 예정일</TableName>
              <TableData>
                {usedAtDate.toISOString().split("T")[0].replace(/-/g, "-")}
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>정산 지급일</TableName>
              <TableData colSpan={4}>
                {paymentInfo.reconciledAt === null
                  ? "-"
                  : paymentInfo.reconciledAt.split("T")[0].replace(/-/g, "/")}
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>정산 방식</TableName>
              <TableData colSpan={4}>
                계좌 이체 / 우리은행 100**********
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>정산 금액</TableName>
              <TableData>
                {Math.round(
                  item.total_price - item.total_price * commissionRate
                ).toLocaleString()}
                <Bold>&nbsp;원</Bold>
              </TableData>
              <TableName colSpan={2}>정산 수수료</TableName>
              <TableData>
                -{" "}
                {Math.round(item.total_price * commissionRate).toLocaleString()}
                <Bold>&nbsp;원</Bold>
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>결제 ID</TableName>
              <TableData>{item.orderNumber}</TableData>
              <TableName colSpan={2}>결제 금액</TableName>
              <TableData>
                {item.total_price.toLocaleString()}
                <Bold>&nbsp;원</Bold>
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>결제일</TableName>
              <TableData colSpan={4}>
                {dateString(new Date(paymentInfo.paidAt))}
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>사용일</TableName>
              <TableData colSpan={4}>
                {dateString(new Date(paymentInfo.usedAt))}
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>상품 ID</TableName>
              <TableData>{paymentInfo.store_product_id}</TableData>
              <TableName colSpan={2}>상품명</TableName>
              <TableData className="ellipsis">
                <EllipsisData helpCursor title="리얼 뱅크시 REAL BANKSY">
                  리얼 뱅크시 REAL BANKSY
                </EllipsisData>
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>고객 ID</TableName>
              <TableData>{paymentInfo.user_id}</TableData>
              <TableName colSpan={2}>고객명</TableName>
              <TableData>
                <EllipsisData>{paymentInfo.userName}</EllipsisData>
              </TableData>
            </tr>
            <tr>
              <TableName colSpan={2}>고객 번호</TableName>
              <TableData colSpan={4}>
                {paymentInfo.userPhone &&
                  maskPhoneNumber(
                    hyphenPhoneNumber(
                      paymentInfo.userPhone?.replace("+82", "0")
                    )
                  )}
              </TableData>
            </tr>
            {/* <InfoItem>ID: {paymentInfo.id}</InfoItem>
            <InfoItem>결제 금액: {paymentInfo.total_price}</InfoItem>
            <InfoItem>결제 일자: {paymentInfo.paidAt}</InfoItem>
            <InfoItem>사용 일자: {paymentInfo.usedAt}</InfoItem>
            <Border />
            <InfoItem>사용 유저 id: {paymentInfo.user_id}</InfoItem>
            <InfoItem>사용 유저 이름: {paymentInfo.userName}</InfoItem>
            <InfoItem>사용 유저 전화번호: {paymentInfo.userPhone}</InfoItem>
            <Border />
            <InfoItem>구매 아이템 id: {paymentInfo.store_product_id}</InfoItem>
            <InfoItem>구매 아이템 이름: {paymentInfo.productName}</InfoItem> */}
          </table>
        )}
      </ContentsWrapper>
    </ModalWrapper>
  );
};

const Bold = styled.span`
  color: ${palette.neutral800};
  ${titleText};
`;

const TableName = styled.th`
  ${titleText};
  color: ${palette.neutral800};
  padding: 8px 10px;
  border: 1px solid ${palette.neutral300};
  background-color: ${palette.neutral200};
`;

const EllipsisData = styled.abbr<{ helpCursor?: boolean }>`
  cursor: ${(props) => (props.helpCursor ? "help" : "default")};
  ${subtitle14};
  max-width: 75px;
  color: ${palette.neutral800};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  text-underline-offset: 4px;
`;

const TableData = styled.td`
  ${subtitle14};

  padding: 10px 16px;
  background-color: ${palette.white};
  border: 1px solid ${palette.neutral300};
`;

const WaitingComment = styled.div`
  ${subtitle14};
  color: ${palette.primary500};
`;

const ModalWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0px;
  margin: 20px;
  width: 420px;
  height: fit-content;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Subject = styled.h2`
  ${titleContents}
  color: ${palette.neutral1000};
`;

const Description = styled.div`
  width: 100%;
  ${body12}
  color: ${palette.neutral600};
  white-space: pre-line;
  margin-bottom: 4px;
`;
const ContentsWrapper = styled.div`
  width: 100%;
  height: 432px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 12px;
`;

const InfoItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: ${palette.neutral700};
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${palette.neutral200};
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  color: ${palette.primary500};
  align-items: center;
  height: 100%;
  margin-bottom: 80px;
`;

export default ModalPaymentInfo;
